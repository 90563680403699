import { Injectable } from '@angular/core';
import { BasePageService } from '../../../../shared/base/base-page.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BACKOFFICE, CUSTOMER_ORDER_BASE_URL, IMAGE_BASE_URL } from '../../../../constants/api.constant';
import { CustomerOrderModel } from '../models/customer-order.model';
import { Observable } from 'rxjs';
import { PageInterface, PageQueryInterface } from '../../../../interfaces/page.interface';

@Injectable({ providedIn: 'root' })
export class CustomerOrderService extends BasePageService<CustomerOrderModel> {

  constructor(public http: HttpClient) {
    super(http);
  }

  get baseUrl(): string {
    return environment.url + BACKOFFICE + CUSTOMER_ORDER_BASE_URL;
  }

  async uploadFile(fd: FormData, supplierOrderId: number): Promise<any> {
    return await this.http.post<any>(environment.url + BACKOFFICE + IMAGE_BASE_URL + `/${supplierOrderId}/upload/supplier-order`,
      fd, {}).toPromise();
  }

  async generateInvoice(customerId: number, orderDate: string): Promise<any> {
    return await this.http.get<any>(this.baseUrl + `/generate-invoice/${customerId}/${orderDate}`).toPromise();
  }

  async downloadEvidenceFile(orderId: number): Promise<any> {
    return await this.http.get<any>(environment.url + BACKOFFICE + IMAGE_BASE_URL + `/${orderId}/stream/customer-order`, {
      responseType: 'blob' as 'json',
    })
      .toPromise();
  }

  async getAllItemInOrderByStorageAndDate(storageId: number, deliveryDate: string): Promise<any> {
    return await this.http.get<any>(this.baseUrl + `/get-items/${storageId}/${deliveryDate}`).toPromise();
  }

  downloadFile(id: number): Observable<Blob> {
    // Make an HTTP GET request to the API
    return this.http.get(this.baseUrl + '/invoice-pdf/' + id, {
      responseType: 'blob', // Set the response type to 'blob' for binary data
    });
  }

  async findAllBySearch(p: PageQueryInterface, customerName: string, date: string, status: string): Promise<PageInterface> {
    return await this.http.get<PageInterface>(this.baseUrl + `?page=${p.pageNo}&limit=${p.pageSize}&search-term=${p.searchTerm}&customer-name=${customerName}&date=${date}&status=${status}`,
      {}).toPromise();
  }

  async testXTable(p: PageQueryInterface): Promise<PageInterface<CustomerOrderModel>> {
    return await this.http.post<PageInterface<CustomerOrderModel>>(this.baseUrl + `/test/order`,
      p).toPromise();
  }
}
