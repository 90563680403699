<div class="">
  <div class="row">
    <div class="col-4">
      <label>{{'common.search' | translate}}</label>
      <div class="input-group mb-3">
        <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control" aria-label="search">
        <div class="input-group-append">
                  <span class="input-group-text" id="suffix_password_show">
                      <span class="icon-search"></span>
                  </span>
        </div>
      </div>
    </div>
    <div class="col-8 pt-3 text-end">
      <button (click)="create()" mat-raised-button color="primary" class="bg-brand text-white">{{'common.create' | translate}}</button>
    </div>
  </div>
</div>
<div class="px-3 pb-5">
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead>
      <tr>
        <th class="text-nowrap">{{'pages.supplier.supplier-name' | translate}}</th>
        <th class="text-nowrap">{{'pages.supplier.contact-person' | translate}}</th>
        <th class="text-nowrap">{{'pages.supplier.phone-number' | translate}}</th>
        <th class="text-nowrap">{{'pages.supplier.address' | translate}}</th>
        <th class="text-nowrap">{{'common.updated-at' | translate}}</th>
        <th class="text-end text-nowrap">{{'pages.item.buy-price' | translate}}</th>
        <th class="text-end text-nowrap">{{'pages.item.standard-price' | translate}}</th>
        <th class="sticky-column text-center">{{'common.action' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let d of pageItems; index as i">
        <td class="text-left text-nowrap">{{d.supplierName}}</td>
        <td class="text-left text-nowrap">{{d.contactPerson}}</td>
        <td class="text-left text-nowrap">{{d.phoneNumber}}</td>
        <td class="text-left text-nowrap">{{d.address}}</td>
        <td class="text-left text-nowrap">{{d.updatedAt | date: 'mediumDate'}}</td>
        <td class="text-end">
          <app-number-field
            [(ngModel)]="d.buyPrice"
            (valueChange)="getValueChange(d, $event)"
          ></app-number-field>
        </td>
        <td class="text-end">
          <app-number-field [(ngModel)]="d.standardPrice" (valueChange)="getStandardChange(d, $event)"></app-number-field>
        </td>
        <td class="sticky-column">
          <div class="btn-group" role="group" aria-label="Button Action">
            <button (click)="pickMe(d)" mat-button class="text-orange" color="primary">
              <mat-icon *ngIf="d.supplierId == itemGroup.supplierId" color="warn">check_circle</mat-icon>
              <mat-icon *ngIf="d.supplierId != itemGroup.supplierId" color="primary">check_circle</mat-icon>
            </button>
            <button (click)="saveItem(d)" mat-button class="text-orange" color="primary">
              <mat-icon>save</mat-icon>
            </button>
            <button (click)="delete(d)" type="button"  mat-button color="warn">
              <mat-icon>delete</mat-icon>
            </button>
          </div>

        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="">
  <div class="row mt-2 mb-4">
    <div class="col-12 text-center">
      <app-pagination
        (goPage)="toPage($event)"
        [pagesToShow]="showPages"
        [page]="pageNo"
        [perPage]="pageSize"
        [count]="totalRow"></app-pagination>
    </div>
  </div>
</div>
