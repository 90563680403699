<div class="form-group">
    <label *ngIf="label">{{label}}<span *ngIf="checkRequiredValidator() == 'required'"> *</span></label>
  <div class="input-group">
    <input [readOnly]="readonly"
           type="{{mode}}"
           class="form-control"
           [formControl]="ngControl.control"
           [class]="ngControl.control.dirty && ngControl.control.errors? 'is-invalid': ''" />
    <ng-content></ng-content>
  </div>

    <div class="invalid-feedback" *ngIf="ngControl.control.dirty && ngControl.control.errors">{{'error.input-required' | translate}}</div>
  </div>
