import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {CustomerOrderDetailModel, CustomerOrderModel} from '../models/customer-order.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomerOrderService} from '../services/customer-order.service';
import {MatDialog} from '@angular/material/dialog';
import {BreadcrumbService} from '../../../../services/breadcrumb.service';
import {
  CRUD_MODE_CREATE,
  CRUD_MODE_EDIT,
  CRUD_MODE_VIEW, DELIVERY_STATUS, DIRECTORATES,
  STATUS_LIST,
  UNIT_LIST
} from '../../../../constants/common.constant';
import {DialogScrollComponent} from '../../../../shared/components/dialog/dialog-scroll.component';
import {BaseComponentDirective} from '../../../../shared/base/base.component';
import {CustomerModel} from '../../customer/models/customer.model';
import {CustomerService} from '../../customer/services/customer.service';
import {ItemService} from '../../../item/item/services/item.service';
import {ItemModel} from '../../../item/item/models/item.model';
import {AreaService} from '../../../master/area/services/area.service';
import {AreaSubService} from '../../../master/area-sub/services/area-sub.service';
import {ItemPicker} from '../../../item/item/modals/item.picker';
import {MarginService} from '../../../master/margin/services/margin.service';
import {MarginModel} from '../../../master/margin/models/margin.model';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatAccordion} from '@angular/material/expansion';
import {CustomerPicker} from '../../customer/modals/customer.picker';
import {DirectoratePicker} from '../../customer/modals/directorate.picker';

@Component({
  selector: 'app-page-customer-order-form',
  templateUrl: './customer-order-form.page.html',
})
export class CustomerOrderFormPageComponent extends BaseComponentDirective implements OnInit, AfterViewInit {

  customerOrder: CustomerOrderModel;
  form: FormGroup;
  mode = '';
  hideOnNew = true;
  img: any;

  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;

  disableButton = true;
  total = 0;
  totalAfterTax = 0;

  customers: CustomerModel[] = [];
  statuses = [];
  stockStatuses = DELIVERY_STATUS;
  directorates = DIRECTORATES;
  margins: MarginModel[] = [];

  protected readonly UNIT_LIST = UNIT_LIST;

  orderDetails: CustomerOrderDetailModel[] = [];
  items: ItemModel[] = [];

  generateInvoiceCounter = 0;
  tax = 0;
  margin: MarginModel;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private customerOrderService: CustomerOrderService,
    private customerService: CustomerService,
    private itemService: ItemService,
    private areaService: AreaService,
    private subAreaService: AreaSubService,
    private marginService: MarginService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);

    this.form = this.formBuilder.group({
      customerId: [null, [Validators.required]],
      customerName: [null, []],
      contactPerson: [null, []],
      phoneNumbers: [null, []],
      address: [null, []],
      areaName: [null, []],
      freeDeliveryMinOrder: [null, []],
      subAreaName: [null, []],
      invoiceNo: [null, [Validators.required]],
      orderDate: [null, [Validators.required]],
      receivedDate: [null, []],
      tax: [0, []],
      otherAddition: [0, []],
      otherSubtraction: [0, []],
      orderedBy: [null, []],
      status: ['draft', [Validators.required]],
      deliveryStatus: ['none', []],
      paidDate: [null, []],
      verifiedDate: [null, []],
      deliveryDate: [null, []],
      note: [null, []],
      directorate: [null, [Validators.required]],
      directorateKey: [null, []],
      deliveryFee: [0, []],
      discount: [0, []],
      driverName: [null, []],
      receivedBy: [null]
    });
  }


  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.form.controls.status.disable({onlySelf: true});
    this.margins = await this.marginService.findAllNoPaging();
    this.customers = await this.customerService.findAllNoPaging();
    switch (this.activatedRoute.snapshot.params.action) {
      case 'add-new': {
        this.mode = CRUD_MODE_CREATE;
        this.statuses = STATUS_LIST;
        this.hideOnNew = true;
        break;

      }
      case 'edit': {
        this.mode = CRUD_MODE_EDIT;
        const id = this.activatedRoute.snapshot.params.id;
        this.customerOrder = await this.customerOrderService.findOne(id);
        this.attachEvidence(id);
        await this.fillFormAndDetails();
        this.assignValueToQuantityFinal();
        this.totalCount(1);
        this.splicingStatus();
        this.switchCustomerStatus();
        this.disableButton = false;
        break;
      }
      case 'view': {
        this.mode = CRUD_MODE_VIEW;
        const id = this.activatedRoute.snapshot.params.id;
        this.form.disable();
        this.disableButton = true;
        this.customerOrder = await this.customerOrderService.findOne(id);
        await this.fillFormAndDetails();
        this.assignValueToQuantityFinal();
        this.totalCount(1);
        this.splicingStatus();
        this.switchCustomerStatus();

        break;
      }
      default:
        this.mode = CRUD_MODE_VIEW;
    }

    this.breadcrumbService.changeMessage([
      {label: 'pages.customer.customer', active: false},
      {label: 'pages.customer.customer-order', active: false, route: '/customer/order'},
      {label: 'pages.customer.customer-order-form', active: true, route: ''}
    ]);
    this.doValueChanges();
    this.disableInputs();
  }

  async ngAfterViewInit(): Promise<any> {
    setTimeout(_ => {
      this.accordion.openAll();
    }, 2000);
  }

  disableInputs(): void {
    this.form.controls.invoiceNo.disable();
    this.form.controls.address.disable();
    this.form.controls.contactPerson.disable();
    this.form.controls.phoneNumbers.disable();
    this.form.controls.areaName.disable();
    this.form.controls.subAreaName.disable();
    this.form.controls.freeDeliveryMinOrder.disable();
  }

  switchCustomerStatus(): void {
    switch (this.customerOrder.status) {
      case 'proceed':
      case 'paid':
      case 'pending':
      case 'verified': {
        this.form.controls.customerId.disable();
        this.form.controls.orderDate.disable();
        break;
      }
    }
  }

  attachEvidence(id: number): void {
    this.customerOrderService.downloadEvidenceFile(id).then(blob => {
      this.img = URL.createObjectURL(blob);
    }, error => {
      console.error('Error fetching image:', error);
    });
  }

  splicingStatus(): void {
    this.statuses = STATUS_LIST;
    if (this.customerOrder.status !== 'draft') {
      const index = this.statuses.findIndex(sx => sx.key === 'draft');
      if (index > -1) {
        this.statuses.splice(index, 1);
      }
    } else {
      this.hideOnNew = false;
    }
  }

  assignValueToQuantityFinal(): void {
    this.orderDetails = this.customerOrder.details;
    this.orderDetails.forEach(od => {
      if (!od.quantityFinal) {
        od.quantityFinal = od.quantity;
      }
    });
  }

  doValueChanges(): void {
    if (this.form.controls.deliveryStatus.value === 'delivered') {
      if (this.mode !== 'view') {
        this.form.controls.driverName.setValidators([Validators.required]);
        this.form.controls.deliveryDate.setValidators([Validators.required]);
        this.form.updateValueAndValidity();
      }
    }
    if (this.form.controls.deliveryStatus.value === 'received') {
      if (this.mode !== 'view') {
        this.form.controls.receivedBy.setValidators([Validators.required]);
        this.form.controls.receivedDate.setValidators([Validators.required]);
      }
    }
    this.form.controls.deliveryStatus.valueChanges.subscribe(r => {
      if (r !== 'delivered') {
        this.form.controls.driverName.clearValidators();
        this.form.controls.deliveryDate.clearValidators();
        this.form.updateValueAndValidity();

      } else {
        this.form.controls.driverName.setValidators([Validators.required]);
        this.form.controls.deliveryDate.setValidators([Validators.required]);
        this.form.updateValueAndValidity();
      }

      if (r !== 'received') {
        this.form.controls.receivedBy.clearValidators();
        this.form.controls.receivedDate.clearValidators();
        this.form.updateValueAndValidity();
      } else {
        this.form.controls.receivedBy.setValidators([Validators.required]);
        this.form.controls.receivedDate.setValidators([Validators.required]);
        this.form.updateValueAndValidity();
      }
    });
    this.form.controls.status.valueChanges.subscribe(r => {
      if (this.customerOrder && this.customerOrder.status) {
        this.customerOrder.status = r;
      }
    });
    this.form.controls.customerId.valueChanges.subscribe(async r => {
      this.disableButton = (!r);
      if (r) {
        await this.onChangeCustomerId(r);
      } else {
        this.form.controls.address.setValue(null);
        this.form.controls.contactPerson.setValue(null);
        this.form.controls.phoneNumbers.setValue(null);
        this.form.controls.areaName.setValue(null);
        this.form.controls.subAreaName.setValue(null);
        this.form.controls.freeDeliveryMinOrder.setValue(null);
      }
      if (this.mode === CRUD_MODE_CREATE) {
        this.generateInvoiceNo(r).then();
      }
    });

    this.form.controls.orderDate.valueChanges.subscribe(async r => {
      if (this.mode === CRUD_MODE_CREATE) {
        this.generateInvoiceNo(r).then();
      }
    });
  }

  async onChangeCustomerId(id: number): Promise<void> {
    const cust = this.customers.find(c => c.id === id);
    this.margin = this.margins.find(mx => parseInt(mx.areaId, 10) === parseInt(cust.areaId, 10));

    this.form.controls.address.setValue(cust.address);
    this.form.controls.contactPerson.setValue(cust.contactPerson);
    this.form.controls.phoneNumbers.setValue(cust.phoneNumbers);
    const area = await this.areaService.findOne(cust.areaId);
    const subArea = await this.subAreaService.findOne(cust.subAreaId);
    this.form.controls.areaName.setValue(area.areaName);
    this.form.controls.subAreaName.setValue(subArea.subAreaName);
    this.form.controls.freeDeliveryMinOrder.setValue(parseFloat(area.freeDeliveryMinOrder));
  }

  openCustomerPicker(): void {
    const dialog = this.dialog.open(CustomerPicker, {});
    dialog.componentInstance.result.subscribe(r => {
      if (r) {
        this.form.controls.customerId.setValue(r.id);
        this.form.controls.customerName.setValue(r.customerName);
        this.form.controls.contactPerson.setValue(r.contactPerson);
        this.form.controls.phoneNumbers.setValue(r.phoneNumbers);
        this.form.controls.address.setValue(r.address);
      }
    });
  }

  openDirectoratePicker(): void {
    const dialog = this.dialog.open(DirectoratePicker, {});
    dialog.componentInstance.result.subscribe(r => {
      if (r) {
        this.form.controls.directorateKey.setValue(r.key);
        this.form.controls.directorate.setValue(r.value);
      }
    });
  }

  cleanupStatus(): void {
    const toDelete = ['PENDING PAYMENT', 'VERIFIED', 'PAID'];
    this.statuses.forEach(_ => {
      toDelete.forEach(d => {
        const index = this.statuses.findIndex(sx => sx.key === d);
        if (index > -1) {
          this.statuses = this.statuses.splice(index, 1);
        }
      });
    });
  }

  async generateInvoiceNo(r: any): Promise<void> {
    if (r && this.generateInvoiceCounter === 0 || this.generateInvoiceCounter === 1) {
      this.generateInvoiceCounter++;
    } else {
      if (!r) {
        this.generateInvoiceCounter--;
        this.form.controls.invoiceNo.setValue(null);
      }
    }

    if (this.generateInvoiceCounter === 2) {
      const invoiceNo = await this.customerOrderService.generateInvoice(
        this.form.controls.customerId.value,
        this.date.toDbDate(this.form.controls.orderDate.value));
      this.form.controls.invoiceNo.setValue(invoiceNo.value.toUpperCase());
      const customer = this.customers.find(c => c.id === this.form.controls.customerId.value);
      this.items = await this.itemService.findByAreaId(customer.areaId);
    }
  }

  fillCustomerInfo($event: any): void {
    //
  }

  async fillFormAndDetails(): Promise<void> {
    const customer = await this.customerService.findOne(this.customerOrder.customerId);
    this.form.setValue({
      customerId: this.customerOrder.customerId,
      customerName: customer.customerName,
      directorateKey: this.directorates.find(d => d.value === this.customerOrder.customerDirectorateId).key,
      contactPerson: '',
      phoneNumbers: '',
      areaName: '',
      address: '',
      freeDeliveryMinOrder: '',
      subAreaName: '',
      orderedBy: '',
      invoiceNo: this.customerOrder.invoiceNo,
      orderDate: this.customerOrder.orderDate,
      receivedDate: this.customerOrder.receivedDate,
      tax: this.customerOrder.tax,
      discount: this.customerOrder.customerDiscount,
      otherAddition: this.customerOrder.otherAddition,
      otherSubtraction: this.customerOrder.otherSubtraction,
      status: this.customerOrder.status,
      deliveryStatus: this.customerOrder.deliveryStatus,
      paidDate: this.customerOrder.paidDate,
      verifiedDate: (this.customerOrder.verifiedDate) ? this.customerOrder.verifiedDate : null,
      deliveryDate: this.customerOrder.deliveryDate,
      note: this.customerOrder.note,
      deliveryFee: this.customerOrder.deliveryFee,
      directorate: this.customerOrder.customerDirectorateId,
      driverName: (this.customerOrder.driverName) ? this.customerOrder.driverName : '',
      receivedBy: (this.customerOrder.receivedBy) ? this.customerOrder.receivedBy : '',
    });

    await this.onChangeCustomerId(this.customerOrder.customerId);
  }

  doBeforeSave(mode: string, date: Date): void {
    const check = this.check(this.form);
    if (check) {
      this.snackBar.open('all required input must be filled ' + check.controlName + ' ' + check.valid, '', {duration: 2000});
    } else {
      const payload = {
        id: (this.mode === CRUD_MODE_EDIT) ? this.customerOrder.id : null,
        customerId: this.form.controls.customerId.value,
        invoiceNo: this.form.controls.invoiceNo.value,
        orderDate: this.form.controls.orderDate.value,
        receivedDate: this.form.controls.receivedDate.value,
        tax: this.ui.moneyFormatToNumber(this.form.controls.tax.value),
        customerDiscount: this.ui.moneyFormatToNumber(this.form.controls.discount.value),
        otherAddition: this.ui.moneyFormatToNumber(this.form.controls.otherAddition.value),
        otherSubtraction: this.ui.moneyFormatToNumber(this.form.controls.otherSubtraction.value),
        status: mode,
        deliveryStatus: this.form.controls.deliveryStatus.value,
        paidDate: (mode === 'paid') ? this.form.controls.paidDate.value : null,
        verifiedDate: (['verified', 'pending', 'paid'].find(f => f === mode)) ? date : null,
        deliveryDate: this.form.controls.deliveryDate.value,
        note: this.form.controls.note.value,
        customerDirectorateId: this.form.controls.directorate.value,
        freeDelivery: this.ui.moneyFormatToNumber(this.totalAfterTax > this.form.controls.freeDeliveryMinOrder.value ? 1 : 0),
        taxPercentage: this.ui.moneyFormatToNumber(this.tax),
        grandTotal: this.ui.moneyFormatToNumber(this.total),
        grandTotalTaxed: this.ui.moneyFormatToNumber(this.totalAfterTax),
        driverName: this.form.controls.driverName.value,
        receivedBy: this.form.controls.receivedBy.value,
        details: []
      };
      const c = this.customers.find(cx => cx.id === payload.customerId);
      const m = this.margins.find(mx => parseInt(mx.areaId, 10) === parseInt(c.areaId, 10));

      this.orderDetails.forEach(od => {
        od.price = this.ui.moneyFormatToNumber(od.price);
        od.margin = m.margin;
        od.quantityFinal = parseFloat(od.quantityFinal);
        od.quantity = parseFloat(od.quantity);
        od.standardPrice = parseFloat(od.standardPrice);
        payload.details.push(od);
      });

      const isCreateMode = this.mode === CRUD_MODE_CREATE;

      const dialog = this.dialog.open(DialogScrollComponent, {
        data: {
          title: this.translateService.instant('dialog.save-message'),
          subtitle: this.translateService.instant('dialog.save-title'),
          content: (isCreateMode) ? `#${payload.invoiceNo}` : `#${payload.id} - ${payload.invoiceNo}`,
          buttons: [
            {
              value: 1,
              className: 'text-red btn btn-primary',
              color: 'primary',
              focus: true,
              label: this.translateService.instant('common.save')
            },
            {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
          ]
        }
      });

      dialog.afterClosed().subscribe((res) => {
        if (res === 1) {
          if (isCreateMode) {
            this.customerOrderService.save(payload).then((r) => {
              this.doAfterSave(r.id);
            });
          } else {
            this.customerOrderService.update(payload).then((_) => {
              this.doAfterSave(payload.id);
            });
          }
        }
      });
    }
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }

    this.doBeforeSave('draft', this.date.nowToDbDate());

  }

  doAfterSave(id: number): void {
    if (this.fd) {
      this.uploadFile(id).then();
    }

    this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
      verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
    });

    this.router.navigate(['/customer/order']).then();
  }

  create(): void {
    const cust = this.customers.find(c => c.id === this.form.controls.customerId.value);

    const selectedItems = this.orderDetails.map(od => od.itemId);
    const dialog = this.dialog.open(ItemPicker, {
      data: {
        selectedItems,
        areaId: cust.areaId
      }
    });

    dialog.componentInstance.result.subscribe(async items => {
      if (items && items.length > 0) {
        for (const item of items) {
          if (item.sku) {
            const existingItem = this.orderDetails.find(od => od.sku === item.sku);
            if (!existingItem) {
              this.orderDetails.push({
                itemGroupId: item.itemGroupCode,
                sku: item.sku,
                itemName: item.itemGroupName,
                quantity: (item.quantity) ? item.quantity : 1,
                quantityFinal: (item.quantity) ? item.quantity : 1,
                subtotal: item.subtotal,
                currentStock: item.currentStock,
                itemId: item.id,
                margin: this.margin.margin,
                standardPrice: item.standardPrice,
                price: item.sellPrice,
                unit: item.unit
              });
            }
            this.totalCount(0);
          }
        }
      }
    });
  }

  totalCount(mode: number): void {
    this.total = 0;
    const deliveryFee = this.ui.moneyFormatToNumber(this.form.controls.deliveryFee.value);
    this.orderDetails.forEach(d => {
      if (mode === 1) {
        this.total += d.quantityFinal * d.price;
      } else {
        this.total += d.quantity * d.price;
      }
    });
    this.total = this.total - this.ui.moneyFormatToNumber(this.form.controls.discount.value) + deliveryFee;
    this.totalAfterTax = this.total + this.total * (this.tax / 100);
    this.form.controls.tax.setValue(this.total * (this.tax / 100));
  }

  getQuantity(d: any, index: number, qty: any): void {
    if (!this.orderDetails[index].price) {
      this.orderDetails[index].price = 0;
    }
    this.orderDetails[index].quantity = qty;
    this.orderDetails[index].quantityFinal = qty;
  }

  getQuantityFinal(d: any, index: number, qty: any): void {
    if (!this.orderDetails[index].price) {
      this.orderDetails[index].price = 0;
    }
    this.orderDetails[index].quantityFinal = qty;
    this.orderDetails[index].subtotal = this.ui.moneyFormatToNumber(qty) * this.ui.moneyFormatToNumber(this.orderDetails[index].price);
    this.totalCount(1);
    if (this.total > this.form.controls.freeDeliveryMinOrder.value) {
      this.form.controls.deliveryFee.disable({onlySelf: true});
      this.form.controls.deliveryFee.setValue(0);
    } else {
      this.form.controls.deliveryFee.enable({onlySelf: true});
    }
  }

  addRemark(d: any, index: number, val: any): void {
    this.orderDetails[index].remarkPerItem = val;
  }

  delete(d: CustomerOrderDetailModel): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.itemName}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-danger',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.delete')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        const index = this.orderDetails.findIndex(od => od.itemGroupId === d.itemGroupId);
        this.orderDetails.splice(index, 1);
        this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
          verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
        });
      }
    });
  }

  async uploadFile(id: number): Promise<any> {
    return await this.customerOrderService.uploadFile(this.fd, id);
  }

  sent(): void {
    this.doBeforeSave('sent', new Date());
  }

  finishInvoice(): void {
    this.doBeforeSave('finish', new Date());
  }

  proceed(): void {
    // validating checking
    let flag = 0;
    this.orderDetails.forEach(od => {
      if (!od.checked) {
        flag = 1;
      }
    });
    if (flag === 1) {
      const dialog = this.dialog.open(DialogScrollComponent, {
        data: {
          title: this.translateService.instant('dialog.validation-message'),
          subtitle: this.translateService.instant('dialog.validation-title'),
          content: this.translateService.instant('pages.customer.checked-validation'),
          buttons: [
            {
              value: 1,
              className: 'text-red btn btn-danger',
              color: 'primary',
              focus: true,
              label: this.translateService.instant('common.proceed')
            },
            {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
          ]
        }
      });

      dialog.afterClosed().subscribe((res) => {
        if (res === 1) {
          this.doBeforeSave('proceed', new Date());
        }
      });
    } else {
      this.doBeforeSave('proceed', new Date());
    }
  }

  downloadEvidence(): void {
    this.customerOrderService.downloadEvidenceFile(this.customerOrder.id).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.customerOrder.invoiceNo + '.jpg';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching image:', error);
    });
  }

  downloadInvoice(): void {
    this.customerOrderService.downloadFile(this.customerOrder.id).subscribe((data: Blob) => {
      const blob = new Blob([data], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'downloaded-file.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }

  checkQtyAndPrice($event: MatCheckboxChange, d: CustomerOrderDetailModel): void {
    if ($event.checked) {
      d.checked = 1;
      let msg = '';
      let extra = '';
      if (parseFloat(d.quantity) !== parseFloat(d.quantityFinal)) {
        msg = `${d.itemName} has different quantity are you sure want to confirm this? `;
        extra = `order quantity is ${d.quantity} while incoming quantity is ${d.quantityFinal}`;
      }
      if (msg === '') {
        d.checked = 1;
      } else {
        const dlg = this.dialog.open(DialogScrollComponent, {
          data: {
            subtitle: this.translateService.instant('dialog.alert-title'),
            content: msg,
            extraText: extra,
            buttons: [
              {
                value: 1,
                className: 'text-red btn btn-primary',
                color: 'primary',
                focus: true,
                label: this.translateService.instant('common.confirm')
              },
              {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
            ]
          }
        });

        dlg.afterClosed().subscribe(r => {
          if (r === 0) {
            d.checked = 0;
          }
        });
      }

    } else {
      d.checked = 0;
    }
  }

  cancel(): void {
    this.router.navigate(['/customer/order']).then();
  }
}
