import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../material/material.module';
import {TranslateModule} from '@ngx-translate/core';
import {EscapeHtmlPipe} from '../pipes/escape-html.pipe';
import { AppComponentModule } from './components/component.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    AppComponentModule
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppSharedModule {}
