import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../auth/auth-guard.service';
import {NgModule} from '@angular/core';
import {StorageAuditPageComponent} from './storage-audit/pages/storage-audit.page';
import {StorageAuditFormPageComponent} from './storage-audit/pages/storage-audit-form.page';
import {StorageAuditEditorPageComponent} from './storage-audit/pages/storage-audit-editor.page';

const routes: Routes = [
  {path: 'storage', component: StorageAuditPageComponent, canActivate: [AuthGuard]},
  {path: 'storage/add-new/:storageId', component: StorageAuditFormPageComponent, canActivate: [AuthGuard]},
  {path: 'storage/edit/:id', component: StorageAuditEditorPageComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuditRoutesModule {
}
