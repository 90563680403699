import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DatePickerDirective} from './date-picker.directive';
import {injectNgControlDate} from './date-picker.function';

@Component({
    selector: 'app-datepicker',
    hostDirectives: [DatePickerDirective],
    templateUrl: 'date-picker.component.html',
    styleUrls: ['date-picker.scss']
})
export class AppDatePickerComponent implements OnInit{
    ngControl = injectNgControlDate();
    @Input() label = '';
    @Input() readonly = false;
    @Input() disabling = false;
    @Output() valueChange: EventEmitter<any> = new EventEmitter();

    ngOnInit(): void {
      if (this.disabling) {
        this.ngControl.control.disable();
      }

      this.ngControl.control.valueChanges.subscribe(r => {
            this.valueChange.emit(r);
        });
    }

    checkRequiredValidator(): string {
        const inputFieldControl = this.ngControl.control;
        if (inputFieldControl && inputFieldControl.validator) {
            const validators = inputFieldControl.validator({} as FormControl);
            if (validators && validators.required) {
                return 'required';
            } else {
                return null;
            }
        }
    }
}
