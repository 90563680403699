import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BACKOFFICE, IMAGE_BASE_URL, STORAGE_BASE_URL } from 'src/app/constants/api.constant';
import { environment } from 'src/environments/environment';
import { StorageModel } from 'src/app/pages/master/storage/models/storage.model';
import { BasePageService } from 'src/app/shared/base/base-page.service';

@Injectable({providedIn: 'root'})
export class StorageService extends BasePageService<StorageModel>{

  constructor(public http: HttpClient) {
    super(http);
  }

  get baseUrl() {
    return environment.url + BACKOFFICE + STORAGE_BASE_URL;
  }

  async uploadFile(fd: FormData, storageId: number): Promise<any> {
    return await this.http.post<any>(environment.url + BACKOFFICE + IMAGE_BASE_URL + `/${storageId}/upload/storage`, fd, {}).toPromise();
  }
}
