import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgProgressModule } from 'ngx-progressbar';
import { MaterialModule } from 'src/app/material/material.module';
import { AppSharedModule } from 'src/app/shared/shared.module';
import { AreaSubModal } from './area-sub/modals/area-sub.modal';
import { AreaSubPageComponent } from './area-sub/pages/area-sub.page';
import { AreaModal } from './area/modals/area.modal';
import { AreaPageComponent } from './area/pages/area.page';
import { MarginModal } from './margin/modals/margin.modal';
import { MarginPageComponent } from './margin/pages/margin.page';
import { MasterRoutesModule } from './master-routes.module';
import { ParameterModal } from './parameter/modals/parameter.modal';
import { ParameterPageComponent } from './parameter/pages/parameter.page';
import { ProductSubModal } from './product-sub/modals/product-sub.modal';
import { ProductSubPageComponent } from './product-sub/pages/product-sub.page';
import { ProductModal } from './product/modals/product.modal';
import { ProductPageComponent } from './product/pages/product.page';
import { StorageModal } from './storage/modals/storage.modal';
import { StoragePageComponent } from './storage/pages/storage.page';
import { AppComponentModule } from 'src/app/shared/components/component.module';
import { StoragePicker } from './storage/modals/storage.picker';
import { XTableComponent } from '../../shared/components/xtable/xtable.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule,
    MasterRoutesModule,
    NgSelectModule,
    AppComponentModule,
    XTableComponent,
  ],
  exports: [
    AreaPageComponent,
    AreaSubPageComponent,
    MarginPageComponent,
    ProductPageComponent,
    ProductSubPageComponent,
    StoragePageComponent,
    ParameterPageComponent,
    AreaModal,
    AreaSubModal,
    MarginModal,
    ProductModal,
    ProductSubModal,
    StorageModal,
    ParameterModal,
    StoragePicker,
  ],
  declarations: [
    AreaPageComponent,
    AreaSubPageComponent,
    MarginPageComponent,
    ProductPageComponent,
    ProductSubPageComponent,
    StoragePageComponent,
    ParameterPageComponent,
    AreaModal,
    AreaSubModal,
    MarginModal,
    ProductModal,
    ProductSubModal,
    StorageModal,
    ParameterModal,
    StoragePicker,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppMasterModule {
}
