<div class="white-background p-5">
    <form [formGroup]="form" (submit)="save()">
      <h4 class="mat-dialog-title px-4" *ngIf="mode == 'create'">{{'pages.storage.storage-editor' | translate}}</h4>
      <h2 class="mat-dialog-title px-4" *ngIf="mode == 'edit' || mode == 'view'">{{'pages.storage.storage-editor' | translate}} #{{storage.id}}</h2>
      <mat-dialog-content style="width: 600px;">

        <div class="row">
          <div class="col-12">
            <app-text-field [readonly]="readonly" [label]="'pages.storage.storage-name' | translate" formControlName="storageName"></app-text-field>
          </div>
        </div>

        <div class="row">
            <div class="col-12">
              <ng-container *ngFor="let a of areas">
                <mat-checkbox [disabled]="a.storageId" class="d-block" [(ngModel)]="a.checked" [ngModelOptions]="{standalone: true}">{{a.areaName}}</mat-checkbox>
              </ng-container>
            </div>
        </div>

        <div class="row">
          <div class="col-12">
            <app-area-field [readonly]="readonly" [label]="'pages.storage.address' | translate" formControlName="address" [rows]="5" [cols]="10"></app-area-field>
          </div>
        </div>

        <div class="row">
            <div class="col-12">
              <app-area-field [readonly]="readonly" [label]="'pages.storage.description' | translate" formControlName="description" [rows]="5" [cols]="10"></app-area-field>
            </div>
          </div>



        <div class="row">
          <div class="col-12">
            <app-upload-single
              [readonly]="readonly"
              [fileUrl]="fileUrl"
              [accept]="'image/*'"
              [label]="'pages.storage.image' | translate"
              [labelButton]="'pages.storage.image-button' | translate" (fileChange)="getFileChange($event)">

            </app-upload-single>
          </div>
        </div>

      </mat-dialog-content>
      <mat-dialog-actions  class="justify-content-end pe-4">
        <button *ngIf="!viewMode" mat-raised-button class="py-1" color="primary">{{'common.save' | translate}}</button>
        <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
      </mat-dialog-actions>
    </form>
  </div>
