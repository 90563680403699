import { Component, OnInit, Injector, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_EDIT, CRUD_MODE_VIEW, CRUD_MODE_CREATE } from 'src/app/constants/common.constant';
import { BaseComponentDirective } from 'src/app/shared/base/base.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { AreaModel } from '../models/area.model';
import { AreaService } from '../services/area.service';
import {StorageModel} from '../../storage/models/storage.model';

@Component({
  selector: 'app-modal-area',
  templateUrl: './area.modal.html',
})
export class AreaModal extends BaseComponentDirective implements OnInit {
  public result = new EventEmitter();
  public form: FormGroup;
  public mode = '';
  public area: AreaModel;
  public viewMode = false;
  public storages: StorageModel[] = [];
  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<AreaModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private areaService: AreaService,
    private dialog: MatDialog
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      areaName: [null, [Validators.required]],
      storageId: [null, []],
      deliveryFee: [0, [Validators.required]],
      freeDeliveryMinOrder: [0, [Validators.required]],
      areaDescription: [null, [Validators.required]]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.area = this.data.area as AreaModel;
    this.mode = this.data.mode;
    this.storages = this.data.storages;

    switch (this.mode) {
      case CRUD_MODE_EDIT: {
        this.fillForm();

        break;
      }
      case CRUD_MODE_VIEW: {
        this.readonly = true;
        this.viewMode = true;
        this.fillForm();

        break;
      }
    }
  }

  fillForm(): void {
    this.fileUrl = this.area.imageName;
    this.form.setValue({
      areaName: this.area.areaName,
      storageId: this.area.storageId,
      deliveryFee: this.area.deliveryFee,
      freeDeliveryMinOrder: this.area.freeDeliveryMinOrder,
      areaDescription: (this.area.areaDescription) ? this.area.areaDescription : ''
    });
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }
    const payload = {
      id: (this.mode === CRUD_MODE_EDIT) ? this.area.id : null,
      areaName: this.form.controls.areaName.value,
      storageId: parseInt(this.form.controls.storageId.value, 10),
      areaDescription: this.form.controls.areaDescription.value,
      deliveryFee: this.ui.moneyFormatToNumber(this.form.controls.deliveryFee.value),
      freeDeliveryMinOrder: this.ui.moneyFormatToNumber(this.form.controls.freeDeliveryMinOrder.value)
    };

    const isCreateMode = this.mode === CRUD_MODE_CREATE;

    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: (isCreateMode) ? `#${payload.areaName}` : `#${payload.id} - ${payload.areaName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.save')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        if (isCreateMode) {
          this.areaService.save(payload).then((r) => {
            this.doAfterSave(r.id);
          });
        } else {
          this.areaService.update(payload).then((_) => {
            this.doAfterSave(payload.id);
          });
        }
      }
    });
  }

  doAfterSave(id: number): void {
    if (this.fd) {
      this.uploadFile(id).then();
    }

    this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
      verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
    });
    this.result.emit(1);
    this.dialogRef.close();
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  async uploadFile(id: number): Promise<any> {
    return await this.areaService.uploadFile(this.fd, id);
  }
}
