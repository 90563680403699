import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { NumberFieldDirective } from './number-field.directive';
import { injectNgControlNumber } from './number-field.function';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-number-field',
    hostDirectives: [NumberFieldDirective],
    templateUrl: 'number-field.component.html',
    styleUrls: ['number-field.scss']
  })
  export class AppNumberFieldComponent implements OnInit{
    ngControl = injectNgControlNumber();
    @Input() label = '';
    @Input() decimalSeparator = '.';
    @Input() thousandSeparator = ',';
    @Input() readonly = false;
    @Output() valueChange: EventEmitter<any> = new EventEmitter();

    ngOnInit(): void {
        this.ngControl.control.valueChanges.subscribe(r => {
            this.valueChange.emit(r);
        });
    }

    checkRequiredValidator(): string {
        const inputFieldControl = this.ngControl.control;
        if (inputFieldControl && inputFieldControl.validator) {
          const validators = inputFieldControl.validator({} as FormControl);
          if (validators && validators.required) {
            return 'required';
          } else {
            return null;
          }
        }
    }
  }
