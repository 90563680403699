<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card m- white-background text-black-50 rounded-lg">
      <div class="mat-card-title px-3 py-3">
        <h3 class="pl-2">{{'pages.area.area' | translate}}</h3>
      </div>
      <div class="mat-card-content px-3 rounded">
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label>{{'common.search' | translate}}</label>
              <div class="input-group mb-3">
                <input
                  [(ngModel)]="searchTerm"
                  (keyup)="doSearch(searchTerm, $event)"
                  class="form-control"
                  aria-label="search">
                <div class="input-group-append">
                  <span class="input-group-text" id="suffix_password_show">
                      <span class="icon-search"></span>
                  </span>
                </div>
              </div>
            </div>

          </div>
          <div class="col-8 pt-3 text-end">
            <button (click)="create()" mat-flat-button color="primary" class="text-white">{{'common.create' | translate}}</button>
          </div>
        </div>
      </div>
      <div class="mat-card-content px-3 pb-5">
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th class="text-nowrap text-end">{{'pages.area.area-id' | translate}}</th>
                <th class="text-nowrap">{{'pages.area.area-name' | translate}}</th>
                <th class="text-nowrap">{{'pages.storage.storage-name' | translate}}</th>
                <th class="text-nowrap text-end">{{'pages.area.delivery-fee' | translate}}</th>
                <th class="text-nowrap text-end">{{'pages.area.free-delivery-min-order' | translate}}</th>
                <th class="sticky-column text-center"  style="width: 200px;">{{'common.action' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let area of pageItems">
                <td class="text-nowrap text-end">{{area.id}}</td>
                <td class="text-nowrap">{{area.areaName}}</td>
                <td class="text-nowrap">{{maskingColumn('storageName', 'id', storages, area.storageId)}}</td>
                <td class="text-nowrap text-end">{{area.deliveryFee | currency: 'USD':'':'4.2-2'}}</td>
                <td class="text-nowrap text-end">{{area.freeDeliveryMinOrder | currency: 'USD':'':'4.2-2'}}</td>
                <td class="sticky-column">
                  <div class="btn-group" role="group" aria-label="Button Action">
                    <button (click)="view(area)" type="button" mat-button color="primary">
                      <mat-icon>fullscreen</mat-icon>
                    </button>
                    <button (click)="edit(area)" mat-button class="text-orange" color="primary">
                      <mat-icon>edit_square</mat-icon>
                    </button>
                    <button (click)="delete(area)" type="button" mat-button color="warn">
                      <mat-icon>delete</mat-icon>
                    </button>
                    <button (click)="detail(area)" type="button" mat-button color="primary">
                      <mat-icon>filter_none</mat-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mat-card-content">
        <div class="row mb-4">
          <div class="col-12 text-center">
            <app-pagination
              (goPage)="toPage($event)"
              [pagesToShow]="showPages"
              [page]="pageNo"
              [perPage]="pageSize"
              [count]="totalRow"></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
