<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card m- white-background text-black-50">

      <div class="mat-card-content px-3 py-3">
        <mat-accordion class="example-headers-align" multi >
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'pages.supplier.supplier' | translate}}
              </mat-panel-title>

            </mat-expansion-panel-header>
            <form [formGroup]="form" (submit)="save()">
              <div class="row">
                <div class="col-6">
                  <app-text-field [readonly]="readonly" [label]="'pages.supplier.supplier-name' | translate" formControlName="supplierName"></app-text-field>
                </div>
                <div class="col-3">
                  <app-text-field [readonly]="readonly" [label]="'pages.supplier.contact-person' | translate" formControlName="contactPerson"></app-text-field>
                </div>
                <div class="col-3">
                  <app-text-field [readonly]="readonly" [label]="'pages.supplier.phone-number' | translate" formControlName="phoneNumber"></app-text-field>
                </div>
              </div>

              <div class="row">

                <div class="col-6">
                  <app-area-field [cols]="10" [rows]="5" [readonly]="readonly" [label]="'pages.supplier.address' | translate" formControlName="address"></app-area-field>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-12 justify-content-end text-end">
                  <button mat-raised-button color="primary">{{'common.edit' | translate}} {{'pages.supplier.supplier' | translate}}</button>
                </div>
              </div>
            </form>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'pages.supplier.supplier-item' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="mat-card-content  ">
              <div class="row">
                <div class="col-4">
                  <label>{{'common.search' | translate}}</label>
                  <div class="input-group mb-3">
                    <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control" aria-label="search">
                    <div class="input-group-append">
                  <span class="input-group-text" id="suffix_password_show">
                      <span class="icon-search"></span>
                  </span>
                    </div>
                  </div>
                </div>
                <div class="col-8 pt-3 text-end">
                  <button (click)="create()" mat-raised-button color="primary" class="bg-brand text-white">{{'common.create' | translate}}</button>
                </div>
              </div>
            </div>
            <div class="mat-card-content pb-5">
              <div class="table-responsive">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th class="text-nowrap text-end">{{'pages.item.id' | translate}}</th>
                      <th class="text-nowrap text-end">{{'pages.item-group.item-group-id' | translate}}</th>
                      <th class="text-nowrap">{{'pages.item-group.item-group-code' | translate}}</th>
                      <th class="text-nowrap">{{'pages.item-group.item-group-name' | translate}}</th>
                      <th class="text-nowrap">{{'pages.product.product-name' | translate}}</th>
                      <th class="text-nowrap">{{'pages.product-sub.product-name' | translate}}</th>
                      <th class="text-nowrap">{{'common.updated-at' | translate}}</th>
                      <th class="text-nowrap text-end">{{'pages.item.buy-price' | translate}}</th>
                      <th></th>
                      <th class="sticky-column text-center">{{'common.action' | translate}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let d of pageItems; index as i">
                      <td class="text-end">{{d.id}}</td>
                      <td class="text-end">{{d.itemGroupId}}</td>
                      <td class="text-left">{{d.itemGroupCode}}</td>
                      <td class="text-left text-nowrap">{{d.itemGroupName}}</td>
                      <td class="text-left">{{d.productName}}</td>
                      <td class="text-left">{{d.subProductName}}</td>
                      <td class="text-left text-nowrap">{{d.updatedAt | date: 'mediumDate'}}</td>
                      <td class="text-end">
                        <app-number-field [(ngModel)]="d.buyPrice" (valueChange)="getValueChange(d, $event)"></app-number-field>
                      </td>
                      <td></td>
                      <td class="sticky-column">
                        <div class="btn-group" role="group" aria-label="Button Action">
                          <button (click)="saveItem(d)" mat-button class="text-orange" color="primary">
                            <mat-icon>save</mat-icon>
                          </button>
                          <button (click)="delete(d)" type="button"  mat-button color="warn">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="mat-card-content">
              <div class="row mt-4 mb-4">
                <div class="col-12 text-center">
                  <app-pagination
                    (goPage)="toPage($event)"
                    [pagesToShow]="showPages"
                    [page]="pageNo"
                    [perPage]="pageSize"
                    [count]="totalRow"></app-pagination>
                </div>
              </div>
            </div>
          </mat-expansion-panel>

        </mat-accordion>

      </div>

    </div>
  </div>
</div>
