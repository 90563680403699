import {Directive, Injector, OnDestroy, OnInit} from '@angular/core';
import {UiHelper} from '../../helpers/ui.helper';
import {LabelHelper} from '../../helpers/label.helper';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {JWT_TOKEN_KEY, STORAGE_PROFILE_KEY} from '../../constants/common.constant';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DateHelper} from '../../helpers/date.helper';
import { FormGroup } from '@angular/forms';

@Directive()
export abstract class BaseComponentDirective implements OnInit, OnDestroy {
  protected ngUnsubscribe = new Subject();
  public ui = UiHelper;
  public label = LabelHelper;
  public date = DateHelper;
  public router: Router;
  public translateService: TranslateService;
  public activatedRoute: ActivatedRoute;
  public jwtService: JwtHelperService;
  public snackBar: MatSnackBar;
  public isSignedIn: boolean;
  public userProfile: any = {};
  public readonly = false;

  protected constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.translateService = injector.get(TranslateService);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.jwtService = injector.get(JwtHelperService);
    this.snackBar = injector.get(MatSnackBar);
  }
  ngOnInit(): void {
    this.isSignedIn = !this.jwtService.isTokenExpired();
    this.userProfile = JSON.parse(localStorage.getItem(STORAGE_PROFILE_KEY));
    if (!this.isSignedIn) {
      localStorage.removeItem(JWT_TOKEN_KEY);
      localStorage.removeItem(STORAGE_PROFILE_KEY);
    }
  }

  markFormGroupAsTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupAsTouched(control); // If it's another form group, recursively call the function
      } else {
        control.markAsTouched();
        control.markAsDirty();
        // Mark the individual form control as touched
      }
    });
  }

  check(form: FormGroup): any {
    let valid = null;
    let disableMarker = null;
    Object.keys(form.controls).forEach(controlName => {
      const control = form.get(controlName);
      if (control.status === 'DISABLED') {
        form.get(controlName).enable();
        disableMarker = controlName;
      }
      form.get(controlName).enable();
      if (control.status === 'INVALID' && !valid) {
        valid = {
          controlName,
          value: control.value,
          valid: control.valid
        };
      }
      if (disableMarker) {
        form.get(controlName).disable();
        disableMarker = null;
      }
    });
    return valid;
  }

  redirectTo(uri: string): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
      this.router.navigate([uri]));
  }

  maskingColumn(label: string, value: any, items: any[], val: any): any {
    // tslint:disable-next-line:triple-equals
    const f = items.find(i => i[value] == val);
    return (f) ? f[label] : '-';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
