import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BACKOFFICE, CUSTOMER_BASE_URL, IMAGE_BASE_URL } from 'src/app/constants/api.constant';
import { BasePageService } from 'src/app/shared/base/base-page.service';
import { environment } from 'src/environments/environment';
import { CustomerModel } from '../models/customer.model';

@Injectable({providedIn: 'root'})
export class CustomerService extends BasePageService<CustomerModel>{

  constructor(public http: HttpClient) {
    super(http);
  }

  get baseUrl(): string {
    return environment.url + BACKOFFICE + CUSTOMER_BASE_URL;
  }

  async uploadFile(fd: FormData, customerId: number): Promise<any> {
    return await this.http.post<any>(environment.url + BACKOFFICE + IMAGE_BASE_URL + `/${customerId}/upload/customer`, fd, {}).toPromise();
  }
}
