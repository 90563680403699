import {Directive, EventEmitter, Injector, OnDestroy, OnInit} from '@angular/core';

import {Subject} from 'rxjs';
import {BaseComponentDirective} from './base.component';
import {PageInterface, PageQueryInterface} from '../../interfaces/page.interface';

@Directive()
export abstract class BasePageComponentDirective<T> extends BaseComponentDirective implements OnInit, OnDestroy {
  protected ngUnsubscribe = new Subject();
  protected page = new EventEmitter();
  public pageData: PageInterface;
  public pageState = '';
  public pageNo = 1;
  public pageSize = 10;
  public showPages = 3;
  public totalRow = 0;
  public pageItems: T[] = [];
  public searchTerm = '';
  public pageQuery: PageQueryInterface = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    searchTerm: this.searchTerm
  };

  protected constructor(injector: Injector) {
    super(injector);
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.page.subscribe((res) => {
      this.pageData = res;
      this.pageItems = (!this.pageData.itemDtos) ? this.pageData.items : this.pageData.itemDtos;
      this.totalRow = this.pageData.meta.totalItems;
      this.pageNo = this.pageData.meta.currentPage;
      this.pageQuery = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        searchTerm: this.searchTerm
      };
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  public abstract query(): void;

  toPage(p: number): void {
    this.pageQuery = {
      pageNo: p,
      pageSize: this.pageSize,
      searchTerm: ''
    };
    this.query();
  }

  doSearch(searchTerm: string, $event: any): void {
    if ($event.key === 'Enter') {
      this.pageQuery = {
        pageNo: 1,
        pageSize: this.pageSize,
        searchTerm
      };
      this.query();
    }
  }

}
