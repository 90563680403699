import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TextFieldDirective } from './text-field.directive';
import { injectNgControl } from './text-field.function';

@Component({
    selector: 'app-text-field',
    hostDirectives: [TextFieldDirective],
    templateUrl: 'text-field.component.html',
    styleUrls: ['text-field.scss']
  })
  export class AppTextFieldComponent implements OnInit{
    ngControl = injectNgControl();
    @Input() label = '';
    @Input() readonly = false;
    @Input() disabling = false;
    @Input() mode = 'text';
    @Output() valueChange: EventEmitter<any> = new EventEmitter();
    ngOnInit(): void {
      if (this.disabling) {
        this.ngControl.control.disable();
      }
      this.ngControl.control.valueChanges.subscribe(r => {
        this.valueChange.emit(r);
      });
    }

    checkRequiredValidator(): string {
        const inputFieldControl = this.ngControl.control;
        if (inputFieldControl && inputFieldControl.validator) {
          const validators = inputFieldControl.validator({} as FormControl);
          if (validators && validators.required) {
            return 'required';
          } else {
            return null;
          }
        }
      }
  }
