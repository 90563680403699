<div class="full-screen registration-modal">
  <div class="full-height-page">
    <div class="top-logo">
      <img [src]="logo" alt="Rame rame logo" />
    </div>
    <div class=" text-center">
      <img [src]="mailSentLogo" alt="mail sent" />
      <h1 class="h3 mt-3 mb-4">{{'pages.login.check-inbox' | translate}}</h1>
      <p>{{'pages.login.reset-request-received' | translate}}</p>
      <p>{{'pages.login.check-email-guide' | translate}}</p>
      <span [routerLink]="['/sign-in']" class="button-link-white">{{'common.close' | translate}}</span>
    </div>
  </div>
</div>
