import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SupplierPageComponent } from './supplier/pages/supplier.page';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import {SupplierOrderPageComponent} from './supplier-order/pages/supplier-order.page';
import {SupplierOrderFormPageComponent} from './supplier-order/pages/supplier-order-form.page';
import {SupplierOrderReceivingPageComponent} from './supplier-order-receiving/pages/supplier-order-receiving.page';
import {
    SupplierOrderReceivingFormPageComponent
} from './supplier-order-receiving/pages/supplier-order-receiving-form.page';
import {SupplierItemPageComponent} from './supplier-item/pages/supplier-item.page';

const routes: Routes = [
  {path: 'supplier', component: SupplierPageComponent, canActivate: [AuthGuard]},
  {path: 'supplier/:id/item', component: SupplierItemPageComponent, canActivate: [AuthGuard]},
  {path: 'order', component: SupplierOrderPageComponent, canActivate: [AuthGuard]},
  {path: 'order/:action/:id', component: SupplierOrderFormPageComponent, canActivate: [AuthGuard]},
  {path: 'receiving-order', component: SupplierOrderReceivingPageComponent, canActivate: [AuthGuard]},
  {path: 'receiving-order/:action/:id', component: SupplierOrderReceivingFormPageComponent, canActivate: [AuthGuard]}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class SupplierRoutesModule {
  }
