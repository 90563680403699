import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BACKOFFICE, PARAMETER_BASE_URL } from '../../../../constants/api.constant';
import { PageInterface, PageQueryInterface } from '../../../../interfaces/page.interface';
import { ParameterModel } from '../models/parameter.model';

@Injectable({ providedIn: 'root' })
export class ParameterService {
  constructor(private http: HttpClient) {
  }

  get baseUrl(): string {
    return environment.url + BACKOFFICE + PARAMETER_BASE_URL;
  }

  async findOne(id: number): Promise<ParameterModel> {
    return await this.http.get<ParameterModel>(this.baseUrl + '/' + id).toPromise();
  }

  async findAll(p: PageQueryInterface): Promise<PageInterface<ParameterModel>> {
    return await this.http.post<PageInterface<ParameterModel>>(this.baseUrl + '/list',
      p).toPromise();
  }

  async store(data: ParameterModel): Promise<ParameterModel> {
    return await this.http.post<ParameterModel>(this.baseUrl,
      data).toPromise();
  }

  async update(id: number, data: ParameterModel): Promise<ParameterModel> {
    return await this.http.patch<ParameterModel>(this.baseUrl + `/${id}`,
      data).toPromise();
  }

  async delete(id: number): Promise<ParameterModel> {
    return await this.http.delete<ParameterModel>(this.baseUrl + `/${id}`,
      {}).toPromise();
  }
}
