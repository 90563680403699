import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {BaseComponentDirective} from '../../../../shared/base/base.component';
import {SupplierOrderDetailModel, SupplierOrderModel} from '../../supplier-order/models/supplier-order.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SupplierModel} from '../../supplier/models/supplier.model';
import {
    CRUD_MODE_CREATE,
    CRUD_MODE_EDIT,
    CRUD_MODE_VIEW,
    IN_STOCK_LIST,
    STATUS_LIST,
    UNIT_LIST
} from '../../../../constants/common.constant';
import {ItemGroupModel} from '../../../item/item-group/models/item-group.model';
import {SupplierOrderService} from '../../supplier-order/services/supplier-order.service';
import {SupplierService} from '../../supplier/services/supplier.service';
import {ItemGroupService} from '../../../item/item-group/services/item-group.service';
import {MatDialog} from '@angular/material/dialog';
import {BreadcrumbService} from '../../../../services/breadcrumb.service';
import {DialogScrollComponent} from '../../../../shared/components/dialog/dialog-scroll.component';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatAccordion} from '@angular/material/expansion';

@Component({
    selector: 'app-page-supplier-order-receiving-form',
    templateUrl: './supplier-order-receiving-form.page.html',
})
export class SupplierOrderReceivingFormPageComponent extends BaseComponentDirective implements OnInit, AfterViewInit{

    supplierOrder: SupplierOrderModel;
    form: FormGroup;
    mode = '';

    fileUrl: string | ArrayBuffer;
    fileListAvatar: FileList;
    fd: FormData;

    disableButton = true;
    total = 0;

    suppliers: SupplierModel[] = [];
    statuses = [];
    stockStatuses = IN_STOCK_LIST;

    protected readonly UNIT_LIST = UNIT_LIST;

    orderDetails: SupplierOrderDetailModel[] = [];
    itemGroups: ItemGroupModel[] = [];

    generateInvoiceCounter = 0;

    @ViewChild(MatAccordion) accordion: MatAccordion;

    constructor(
        injector: Injector,
        private formBuilder: FormBuilder,
        private supplierOrderService: SupplierOrderService,
        private supplierService: SupplierService,
        private itemGroupService: ItemGroupService,
        private dialog: MatDialog,
        private breadcrumbService: BreadcrumbService
    ) {
        super(injector);

        this.form = this.formBuilder.group({
            supplierId: [null, [Validators.required]],
            supplierName: [null, []],
            address: [null, []],
            phone: [null, []],
            contactPerson: [null, []],
            internalInvoiceNo: [null, [Validators.required]],
            supplierInvoiceNo: [null, []],
            orderDate: [null, [Validators.required]],
            receivedDate: [null, [Validators.required]],
            tax: [0, []],
            supplierDiscount: [0, []],
            otherAddition: [0, []],
            otherSubtraction: [0, []],
            orderedBy: [null, []],
            status: ['draft', [Validators.required]],
            stockStatus: ['none', []],
            paidDate: [null, []],
            verifiedDate: [null, []],
            stockedDate: [null, []],
            note: [null, []],
        });
    }

    async ngAfterViewInit(): Promise<any> {
      setTimeout(_ => {
        this.accordion.openAll();
      }, 1500);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.form.controls.status.disable({onlySelf: true});
        switch (this.activatedRoute.snapshot.params.action) {
            case 'edit': {
                this.mode = CRUD_MODE_EDIT;
                const id = this.activatedRoute.snapshot.params.id;
                this.supplierOrder = await this.supplierOrderService.findOne(id);
                this.fillFormAndDetails();
                this.statuses = STATUS_LIST;
                if (this.supplierOrder.status !== 'draft') {
                    const index = this.statuses.findIndex(sx => sx.key === 'draft');
                    if (index > -1) {
                        this.statuses.splice(index, 1);
                    }
                }
                this.refreshTotal();
                this.orderDetails = this.supplierOrder.details;
                this.disableButton = false;
                switch (this.supplierOrder.status) {
                    case 'sent':
                    case 'paid':
                    case 'pending':
                    case 'verified': {
                        this.form.controls.supplierId.disable();
                        this.form.controls.orderDate.disable();
                        this.form.controls.stockStatus.disable();
                        this.form.controls.receivedDate.setValue(new Date());

                        this.form.controls.tax.valueChanges.subscribe(_ => {
                            this.refreshTotal();
                        });

                        this.form.controls.otherAddition.valueChanges.subscribe(_ => {
                            this.refreshTotal();
                        });

                        this.form.controls.otherSubtraction.valueChanges.subscribe(_ => {
                            this.refreshTotal();
                        });

                        break;
                    }
                }
                break;
            }
            case 'view': {
                this.mode = CRUD_MODE_VIEW;
                const id = this.activatedRoute.snapshot.params.id;
                this.form.disable();
                this.disableButton = true;
                this.supplierOrder = await this.supplierOrderService.findOne(id);
                this.fillFormAndDetails();
                this.statuses = STATUS_LIST;
                if (this.supplierOrder.status !== 'draft') {
                    const index = this.statuses.findIndex(sx => sx.key === 'draft');
                    if (index > -1) {
                        this.statuses.splice(index, 1);
                    }
                }
                this.supplierOrder.details.forEach(d => {
                    this.total += d.subtotal;
                });
                this.itemGroups = await this.itemGroupService.findBySupplierId(this.form.controls.supplierId.value);
                this.orderDetails = this.supplierOrder.details;

                break;
            }
            default: this.mode = CRUD_MODE_VIEW;
        }

        this.breadcrumbService.changeMessage([
            {label: 'pages.supplier.supplier', active: false},
            {label: 'pages.supplier.order-receiving', active: false, route: '/supplier/receiving-order'},
            {label: 'pages.supplier.order-receiving-form', active: true, route: ''}
        ]);

        this.form.controls.status.valueChanges.subscribe(r => {
            if (this.supplierOrder && this.supplierOrder.status) {
                this.supplierOrder.status = r;
            }
        });

        this.suppliers  = await this.supplierService.findAllNoPaging();
    }

    refreshTotal(): void {
        this.total = 0;
        this.supplierOrder.details.forEach(d => {
            d.realQuantity = d.realQuantity ? this.ui.moneyFormatToNumber(d.realQuantity) : 0;
            d.itemDiscount = d.itemDiscount ? this.ui.moneyFormatToNumber(d.itemDiscount) : 0;
            this.total += d.subtotal;
        });
        const tax = this.ui.moneyFormatToNumber(this.form.controls.tax.value) / 100;
        this.total = this.total +
            this.ui.moneyFormatToNumber(this.form.controls.otherAddition.value) -
            this.ui.moneyFormatToNumber(this.form.controls.otherSubtraction.value);
        const taxValue = this.total * tax;
        this.total = this.total + taxValue;
    }

    async fillFormAndDetails(): Promise<void> {
      const supplier = await this.supplierService.findOne(this.supplierOrder.supplierId);
      this.form.setValue({
        supplierId: this.supplierOrder.supplierId,
        supplierName: supplier.supplierName,
        address: supplier.address,
        phone: supplier.phoneNumber,
        contactPerson: supplier.contactPerson,
        internalInvoiceNo: this.supplierOrder.internalInvoiceNo,
        supplierInvoiceNo: this.supplierOrder.supplierInvoiceNo,
        orderDate: this.supplierOrder.orderDate,
        receivedDate: this.supplierOrder.receivedDate,
        tax: this.supplierOrder.tax,
        supplierDiscount: this.supplierOrder.supplierDiscount,
        otherAddition: this.supplierOrder.otherAddition,
        otherSubtraction: this.supplierOrder.otherSubtraction,
        orderedBy: this.supplierOrder.orderedBy,
        status: this.supplierOrder.status,
        stockStatus: this.supplierOrder.stockStatus,
        paidDate: this.supplierOrder.paidDate,
        verifiedDate: this.supplierOrder.verifiedDate,
        stockedDate: this.supplierOrder.stockedDate,
        note: this.supplierOrder.note,
      });
    }

    doBeforeSave(mode: string, date: Date): void {
        const payload = {
            id: (this.mode === CRUD_MODE_EDIT) ? this.supplierOrder.id : null,
            supplierId: this.form.controls.supplierId.value,
            internalInvoiceNo: this.form.controls.internalInvoiceNo.value,
            supplierInvoiceNo: this.form.controls.supplierInvoiceNo.value,
            orderDate: this.form.controls.orderDate.value,
            receivedDate: (['received', 'verified', 'paid'].find(f => f === mode)) ? this.form.controls.receivedDate.value : null,
            tax: this.form.controls.tax.value,
            supplierDiscount: this.form.controls.supplierDiscount.value,
            otherAddition: this.form.controls.otherAddition.value,
            otherSubtraction: this.form.controls.otherSubtraction.value,
            orderedBy: this.form.controls.orderedBy.value,
            status: mode,
            stockStatus: this.form.controls.stockStatus.value,
            paidDate: (mode === 'paid') ? this.form.controls.paidDate.value : null,
            verifiedDate: (['verified', 'paid'].find(f => f === mode)) ? date : null,
            stockedDate: null,
            note: this.form.controls.note.value,
            details: []
        };

        this.orderDetails.forEach(od => {
            od.realQuantity = parseFloat(od.realQuantity);
            od.quantity = parseFloat(od.quantity);
            od.buyPrice = this.ui.moneyFormatToNumber(od.buyPrice);
            od.lastBuyPrice = parseFloat(od.lastBuyPrice);
            od.subtotal = od.buyPrice * od.realQuantity;
            payload.details.push(od);
        });

        const isCreateMode = this.mode === CRUD_MODE_CREATE;

        const dialog = this.dialog.open(DialogScrollComponent,  {
            data: {
                title: this.translateService.instant('dialog.save-message'),
                subtitle: this.translateService.instant('dialog.save-title'),
                content: (isCreateMode) ? `#${payload.internalInvoiceNo}` : `#${payload.id} - ${payload.internalInvoiceNo}`,
                buttons: [
                    {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.save')},
                    {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
                ]
            }
        });

        dialog.afterClosed().subscribe((res) => {
            if (res === 1) {
              this.supplierOrderService.update(payload).then((_) => {
                  this.doAfterSave(payload.id);
              });
            }
        });
    }

    save(): void {
        if (!this.form.valid) {
            this.markFormGroupAsTouched(this.form);
            return;
        }

        // check if all row checked
        for (const item of this.orderDetails) {
          if (!item.checked) {
            this.dialog.open(DialogScrollComponent, {
              data: {
                title: this.translateService.instant('dialog.alert-title'),
                subtitle: this.translateService.instant('dialog.alert-title'),
                content: `${item.itemGroupName} ${this.translateService.instant('dialog.item-not-checked')}`,
                buttons: [
                  {value: 0, className: 'text-brand-color', focus: true, label: this.translateService.instant('common.close')}
                ]
              }
            });
            return;
          }
        }

        // check if there is evidence file
        if (!this.fd) {
          const dlg = this.dialog.open(DialogScrollComponent, {
            data: {
              subtitle: this.translateService.instant('dialog.alert-title'),
              content: this.translateService.instant('dialog.no-file-uploaded'),
              buttons: [
                {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.confirm')},
                {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
              ]
            }
          });

          dlg.afterClosed().subscribe(r => {
            if (r === 1) {
              this.doBeforeSave('received', this.date.nowToDbDate());
            }
          });
        } else {
          this.doBeforeSave('received', this.date.nowToDbDate());
        }
    }

    doAfterSave(id: number): void {
        if (this.fd) {
            this.uploadFile(id).then(_ => {
              this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
                verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
              });

              this.router.navigate(['/supplier/receiving-order']).then();
            });
        } else {

          this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });

          this.router.navigate(['/supplier/receiving-order']).then();
        }
    }

    getFileChange($event: FileList): void {
        this.fd = new FormData();
        const fileTransfer = $event[0];
        this.fd.append('file', fileTransfer);

    }

    async uploadFile(id: number): Promise<any> {
        return await this.supplierOrderService.uploadFile(this.fd, id);
    }

    changeTotal(column: string, index: number, value: any): void {
        switch (column) {
            case 'buyPrice': {
              this.supplierOrder.details[index].buyPrice = value;
              this.supplierOrder.details[index].checked = 0;
              break;
            }
            case 'itemDiscount': this.supplierOrder.details[index].itemDiscount = value; break;
            case 'realQuantity': {
              this.supplierOrder.details[index].realQuantity = value;
              this.supplierOrder.details[index].checked = 0;
              break;
            }
        }
        this.supplierOrder.details[index].subtotal = (this.ui.moneyFormatToNumber(this.supplierOrder.details[index].realQuantity) *
                    this.ui.moneyFormatToNumber(this.supplierOrder.details[index].buyPrice)) -
                    this.ui.moneyFormatToNumber(this.supplierOrder.details[index].itemDiscount);
        this.refreshTotal();
    }

    downloadInvoice(): void {
        this.supplierOrderService.downloadFile(this.supplierOrder.id).subscribe((data: Blob) => {
            const blob = new Blob([data], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'downloaded-file.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        });
    }

  checkQtyAndPrice($event: MatCheckboxChange, d: SupplierOrderDetailModel): void {
      if ($event.checked) {
        d.checked = 1;
        let msg = '';
        let extra = '';
        if (parseFloat(d.quantity) !== parseFloat(d.realQuantity)) {
          msg = `${d.itemGroupName} has different quantity are you sure want to confirm this? `;
          extra = `order quantity is ${d.quantity} while incoming quantity is ${d.realQuantity}`;
        }

        if (parseFloat(d.lastBuyPrice) !== parseFloat(d.buyPrice)) {
          msg = `${d.itemGroupName} has different buy price from last time are you sure want to confirm this? `;
          extra = `last buy price is ${this.ui.currencyFormat(d.lastBuyPrice, 'en')} while new buy price is is ${d.buyPrice}`;
        }

        if (msg === '') {
          d.checked = 1;
        } else {
          const dlg = this.dialog.open(DialogScrollComponent, {
            data: {
              subtitle: this.translateService.instant('dialog.alert-title'),
              content: msg,
              extraText: extra,
              buttons: [
                {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.confirm')},
                {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
              ]
            }
          });

          dlg.afterClosed().subscribe(r => {
            if (r === 0) {
              d.checked = 0;
            }
          });
        }

      } else {
        d.checked = 0;
      }
  }

    cancel(): void {
        this.router.navigate(['/supplier/order']).then();
    }
}
