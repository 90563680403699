import { Component, OnInit, Injector, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_EDIT, CRUD_MODE_VIEW, CRUD_MODE_CREATE } from 'src/app/constants/common.constant';
import { BaseComponentDirective } from 'src/app/shared/base/base.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { SupplierModel } from '../models/supplier.model';
import { SupplierService } from '../services/supplier.service';

@Component({
    selector: 'app-modal-supplier',
    templateUrl: './supplier.modal.html',
})
export class SupplierModal extends BaseComponentDirective implements OnInit{
    public result = new EventEmitter();
    public form: FormGroup;
    public mode = '';
    public supplier: SupplierModel;
    public viewMode = false;

    fileUrl: string | ArrayBuffer;
    fileListAvatar: FileList;
    fd: FormData;

    constructor(
      injector: Injector,
      public dialogRef: MatDialogRef<SupplierModal>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private formBuilder: FormBuilder,
      private supplierService: SupplierService,
      private dialog: MatDialog
    ) {
      super(injector);
      this.form = this.formBuilder.group({
        supplierName: [null, [Validators.required]],
        contactPerson: [null, [Validators.required]],
        address: [null, [Validators.required]],
        phoneNumber: [null, [Validators.required]],
      });
    }

    async ngOnInit(): Promise<void> {
      super.ngOnInit();
      this.mode = this.data.mode;
      this.supplier = this.data.supplier;

      switch (this.mode) {
        case CRUD_MODE_EDIT: {
          this.form.setValue({
            supplierName: this.supplier.supplierName,
            address: this.supplier.address,
            contactPerson: this.supplier.contactPerson,
            phoneNumber: this.supplier.phoneNumber,
          });

          break;
        }
        case CRUD_MODE_VIEW: {
          this.readonly = true;
          this.form.setValue({
            supplierName: this.supplier.supplierName,
            address: this.supplier.address,
            contactPerson: this.supplier.contactPerson,
            phoneNumber: this.supplier.phoneNumber,
          });

          this.viewMode = true;
          break;
        }
      }
    }

    save(): void {
      if (!this.form.valid) {
        this.markFormGroupAsTouched(this.form);
        return;
      }
      const payload = {
        id: (this.mode === CRUD_MODE_EDIT) ? this.supplier.id : null,
        supplierName: this.form.controls.supplierName.value,
        address: this.form.controls.address.value,
        contactPerson: this.form.controls.contactPerson.value,
        phoneNumber: this.form.controls.phoneNumber.value,
      };

      const isCreateMode = this.mode === CRUD_MODE_CREATE;

      const dialog = this.dialog.open(DialogScrollComponent,  {
        data: {
          title: this.translateService.instant('dialog.save-message'),
          subtitle: this.translateService.instant('dialog.save-title'),
          content: (isCreateMode) ? `#${payload.supplierName}` : `#${payload.id} - ${payload.supplierName}`,
          buttons: [
            {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.save')},
            {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
          ]
        }
      });

      dialog.afterClosed().subscribe((res) => {
        if (res === 1) {
          if (isCreateMode) {
            this.supplierService.save(payload).then((r) => {
              this.doAfterSave(r.id);
            });
          } else {
            this.supplierService.update(payload).then((_) => {
              this.doAfterSave(payload.id);
            });
          }
        }
      });
    }

    doAfterSave(id: number): void {
      if (this.fd) {
        this.uploadFile(id);
      }

      this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
        verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
      });
      this.result.emit(1);
      this.dialogRef.close();
    }

    getFileChange($event: FileList): void {
      this.fd = new FormData();
      const fileTransfer = $event[0];
      this.fd.append('file', fileTransfer);

    }

    async uploadFile(id: number): Promise<any> {
      return await this.supplierService.uploadFile(this.fd, id);
    }
  }
