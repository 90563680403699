import {Component, EventEmitter, Inject, Injector, OnInit, Output} from '@angular/core';
import {ItemGroupService} from '../services/item-group.service';
import {ProductService} from '../../../master/product/services/product.service';
import {ProductSubService} from '../../../master/product-sub/services/product-sub.service';
import {SupplierService} from '../../../supplier/supplier/services/supplier.service';
import {ProductModel} from '../../../master/product/models/product.model';
import {ProductSubModel} from '../../../master/product-sub/models/product-sub.model';
import {SupplierModel} from '../../../supplier/supplier/models/supplier.model';
import {ItemGroupModel} from '../models/item-group.model';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {SelectedItemGroupService} from '../services/selected-item-group.service';

@Component({
  selector: 'app-picker-item-group',
  templateUrl: './item-group.picker.html',
})
export class ItemGroupPicker extends BasePageComponentDirective<ItemGroupModel> implements OnInit{
  products: ProductModel[] = [];
  subProducts: ProductSubModel[] = [];
  suppliers: SupplierModel[] = [];
  public result = new EventEmitter();
  selectedItemGroup: ItemGroupModel;
  itemImageBaseURL = environment.storage + '/images/item-group/';
  currentPageCheckedItemGroups: Map<number, ItemGroupModel> = new Map<number, ItemGroupModel>();

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ItemGroupPicker>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private itemGroupService: ItemGroupService,
    private productService: ProductService,
    private subProductService: ProductSubService,
    private supplierService: SupplierService,
    private selectedItemGroupService: SelectedItemGroupService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.products = await this.productService.findAllNoPaging();
    this.subProducts = await this.subProductService.findAllNoPaging(0);
    this.suppliers = await this.supplierService.findAllNoPaging();
    await this.query(); // Wait for query to complete
    this.initializeCheckedItemGroups(); // Update checked items after data retrieval
    this.selectedItemGroupService.selectedItemGroups$.subscribe(itemGroups => {
      this.currentPageCheckedItemGroups.clear();
      itemGroups.forEach(itemGroup => {
        this.currentPageCheckedItemGroups.set(itemGroup.id, itemGroup);
      });
    });
  }

  query(): void {
    this.pageQuery.pageSize = 5;
    this.itemGroupService.findAll(this.pageQuery).then((res) => {
      this.page.emit(res);
      this.initializeCheckedItemOldData();
    });
  }

  choose(): void {
    const selectedItemGroups = Array.from(this.currentPageCheckedItemGroups.values());
    console.log(this.products, this.selectedItemGroup, this.subProducts);
    if (selectedItemGroups.length > 0) {
      selectedItemGroups.forEach((itemGroup, index) => {
        const p = this.products.find(px => parseInt(px.id, 10) === parseInt(itemGroup.productId, 10));
        const ps = this.subProducts.find(psx => parseInt(psx.id, 10) === parseInt(itemGroup.subProductId, 10));
        if (p) {
          selectedItemGroups[index].productName = p.productName;
        }
        if (ps) {
          selectedItemGroups[index].subProductName = ps.subProductName;
        }
      });

      this.result.emit(selectedItemGroups);
    } else {
      console.log('No item group selected');
    }

    this.dialogRef.close();
  }
  initializeCheckedItemOldData(): void{
    if (this.data.selectedItemGroups) {
      this.data.selectedItemGroups.forEach(itemGroupId => {
        const itemGroup = this.pageItems.find(item => item.id === itemGroupId);
        if (itemGroup) {
          this.currentPageCheckedItemGroups.set(itemGroupId, itemGroup);
        }
      });
    }
  }

  initializeCheckedItemGroups(): void {
    // Update checkedItemGroupsMap based on service data or previous page selections
    if (this.selectedItemGroupService.checkedItemGroups) {
      this.currentPageCheckedItemGroups = new Map(this.selectedItemGroupService.checkedItemGroups);
    } else {
      this.currentPageCheckedItemGroups = new Map(); // Initialize empty map
    }
  }
  // tslint:disable-next-line:typedef
  checkMulti($event, item: any, rowIndex: any) {
    this.selectedItemGroupService.toggleItemGroup(item);
    const isChecked = $event.checked;
    if (isChecked) {
      // Update the checked item groups for the current page
      this.currentPageCheckedItemGroups.set(item.id, item);
    } else {
      this.currentPageCheckedItemGroups.delete(item.id);
    }
    this.selectedItemGroupService.checkedItemGroups = new Map(this.currentPageCheckedItemGroups);
  }
}
