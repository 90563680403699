import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BasePageService } from 'src/app/shared/base/base-page.service';
import { MarginModel } from 'src/app/pages/master/margin/models/margin.model';
import { environment } from 'src/environments/environment';
import { BACKOFFICE, MARGIN_BASE_URL } from 'src/app/constants/api.constant';

@Injectable({providedIn: 'root'})
export class MarginService extends BasePageService<MarginModel>{
  constructor(public http: HttpClient) {
    super(http);
  }

  get baseUrl(): string {
    return environment.url + BACKOFFICE + MARGIN_BASE_URL;
  }

}
