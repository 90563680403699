export const DEFAULT_LANGUAGE = 'EN';
export const FORM_GROUP_VALID = 'VALID';
export const JWT_TOKEN_KEY = 'access_token';
export const STORAGE_LANG_KEY = 'lang';
export const STORAGE_PROFILE_KEY = 'profile';
export const DB_DATE_FORMAT = 'YYYY-MM-DD';
export const DB_TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const APP_DATE_FORMAT = 'D MMMM YYYY';
export const APP_DATETIME_COMPLETE_FORMAT = 'dddd, D MMMM YYYY HH:mm:ss';
export const DEFAULT_NO_AVATAR = 'assets/images/others/noavatar.png';
export const MAIL_SENT_LOGO = '/assets/images/icons/mail-send.svg';
export const AVAILABLE_LANGUAGES = [
  {langCode: 'ID', langValue: 'Bahasa'},
  {langCode: 'EN', langValue: 'English'}
];
export const PICKER_DATE_FORMATS = {
  parse: { dateInput: 'YYYY-MM-DD' }, // Change 'LL' to 'DD MMMM YYYY'
  display: {
    dateInput: 'DD MMMM YYYY', // Change 'LL' to 'DD MMMM YYYY'
    monthYearLabel: 'MMM YYYY', // You can keep this format if you want
    dateA11yLabel: 'LL', // You can keep this format if you want
    monthYearA11yLabel: 'MMMM YYYY', // You can keep this format if you want
  },
};

export const MONTH_LIST_MULTI_LANG = [
  {month: 1, id: 'Januari', en: 'January', short_id: 'Jan', short_en: 'Jan'},
  {month: 2, id: 'Februari', en: 'February', short_id: 'Feb', short_en: 'Feb'},
  {month: 3, id: 'Maret', en: 'March', short_id: 'Mar', short_en: 'Mar'},
  {month: 4, id: 'April', en: 'April', short_id: 'Apr', short_en: 'Apr'},
  {month: 5, id: 'Mei', en: 'May', short_id: 'Mei', short_en: 'May'},
  {month: 6, id: 'Juni', en: 'June', short_id: 'Jun', short_en: 'Jun'},
  {month: 7, id: 'Juli', en: 'July', short_id: 'Jul', short_en: 'Jul'},
  {month: 8, id: 'Agustus', en: 'August', short_id: 'Agust', short_en: 'Aug'},
  {month: 9, id: 'September', en: 'September', short_id: 'Sept', short_en: 'Sept'},
  {month: 10, id: 'Oktober', en: 'October', short_id: 'Okt', short_en: 'Oct'},
  {month: 11, id: 'November', en: 'November', short_id: 'Nov', short_en: 'Nov'},
  {month: 12, id: 'Desember', en: 'December', short_id: 'Des', short_en: 'Dec'},
];
export const VALID_YEARS = [{year: 2019}, {year: 2020}, {year: 2021}, {year: 2022}, {year: 2023}, {year: 2024}, {year: 2025}];

export const CRUD_MODE_CREATE = 'create';
export const CRUD_MODE_EDIT = 'edit';
export const CRUD_MODE_DELETE = 'delete';
export const CRUD_MODE_VIEW = 'view';

export const UNIT_LIST = [
  {key: 'KG', value: 'KG', long: 'KILOGRAM'},
  {key: 'PACK', value: 'PACK', long: 'PACK'},
  {key: 'CAN', value: 'CAN', long: 'CAN'},
  {key: 'BTL', value: 'BTL', long: 'BOTTLE'},
  {key: 'JRG', value: 'JRG', long: 'JIRIGEN'},
  {key: 'ROLL', value: 'ROLL', long: 'ROLL'},
  {key: 'PCS', value: 'PCS', long: 'PIECES'},
];

export const STATUS_LIST = [
  {key: 'DRAFT', value: 'draft'},
  {key: 'SENT', value: 'sent'},
  {key: 'PENDING PAYMENT', value: 'pending'},
  {key: 'VERIFIED', value: 'verified'},
  {key: 'RECEIVED', value: 'received'},
  {key: 'PAID', value: 'paid'},
  {key: 'PROCEED', value: 'proceed'},
  {key: 'FINISH', value: 'finish'},
];

export const IN_STOCK_LIST = [
  {key: 'NONE', value: 'none'},
  {key: 'REVIEW', value: 'review'},
  {key: 'STOCKED', value: 'stocked'},
];

export const DELIVERY_STATUS = [
  {key: 'NONE', value: 'none'},
  {key: 'PACKING', value: 'packing'},
  {key: 'DELIVERED', value: 'delivered'},
  {key: 'RECEIVED', value: 'received'},
];

export const DIRECTORATES = [
  {key: 'KITCHEN', value: 1},
  {key: 'BAR', value: 2},
  {key: 'SPA', value: 3},
];

export const DEFAULT_HQ_STORAGE = 1;
export const REPORT_TYPES = [
  {key: 'EXCEL', value: 'excel'},
  {key: 'PDF', value: 'pdf'},
];

export const USER_ROLE = {
  ADMIN: 1,
  CUSTOMER: 2,
  COURIER: 3,
  ACCOUNTING: 4,
};
