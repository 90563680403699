<div class="white-background p-5">
  <form [formGroup]="form" (submit)="save()">
    <h4 class="mat-dialog-title px-4" >{{'pages.supplier.supplier-order-detail-editor' | translate}}</h4>
    <mat-dialog-content style="width: 600px;">
      <div class="row">
        <div class="col-12">
          <app-select formControlName="itemGroupId"
                      [items]="itemGroups"
                      [bindLabel]="'itemGroupName'"
                      [bindValue]="'id'"
                      [label]="'pages.supplier.item-group-name' | translate"></app-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-number-field [readonly]="true" formControlName="currentStock" [label]="'pages.supplier.current-stock' | translate"></app-number-field>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <app-number-field formControlName="quantity" [label]="'pages.supplier.quantity' | translate"></app-number-field>
        </div>
        <div class="col-4">
          <app-number-field [readonly]="true" formControlName="buyPrice" [label]="'pages.supplier.buy-price' | translate"></app-number-field>
        </div>
        <div class="col-4">
          <app-number-field [readonly]="true" formControlName="subtotal" [label]="'pages.supplier.subtotal' | translate"></app-number-field>
        </div>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions  class="justify-content-end pe-4">
      <button *ngIf="!viewMode" mat-raised-button class="py-1" color="primary">{{'common.save' | translate}}</button>
      <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
    </mat-dialog-actions>
  </form>
</div>
