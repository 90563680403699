import {AfterViewInit, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DEFAULT_LANGUAGE, JWT_TOKEN_KEY} from './constants/common.constant';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'backoffice ramerame.co.id';
  public profile: any = {};
  constructor(
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    let langCode = localStorage.getItem('lang');
    if (!langCode) {
      langCode = DEFAULT_LANGUAGE;
      localStorage.setItem('lang', langCode);
    }
    this.translate.setDefaultLang(langCode.toLocaleLowerCase());
    this.translate.use(langCode.toLocaleLowerCase());
  }

  ngAfterViewInit(): void {
    const token = localStorage.getItem(JWT_TOKEN_KEY);
    if (token && token.length > 5) {

    }
  }
}
