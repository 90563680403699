import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-signin-layout',
  templateUrl: './signin-layout.component.html'
})
export class SigninLayoutComponent implements OnInit{
  ngOnInit(): void {
  }
}
