import { Component, Injector, OnInit } from '@angular/core';
import { BasePageTableComponent } from '../../../../shared/base/base-page-table.component';
import { PageInterface, PageQueryInterface } from '../../../../interfaces/page.interface';
import { ParameterDetailModel } from '../models/parameter-detail.model';
import { ParameterService } from '../services/parameter.service';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { MatDialog } from '@angular/material/dialog';
import { ParameterDetailService } from '../services/parameter-detail.service';
import { ParameterModal, ParameterModalProps } from '../modals/parameter.modal';
import { ParameterModel } from '../models/parameter.model';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppComponentModule } from '../../../../shared/components/component.module';
import { CurrencyPipe, NgForOf } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { XTableButton, XTableColumn, XTableComponent } from '../../../../shared/components/xtable/xtable.component';
import { ParameterDetailModal, ParameterDetailModalProps } from '../modals/parameter-detail.modal';
import { DialogScrollComponent } from '../../../../shared/components/dialog/dialog-scroll.component';

@Component({
  selector: 'app-parameter-detail',
  standalone: true,
  imports: [
    AppComponentModule,
    CurrencyPipe,
    MatButton,
    MatIcon,
    NgForOf,
    ReactiveFormsModule,
    TranslateModule,
    XTableComponent,
  ],
  templateUrl: './parameter-detail.component.html',
  styles: ``,
})
export class ParameterDetailComponent extends BasePageTableComponent<ParameterDetailModel> implements OnInit {

  public form: FormGroup;
  columns: XTableColumn<ParameterDetailModel>[] = [
    {
      name: 'parameterName',
      label: 'pages.parameter.name',
      isSearchable: true,
      isFilterable: true,
      isSortable: true,
    },
    {
      name: 'label',
      label: 'pages.parameter.label',
      isSearchable: true,
      isFilterable: true,
      isSortable: true,
    },
    {
      name: 'description',
      label: 'pages.parameter.description',
      isFilterable: true,
      isSearchable: true,
    },
    {
      name: 'value',
      label: 'pages.parameter.value',
      isSearchable: true,
      isFilterable: true,
      isSortable: true,
    },
    {
      name: 'valueAlt',
      label: 'pages.parameter.value-alt',
      isSearchable: true,
      isFilterable: true,
      isSortable: true,
    },
    {
      name: 'extras',
      label: 'pages.parameter.extras',
      isSearchable: true,
      isFilterable: true,
      isSortable: true,
    },
  ];
  buttons: XTableButton<ParameterDetailModel>[] = [
    {
      name: 'edit',
      icon: 'edit',
      onClick: (d) => this.openEditParameterDetailDialog(d),
      tooltip: 'pages.parameter.edit-parameter-detail',
    },
    {
      name: 'delete',
      icon: 'delete',
      onClick: (d) => this.delete(d),
      color: 'warn',
      tooltip: 'pages.parameter.delete-parameter-detail',
    },
  ];

  private parameter: ParameterModel;
  private parameterId: number;

  constructor(
    injector: Injector,
    private parameterService: ParameterService,
    private parameterDetailService: ParameterDetailService,
    private breadcrumbService: BreadcrumbService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) {
    super(injector);

    this.form = this.formBuilder.group({
      parameterName: [null, [Validators.required]],
      description: [null],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.parameterId = this.activatedRoute.snapshot.params.id;
    this.breadcrumbService.changeMessage([
      { label: 'menu.master.index', active: false, route: '' },
      { label: 'pages.parameter.parameter', active: true, route: '' },
      { label: 'pages.parameter.view-parameter', active: true, route: '' },
    ]);

    this.loadParameter();
  }

  requestData(query: PageQueryInterface): Promise<PageInterface<ParameterDetailModel>> {
    return this.parameterDetailService.findAll(this.parameterId, query);
  }

  async loadParameter() {
    this.parameter = await this.parameterService.findOne(this.parameterId);
    this.form.patchValue(this.parameter);
  }

  openEditParameterDialog() {
    const dialog = this.dialog.open<ParameterModal, ParameterModalProps>(ParameterModal, {
      data: {
        model: this.parameter,
      },
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res !== undefined) {
        this.form.patchValue(res);
        this.parameter = res;
      }
    });
  }

  openCreateParameterDetailDialog() {
    const dialog = this.dialog.open<ParameterDetailModal, ParameterDetailModalProps>(ParameterDetailModal, {
      data: {
        model: {},
        parameterId: this.parameterId,
      },
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res !== undefined) {
        this.reload();
      }
    });
  }

  openEditParameterDetailDialog(model: ParameterDetailModel) {
    const dialog = this.dialog.open<ParameterDetailModal, ParameterDetailModalProps>(ParameterDetailModal, {
      data: {
        model: model,
        parameterId: this.parameterId,
      },
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res !== undefined) {
        this.reload();
      }
    });
  }

  delete(d: ParameterDetailModel): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.parameterName}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-danger',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.delete'),
          },
          { value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close') },
        ],
      },
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.parameterDetailService.delete(this.parameterId, d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000,
          });
          this.reload();
        });
      }
    });
  }
}
