import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { AreaSubModel } from 'src/app/pages/master/area-sub/models/area-sub.model';
import { BACKOFFICE, IMAGE_BASE_URL, AREA_SUB_BASE_URL } from 'src/app/constants/api.constant';
import { PageQueryInterface, PageInterface } from 'src/app/interfaces/page.interface';
import { AreaModel } from 'src/app/pages/master/area/models/area.model';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class AreaSubService {
  constructor(private http: HttpClient) {
  }

  async findAll(p: PageQueryInterface, areaId: number): Promise<PageInterface>{
    return await this.http.get<PageInterface>(environment.url + BACKOFFICE + AREA_SUB_BASE_URL + `?page=${p.pageNo}&limit=${p.pageSize}&search-term=${p.searchTerm}&area-id=${areaId}`, {}).toPromise();
  }

  async findAllNoPaging(areaId: number): Promise<AreaSubModel[]>{
    return await this.http.get<AreaSubModel[]>(environment.url + BACKOFFICE + AREA_SUB_BASE_URL + `/all/${areaId}`, {}).toPromise();
  }

  async findOne(areaSubId: number): Promise<AreaSubModel>{
    return await this.http.get<AreaModel>(environment.url + BACKOFFICE + AREA_SUB_BASE_URL + `/one/${areaSubId}`, {}).toPromise();
  }

  async save(areaSub: AreaSubModel): Promise<AreaSubModel>{
    return await this.http.post<AreaModel>(environment.url + BACKOFFICE + AREA_SUB_BASE_URL, areaSub , {}).toPromise();
  }

  async update(areaSub: AreaSubModel): Promise<AreaSubModel>{
    return await this.http.put<AreaModel>(environment.url + BACKOFFICE + AREA_SUB_BASE_URL, areaSub , {}).toPromise();
  }

  async delete(areaSubId: number): Promise<any>{
    return await this.http.delete<any>(environment.url + BACKOFFICE + AREA_SUB_BASE_URL + `/one/${areaSubId}`, {}).toPromise();
  }

  async uploadFile(fd: FormData, areaSubId: number): Promise<any> {
    return await this.http.post<any>(environment.url + BACKOFFICE + IMAGE_BASE_URL + `/${areaSubId}/upload/area-sub`, fd, {}).toPromise();
  }
}
