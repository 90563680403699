export type PageInterface<T extends unknown = any> = {
  items: T[];
  itemDtos: any[];
  links: string;
  meta: PageMetaInterface;
};

export type PageMetaInterface = {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
};

export type PageQueryInterface = {
  sortList?: SortBy[];
  pageNo?: number;
  pageSize?: number;
  searchTerm?: string;
  filters?: Filter[];
  searchFields?: string[];
};

export enum Operator {
  EQUAL = '=', // Equal to
  NOT_EQUAL = '!=', // Not equal to
  GREATER_THAN = '>', // Greater than
  GREATER_THAN_EQUAL = '>=', // Greater than or equal to
  LESS_THAN = '<', // Less than
  LESS_THAN_EQUAL = '<=', // Less than or equal to
  LIKE = 'like', // Like (case-insensitive search)
}

export enum ColumnType {
  STRING,
  NUMBER,
  DATE,
  DATETIME,
}

export type Filter = {
  fieldName: string;
  operator: Operator;
  fieldValue: any;
  type: ColumnType;
}

export type ColumnInterface = {
  fieldName: string;
  type?: ColumnType;
  isFilterable?: boolean;
}

export type SortBy = {
  fieldName: string;
  direction: 'ASC' | 'DESC';
}
