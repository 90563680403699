import { Component, OnInit, Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_CREATE, CRUD_MODE_EDIT, CRUD_MODE_VIEW } from 'src/app/constants/common.constant';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BasePageComponentDirective } from 'src/app/shared/base/base-page.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { MarginModal } from '../modals/margin.modal';
import { MarginModel } from '../models/margin.model';
import { MarginService } from '../services/margin.service';
import { AreaModel } from '../../area/models/area.model';
import { AreaSubModel } from '../../area-sub/models/area-sub.model';
import { AreaService } from '../../area/services/area.service';
import { AreaSubService } from '../../area-sub/services/area-sub.service';

@Component({
  selector: 'app-page-margin',
  templateUrl: './margin.page.html',
})
export class MarginPageComponent extends BasePageComponentDirective<MarginModel> implements OnInit{

  areas: AreaModel[] = [];
  subAreas: AreaSubModel[] = [];

  constructor(
    injector: Injector,
    private marginService: MarginService,
    private areaService: AreaService,
    private areaSubService: AreaSubService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.areas = await this.areaService.findAllNoPaging();
    this.subAreas = await this.areaSubService.findAllNoPaging(0);
    this.query();

    this.breadcrumbService.changeMessage([
      {label: 'menu.master.index', active: false, route: ''},
      {label: 'pages.margin.margin', active: true, route: ''}
    ]);
  }

  query(): void {
    this.marginService.findAll(this.pageQuery).then((res) => {
      this.page.emit(res);
    });
  }

  create(): void {
    const dialog = this.dialog.open(MarginModal, {
      data: {
        mode: CRUD_MODE_CREATE
      }
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res === 1) {
        this.query();
      }
    });
  }

  edit(d: MarginModel): void {
    this.marginService.findOne(d.id).then(margin => {
      const dialog = this.dialog.open(MarginModal, {
        data: {
          margin,
          mode: CRUD_MODE_EDIT
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.query();
        }
      });
    });

  }

  view(d: MarginModel): void {
    this.marginService.findOne(d.id).then(margin => {
      this.dialog.open(MarginModal, {
        data: {
          margin,
          mode: CRUD_MODE_VIEW
        }
      });
    });

  }

  delete(d: MarginModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.marginName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-danger', color: 'primary', focus: true, label: this.translateService.instant('common.delete')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.marginService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }

}
