import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AppLayoutModule} from './layout/layout.module';
import {AppPageModule} from './pages/page.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgProgressModule} from 'ngx-progressbar';
import {CommonModule} from '@angular/common';
import {NgProgressHttpModule} from 'ngx-progressbar/http';
import {AuthGuard} from './auth/auth-guard.service';
import {Auth} from './auth/auth.service';
import { JwtModule } from '@auth0/angular-jwt';
import { ErrorInterceptorService } from './interceptors/error.interceptor';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function tokenGetter(): string {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: ['api.patala-stock.com', 'localhost:3000', 'api.crowdestate.id']
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgProgressModule.withConfig({
      min: 20,
      meteor: true,
      color: '#ffa726',
    }),

    NgProgressHttpModule,
    AppLayoutModule,
    AppPageModule,
  ],
  providers: [
    Auth,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
