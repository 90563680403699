<div class="main-content homepage grey-background">
    <div class="container-fluid">
        <div class="mat-card m- white-background text-black-50 rounded-lg">
            <div class="mat-card-title px-3 py-3">
                <h3 class="pl-2">{{'pages.customer.customer-order' | translate}}</h3>
            </div>
            <div class="mat-card-content px-3 rounded">
                <div class="row">
                    <div class="col-3">
                      <div class="form-group">
                        <label>{{'common.search' | translate}}</label>
                        <div class="input-group mb-3">
                          <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control" aria-label="search">
                          <div class="input-group-append">
                                <span class="input-group-text" id="suffix_password_show">
                                    <span class="icon-search"></span>
                                </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <app-select (valueChange)="getChanged('status', $event)"
                                  [items]="stockStatuses"
                                  [bindLabel]="'key'"
                                  [bindValue]="'value'"
                                  [label]="'pages.supplier.stock-status' | translate" [(ngModel)]="status"></app-select>
                    </div>
                    <div class="col-2">
                      <app-select (valueChange)="getChanged('status', $event)"
                                  [items]="customers"
                                  [bindLabel]="'key'"
                                  [bindValue]="'value'"
                                  [label]="'pages.customer.customer-name' | translate" [(ngModel)]="customerName"></app-select>
                    </div>
                    <div class="col-2">
                      <app-datepicker (valueChange)="getChanged('date', $event)" [(ngModel)]="datePick" [label]="'pages.customer.order-date' | translate"></app-datepicker>
                    </div>
                    <div class="col-3 pt-3 text-end">
                      <button (click)="upload()" mat-flat-button color="accent" class="text-white" style="margin-right: 5px;">{{'common.upload' | translate}}</button>
                      <button (click)="create()" mat-flat-button color="primary" class="text-white">{{'common.create' | translate}}</button>
                    </div>
                </div>
            </div>
            <div class="mat-card-content px-3 pb-3">
                <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th class="text-nowrap">{{'pages.customer.invoice-no' | translate}}</th>
                        <th class="text-nowrap">{{'pages.customer.order-date' | translate}}</th>
                        <th class="text-nowrap">{{'pages.customer.delivery-date' | translate}}</th>
                        <th class="text-nowrap">{{'pages.customer.customer-name' | translate}}</th>
                        <th class="text-nowrap">{{'pages.customer.status' | translate}}</th>
                        <th class="text-nowrap">{{'pages.customer.delivery-status' | translate}}</th>
                        <th class="sticky-column text-center">{{'common.action' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let d of pageItems">
                        <td class="text-nowrap">{{d.invoiceNo}}</td>
                        <td class="text-nowrap">{{d.orderDate | date}}</td>
                        <td class="text-nowrap">{{d.deliveryDate | date}}</td>
                        <td class="text-nowrap">{{maskingColumn('customerName', 'id', customers, d.customerId)}}</td>
                        <td class="text-nowrap">{{d.status.toUpperCase()}}</td>
                        <td class="text-nowrap">{{d.deliveryStatus.toUpperCase()}}</td>
                        <td class="sticky-column text-center">
                            <div class="btn-group" role="group" aria-label="Button Action">
                                <button (click)="view(d)" type="button" mat-button color="primary">
                                  <mat-icon>fullscreen</mat-icon>
                                </button>
                                <button *ngIf="d.deliveryStatus !== 'received'" (click)="edit(d)" mat-button class="text-orange" color="primary">
                                  <mat-icon>edit_square</mat-icon>
                                </button>
                                <button *ngIf="d.status === 'draft'" (click)="delete(d)" type="button"  mat-button color="warn">
                                  <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="mat-card-content">
                <div class="row mb-4">
                    <div class="col-12 text-center">
                        <app-pagination
                          (goPage)="toPage($event)"
                          [pagesToShow]="showPages"
                          [page]="pageNo"
                          [perPage]="pageSize"
                          [count]="totalRow"></app-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
