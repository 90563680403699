<div class="white-background p-5">
  <form [formGroup]="form" (submit)="save()">
    <h4 class="mat-dialog-title px-4">{{'pages.audit.upload-template-xlsx' | translate}}</h4>
    <mat-dialog-content style="width: 600px;">

      <div class="row">
        <div class="col-12">
          <app-upload-single
            [readonly]="readonly"
            [fileUrl]="fileUrl"
            [accept]="'*/*'"
            [label]="'pages.audit.file-xlsx' | translate"
            [labelButton]="'pages.audit.xlsx-button' | translate" (fileChange)="getFileChange($event)">

          </app-upload-single>
        </div>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions  class="justify-content-end pe-4">
      <button
        mat-raised-button
        class="py-1"
        color="primary">{{'common.update' | translate}}</button>

      <button type="button"
              mat-dialog-close
              mat-stroked-button
              class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
    </mat-dialog-actions>
  </form>
</div>
