import {Component} from '@angular/core';
import {MAIL_SENT_LOGO} from '../../constants/common.constant';

@Component({
  selector: 'app-page-mail-sent',
  templateUrl: './mail-sent.page.html',
})
export class MailSentPageComponent{
  public logo = '';
  public mailSentLogo = MAIL_SENT_LOGO;
}
