import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {MainComponent} from './main/main.component';
import {CommonModule} from '@angular/common';
import {SidenavComponent} from './sidenav/sidenav.component';
import {SidenavItemComponent} from './sidenav/sidenav-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgProgressModule} from 'ngx-progressbar';
import {BlankLayoutComponent} from './blank-layout/blank-layout.component';
import {SigninLayoutComponent} from './signin-layout/signin-layout.component';
import {TopNavLayoutComponent} from './top-nav-layout/top-nav.component';
import {MaterialModule} from '../material/material.module';
import {BlankWhiteComponent} from './blank-layout/blank-white.component';
import {RouterModule} from '@angular/router';
import { ChangePasswordModal } from './modals/change-password.modal';
import { AppComponentModule } from '../shared/components/component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    NgProgressModule,
    RouterModule,
    AppComponentModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    MainComponent,
    BlankLayoutComponent,
    SigninLayoutComponent,
    SidenavComponent,
    TopNavLayoutComponent,
    SidenavItemComponent,
    BlankWhiteComponent
  ],
  declarations: [
    MainComponent,
    SidenavComponent,
    BlankLayoutComponent,
    SigninLayoutComponent,
    TopNavLayoutComponent,
    SidenavItemComponent,
    BlankWhiteComponent,
    ChangePasswordModal,
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppLayoutModule {}
