import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
/*
 * ----------------------------------------------------------------
 * async foo(): Promise<any> {
 *    console.log(await this.translate.getLabel('common.save'));
 * }
 * ----------------------------------------------------------------
 */

export class LabelHelper {
  static getLabel(translator: TranslateService, key: string): any {
    return new Promise((resolve, reject) => {
      translator.get(key).toPromise().then((res) => {
        resolve(res);
      }, () => {
        reject('-');
      });
    });
  }

  static requestAPIWithPromise(http: HttpClient, payload: any, url: string): any {
    return new Promise((resolve, reject) => {
      http.post<any>(url, payload, {}).toPromise().then((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    });
  }


  static getLabelWithParam(translator: TranslateService, key: string, values: any): any {
    return new Promise((resolve, reject) => {
      translator.get(key, values).toPromise().then((res) => {
        resolve(res);
      }, () => {
        reject('-');
      });
    });
  }
}
