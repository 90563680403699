import {Component, Injector, OnInit} from '@angular/core';
import {CustomerOrderDetailModel, CustomerOrderModel} from '../models/customer-order.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomerOrderService} from '../services/customer-order.service';
import {MatDialog} from '@angular/material/dialog';
import {BreadcrumbService} from '../../../../services/breadcrumb.service';
import {
  CRUD_MODE_CREATE,
  CRUD_MODE_EDIT,
  CRUD_MODE_VIEW, DIRECTORATES,
  IN_STOCK_LIST,
  STATUS_LIST,
  UNIT_LIST
} from '../../../../constants/common.constant';
import {DialogScrollComponent} from '../../../../shared/components/dialog/dialog-scroll.component';
import {BaseComponentDirective} from '../../../../shared/base/base.component';
import {ItemGroupService} from '../../../item/item-group/services/item-group.service';
import {CustomerModel} from '../../customer/models/customer.model';
import {CustomerService} from '../../customer/services/customer.service';
import {ItemService} from '../../../item/item/services/item.service';
import {ItemModel} from '../../../item/item/models/item.model';
import {AreaService} from '../../../master/area/services/area.service';
import {AreaSubService} from '../../../master/area-sub/services/area-sub.service';
import {ItemPicker} from '../../../item/item/modals/item.picker';
import {MarginService} from '../../../master/margin/services/margin.service';
import {MarginModel} from '../../../master/margin/models/margin.model';

@Component({
  selector: 'app-page-customer-order-receiving-form',
  templateUrl: './order-receiving-form.page.html',
})
export class OrderReceivingFormPageComponent extends BaseComponentDirective implements OnInit {

  customerOrder: CustomerOrderModel;
  form: FormGroup;
  mode = '';
  hideOnNew = true;
  img: any;

  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;

  disableButton = true;
  total = 0;
  totalAfterTax = 0;

  customers: CustomerModel[] = [];
  statuses = [];
  stockStatuses = IN_STOCK_LIST;
  directorates = DIRECTORATES;
  margins: MarginModel[] = [];

  protected readonly UNIT_LIST = UNIT_LIST;

  orderDetails: CustomerOrderDetailModel[] = [];
  items: ItemModel[] = [];

  generateInvoiceCounter = 0;
  tax = 11;
  margin: MarginModel;

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private customerOrderService: CustomerOrderService,
    private customerService: CustomerService,
    private itemGroupService: ItemGroupService,
    private itemService: ItemService,
    private areaService: AreaService,
    private subAreaService: AreaSubService,
    private marginService: MarginService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);

    this.form = this.formBuilder.group({
      customerId: [null, [Validators.required]],
      contactPerson: [null, []],
      phoneNumbers: [null, []],
      address: [null, []],
      areaName: [null, []],
      freeDeliveryMinOrder: [null, []],
      subAreaName: [null, []],
      invoiceNo: [null, [Validators.required]],
      orderDate: [null, [Validators.required]],
      receivedDate: [null, []],
      tax: [0, []],
      otherAddition: [0, []],
      otherSubtraction: [0, []],
      orderedBy: [null, []],
      status: ['draft', [Validators.required]],
      deliveryStatus: ['none', []],
      paidDate: [null, []],
      verifiedDate: [null, []],
      deliveryDate: [null, []],
      note: [null, []],
      directorate: [null, [Validators.required]],
      deliveryFee: [0, []],
      discount: [0, []]
    });
  }


  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.form.controls.status.disable({onlySelf: true});
    this.margins = await this.marginService.findAllNoPaging();
    this.customers = await this.customerService.findAllNoPaging();
    switch (this.activatedRoute.snapshot.params.action) {
      case 'add-new': {
        this.mode = CRUD_MODE_CREATE;
        this.statuses = STATUS_LIST;
        this.hideOnNew = false;
        break;

      }
      case 'edit': {
        this.mode = CRUD_MODE_EDIT;
        const id = this.activatedRoute.snapshot.params.id;
        this.customerOrder = await this.customerOrderService.findOne(id);

        this.fillFormAndDetails();
        this.statuses = STATUS_LIST;
        if (this.customerOrder.status !== 'draft') {
          const index = this.statuses.findIndex(sx => sx.key === 'draft');
          if (index > -1) {
            this.statuses.splice(index, 1);
          }
        } else {
          this.hideOnNew = false;
        }
        this.totalCount();
        this.orderDetails = this.customerOrder.details;
        this.disableButton = false;
        switch (this.customerOrder.status) {
          case 'sent':
          case 'paid':
          case 'pending':
          case 'received':
          case 'verified': {
            this.form.controls.customerId.disable();
            this.form.controls.orderDate.disable();
            break;
          }
        }
        break;
      }
      case 'view': {
        this.mode = CRUD_MODE_VIEW;
        const id = this.activatedRoute.snapshot.params.id;
        this.form.disable();
        this.disableButton = true;
        this.customerOrder = await this.customerOrderService.findOne(id);
        await this.fillFormAndDetails();
        this.statuses = STATUS_LIST;
        if (this.customerOrder.status !== 'draft') {
          const index = this.statuses.findIndex(sx => sx.key === 'draft');
          if (index > -1) {
            this.statuses.splice(index, 1);
          }
        }
        this.customerOrder.details.forEach(d => {
          this.total += d.subtotal;
        });
        this.items = await this.itemGroupService.findBySupplierId(this.form.controls.supplierId.value);
        this.orderDetails = this.customerOrder.details;

        break;
      }
      default:
        this.mode = CRUD_MODE_VIEW;
    }

    this.breadcrumbService.changeMessage([
      {label: 'pages.customer.customer', active: false},
      {label: 'pages.customer.customer-order', active: false, route: '/customer/order'},
      {label: 'pages.customer.customer-order-form', active: true, route: ''}
    ]);

    this.form.controls.status.valueChanges.subscribe(r => {
      if (this.customerOrder && this.customerOrder.status) {
        this.customerOrder.status = r;
      }
    });


    this.form.controls.customerId.valueChanges.subscribe(async r => {
      this.disableButton = (!r);
      if (r) {
        await this.onChangeCustomerId(r);
      }else {
        this.form.controls.address.setValue(null);
        this.form.controls.contactPerson.setValue(null);
        this.form.controls.phoneNumbers.setValue(null);
        this.form.controls.areaName.setValue(null);
        this.form.controls.subAreaName.setValue(null);
        this.form.controls.freeDeliveryMinOrder.setValue(null);
      }
      if (this.mode === CRUD_MODE_CREATE) {
        this.generateInvoiceNo(r).then();
      }
    });

    this.form.controls.orderDate.valueChanges.subscribe(async r => {
      if (this.mode === CRUD_MODE_CREATE) {
        this.generateInvoiceNo(r).then();
      }
    });
  }

  async onChangeCustomerId(id: number): Promise<void> {
    const cust = this.customers.find(c => c.id === id);
    this.margin = this.margins.find(mx => mx.areaId === cust.areaId);

    this.form.controls.address.setValue(cust.address);
    this.form.controls.contactPerson.setValue(cust.contactPerson);
    this.form.controls.phoneNumbers.setValue(cust.phoneNumbers);
    const area = await this.areaService.findOne(cust.areaId);
    const subArea = await this.subAreaService.findOne(cust.subAreaId);
    this.form.controls.areaName.setValue(area.areaName);
    this.form.controls.subAreaName.setValue(subArea.subAreaName);
    this.form.controls.freeDeliveryMinOrder.setValue(parseFloat(area.freeDeliveryMinOrder));
  }

  cleanupStatus(): void {
    const toDelete = ['PENDING PAYMENT', 'VERIFIED', 'PAID'];
    this.statuses.forEach(_ => {
      toDelete.forEach(d => {
        const index = this.statuses.findIndex(sx => sx.key === d);
        if (index > -1) {
          this.statuses = this.statuses.splice(index, 1);
        }
      });
    });
  }

  async generateInvoiceNo(r: any): Promise<void> {
    if (r && this.generateInvoiceCounter === 0 || this.generateInvoiceCounter === 1) {
      this.generateInvoiceCounter++;
    } else {
      if (!r) {
        this.generateInvoiceCounter--;
        this.form.controls.invoiceNo.setValue(null);
      }
    }

    if (this.generateInvoiceCounter === 2) {
      const invoiceNo = await this.customerOrderService.generateInvoice(
        this.form.controls.customerId.value,
        this.date.toDbDate(this.form.controls.orderDate.value));
      this.form.controls.invoiceNo.setValue(invoiceNo.value.toUpperCase());
      const customer = this.customers.find(c => c.id === this.form.controls.customerId.value);
      this.items = await this.itemService.findByAreaId(customer.areaId);
    }
  }

  fillCustomerInfo($event: any): void {
    //
  }

  async fillFormAndDetails(): Promise<void> {

    this.form.setValue({
      customerId: this.customerOrder.customerId,
      contactPerson: '',
      phoneNumbers: '',
      areaName: '',
      address: '',
      freeDeliveryMinOrder: '',
      subAreaName: '',
      orderedBy: '',
      invoiceNo: this.customerOrder.invoiceNo,
      orderDate: this.customerOrder.orderDate,
      receivedDate: this.customerOrder.receivedDate,
      tax: this.customerOrder.tax,
      discount: this.customerOrder.customerDiscount,
      otherAddition: this.customerOrder.otherAddition,
      otherSubtraction: this.customerOrder.otherSubtraction,
      status: this.customerOrder.status,
      deliveryStatus: this.customerOrder.deliveryStatus,
      paidDate: this.customerOrder.paidDate,
      verifiedDate: (this.customerOrder.verifiedDate) ? this.customerOrder.verifiedDate : null,
      deliveryDate: this.customerOrder.deliveryDate,
      note: this.customerOrder.note,
      deliveryFee: this.customerOrder.deliveryFee,
      directorate: this.customerOrder.customerDirectorateId
    });

    await this.onChangeCustomerId(this.customerOrder.customerId);
  }

  doBeforeSave(mode: string, date: Date): void {
    const payload = {
      id: (this.mode === CRUD_MODE_EDIT) ? this.customerOrder.id : null,
      customerId: this.form.controls.customerId.value,
      invoiceNo: this.form.controls.invoiceNo.value,
      orderDate: this.form.controls.orderDate.value,
      receivedDate: (['received', 'verified', 'pending', 'paid'].find(f => f === mode)) ? date : null,
      tax: this.form.controls.tax.value,
      customerDiscount: this.form.controls.discount.value,
      otherAddition: this.form.controls.otherAddition.value,
      otherSubtraction: this.form.controls.otherSubtraction.value,
      status: mode,
      deliveryStatus: this.form.controls.deliveryStatus.value,
      paidDate: (mode === 'paid') ? this.form.controls.paidDate.value : null,
      verifiedDate: (['verified', 'pending', 'paid'].find(f => f === mode)) ? date : null,
      deliveryDate: this.form.controls.deliveryDate.value,
      note: this.form.controls.note.value,
      customerDirectorateId: this.form.controls.directorate.value,
      freeDelivery: this.totalAfterTax > this.form.controls.freeDeliveryMinOrder.value ? 1 : 0,
      taxPercentage: this.tax,
      grandTotal: this.total,
      grandTotalTaxed: this.totalAfterTax,
      details: []
    };
    const c = this.customers.find(cx => cx.id === payload.customerId);
    const m = this.margins.find(mx => mx.areaId === c.areaId);

    this.orderDetails.forEach(od => {
      od.price = this.ui.moneyFormatToNumber(od.price);
      od.margin = m.margin;
      od.quantityFinal = parseFloat(od.quantityFinal);
      od.quantity = parseFloat(od.quantity);
      od.standardPrice = parseFloat(od.standardPrice);
      payload.details.push(od);
    });

    const isCreateMode = this.mode === CRUD_MODE_CREATE;

    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: (isCreateMode) ? `#${payload.invoiceNo}` : `#${payload.id} - ${payload.invoiceNo}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-primary',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.save')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        if (isCreateMode) {
          this.customerOrderService.save(payload).then((r) => {
            this.doAfterSave(r.id);
          });
        } else {
          this.customerOrderService.update(payload).then((_) => {
            this.doAfterSave(payload.id);
          });
        }
      }
    });
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }

    this.doBeforeSave('draft', this.date.nowToDbDate());

  }

  doAfterSave(id: number): void {
    if (this.fd) {
      this.uploadFile(id).then();
    }

    this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
      verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
    });

    this.router.navigate(['/customer/order']).then();
  }

  create(): void {
    const cust = this.customers.find(c => c.id === this.form.controls.customerId.value);
    const dialog = this.dialog.open(ItemPicker, {
      data: {
        areaId: cust.areaId
      }
    });

    dialog.componentInstance.result.subscribe(r => {
      console.log(r);
      if (r.sku) {
        const x = this.orderDetails.find(od => od.sku === r.sku);
        if (!x) {
          this.orderDetails.push({
            itemGroupId: r.itemGroupCode,
            sku: r.sku,
            itemName: r.itemGroupName,
            quantity: (r.quantity) ? r.quantity : 1,
            subtotal: r.subtotal,
            itemId: r.id,
            margin: this.margin.margin,
            standardPrice: r.standardPrice,
            price: r.sellPrice,
            unit: r.unit
          });
        }
        this.totalCount();
      }
    });
  }

  totalCount(): void {
    this.total = 0;
    const deliveryFee = this.ui.moneyFormatToNumber(this.form.controls.deliveryFee.value);
    this.orderDetails.forEach(d => {
      this.total += d.subtotal;
    });
    this.total = this.total - this.ui.moneyFormatToNumber(this.form.controls.discount.value) + deliveryFee;
    this.totalAfterTax = this.total + this.total * (this.tax / 100);
    this.form.controls.tax.setValue(this.total * (this.tax / 100));
  }

  getQuantity(d: any, index: number, qty: any): void {
    if (!this.orderDetails[index].price) {
      this.orderDetails[index].price = 0;
    }
    this.orderDetails[index].quantity = qty;
    this.orderDetails[index].subtotal = this.ui.moneyFormatToNumber(qty) * this.ui.moneyFormatToNumber(this.orderDetails[index].price);
    this.totalCount();
    if (this.total > this.form.controls.freeDeliveryMinOrder.value) {
      this.form.controls.deliveryFee.disable({onlySelf: true});
      this.form.controls.deliveryFee.setValue(0);
    }else {
      this.form.controls.deliveryFee.enable({onlySelf: true});
    }
  }

  delete(d: CustomerOrderDetailModel): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.itemName}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-danger',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.delete')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        const index = this.orderDetails.findIndex(od => od.itemGroupId === d.itemGroupId);
        this.orderDetails.splice(index, 1);
        this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
          verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
        });
      }
    });
  }

  async uploadFile(id: number): Promise<any> {
    return await this.customerOrderService.uploadFile(this.fd, id);
  }

  paid(): void {
    this.doBeforeSave('paid', new Date());
  }

  verified(): void {
    this.doBeforeSave('verified', new Date());
  }

  sent(): void {
    this.doBeforeSave('sent', new Date());
  }

  pendingPayment(): void {
    this.doBeforeSave('pending', new Date());
  }

  downloadEvidence(): void {
    this.customerOrderService.downloadEvidenceFile(this.customerOrder.id).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.customerOrder.invoiceNo + '.jpg';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching image:', error);
    });
  }

  downloadInvoice(): void {
    this.customerOrderService.downloadFile(this.customerOrder.id).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'downloaded-file.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }

  cancel(): void {
    this.router.navigate(['/supplier/order']).then();
  }
}
