<div class="main-content homepage grey-background">
    <div class="container-fluid">
        <div class="mat-card m- white-background text-black-50 rounded-lg">
            <div class="mat-card-title px-3 py-3">
                <h3 class="pl-2 text-capitalize">{{mode}} {{'pages.customer.customer-order' | translate}}</h3>
            </div>

            <div class="mat-card-content px-3 pb-5">
                <form [formGroup]="form" >

                  <div class="row mb-3">
                    <div class="col-3">
                      <app-datepicker
                        formControlName="orderDate"
                        [label]="'pages.customer.order-date' | translate"></app-datepicker>
                    </div>
                    <div class="col-3">
                      <app-datepicker
                        formControlName="deliveryDate"
                        [label]="'pages.customer.delivery-date' | translate"></app-datepicker>
                    </div>
                    <div class="col-3">
                      <app-text-field
                        formControlName="invoiceNo"
                        [readonly]="true"
                        [label]="'pages.customer.invoice-no' | translate"></app-text-field>
                    </div>
                  </div>

                  <mat-accordion class="example-headers-align" multi >
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{'pages.customer.customer-info' | translate}}
                        </mat-panel-title>

                      </mat-expansion-panel-header>

                      <div class="row mt-3">
                        <div class="col-3">
                          <app-text-field
                            [readonly]="readonly"
                            [label]="'pages.customer.customer-name' | translate"
                            formControlName="customerName">
                            <div class="input-group-append" (click)="openCustomerPicker()" *ngIf="!customerOrder || mode == 'edit'">
                                <span class="input-group-text" id="basic-addon2">
                                    <i class="icon-search"></i>
                                </span>
                            </div>
                          </app-text-field>
                        </div>
                        <div class="col-3">
                          <app-text-field
                            formControlName="address"
                            [readonly]="true"
                            [label]="'pages.customer.address' | translate"></app-text-field>
                        </div>
                        <div class="col-3">
                          <app-text-field
                            formControlName="contactPerson"
                            [readonly]="true"
                            [label]="'pages.customer.contact-person' | translate"></app-text-field>
                        </div>
                        <div class="col-3">
                          <app-text-field
                            formControlName="phoneNumbers"
                            [readonly]="true"
                            [label]="'pages.customer.phone-number' | translate"></app-text-field>
                        </div>

                      </div>
                      <div class="row mt-3">
                        <div class="col-3">
                          <app-text-field
                            [readonly]="readonly"
                            [label]="'pages.customer.directorate' | translate"
                            formControlName="directorateKey">
                            <div class="input-group-append" (click)="openDirectoratePicker()" *ngIf="!customerOrder  || mode == 'edit'">
                                <span class="input-group-text" id="basic-addon3">
                                    <i class="icon-search"></i>
                                </span>
                            </div>
                          </app-text-field>
                        </div>
                        <div class="col-3">
                          <app-text-field
                            formControlName="areaName"
                            [readonly]="true"
                            [label]="'pages.customer.area-name' | translate"></app-text-field>
                        </div>
                        <div class="col-3">
                          <app-text-field
                            formControlName="subAreaName"
                            [readonly]="true"
                            [label]="'pages.customer.sub-area-name' | translate"></app-text-field>
                        </div>
                        <div class="col-3">
                          <app-number-field
                            formControlName="freeDeliveryMinOrder"
                            [readonly]="true"
                            [label]="'pages.area.free-delivery-min-order' | translate"></app-number-field>
                        </div>
                      </div>

                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{'pages.customer.order-info' | translate}}
                        </mat-panel-title>

                      </mat-expansion-panel-header>

                      <div class="row mt-3">
                        <div class="col-3">
                          <app-select [readonly]="readonly"
                                      [items]="statuses"
                                      [bindLabel]="'key'"
                                      [bindValue]="'value'"
                                      [label]="'pages.customer.status' | translate" formControlName="status"></app-select>
                        </div>
                        <div class="col-3">
                          <app-select
                            [items]="stockStatuses"
                            [bindLabel]="'key'"
                            [bindValue]="'value'"
                            [label]="'pages.customer.delivery-status' | translate" formControlName="deliveryStatus"></app-select>
                        </div>
                        <div class="col-3">
                          <app-text-field
                            formControlName="driverName"
                            [label]="'pages.customer.driver-name' | translate"></app-text-field>
                        </div>
                        <div class="col-3">
                          <app-text-field
                            formControlName="receivedBy"
                            [label]="'pages.customer.received-by' | translate"></app-text-field>
                        </div>

                      </div>
                      <div class="row mt-3">
                        <div class="col-3">
                          <app-datepicker
                            formControlName="receivedDate"
                            [label]="'pages.customer.received-date' | translate"></app-datepicker>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-3">
                          <app-datepicker
                            formControlName="paidDate"
                            [label]="'pages.customer.paid-date' | translate"></app-datepicker>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3 pt-3">
                          <app-area-field
                            formControlName="note"
                            [rows]="5"
                            [label]="'pages.customer.note' | translate"></app-area-field>
                        </div>
                        <div class="col-4 pt-3" *ngIf="img">
                          <div class="dropzone" >
                            <div class="img-preview">
                              <img [src]="img" style="height: 250px;" alt="evidence">
                            </div>
                          </div>
                          <div class="mt-2">
                            <button
                              (click)="downloadEvidence()"
                              type="button"
                              class="btn btn-primary">{{'common.download' | translate}}</button>
                          </div>
                        </div>

                      </div>

                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{'pages.customer.order-details' | translate}}
                        </mat-panel-title>

                      </mat-expansion-panel-header>

                      <div class="row">
                        <div class="col-4">

                        </div>
                        <div class="col-8 pt-3 text-end">
                          <button
                            type="button"
                            [disabled]="disableButton || (customerOrder && customerOrder.status !== 'draft')"
                            (click)="create()"
                            mat-flat-button
                            color="primary"
                            class="text-white">
                            {{'common.create' | translate}}
                          </button>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="table-responsive">
                            <table class="table table-striped table-hover">
                              <thead>
                              <tr>
                                <th class="text-nowrap">{{'pages.customer.sku' | translate}}</th>
                                <th class="text-nowrap">{{'pages.customer.item-name' | translate}}</th>
                                <th class="text-nowrap">{{'pages.customer.unit' | translate}}</th>
                                <th class="text-nowrap text-end">{{'pages.customer.standard-price' | translate}}</th>
                                <th class="text-nowrap text-end">{{'pages.customer.margin' | translate}}</th>
                                <th class="text-nowrap text-end">{{'pages.customer.sell-price' | translate}}</th>
                                <th class="text-nowrap text-end">{{'pages.customer.current-stock' | translate}}</th>
                                <th class="text-nowrap text-end">{{'pages.customer.quantity' | translate}}</th>
                                <th class="text-nowrap text-end">{{'pages.customer.quantity-final' | translate}}</th>
                                <th class="text-nowrap text-end">{{'pages.supplier.subtotal' | translate}}</th>
                                <th class="text-nowrap">{{'pages.customer.checking' | translate}}</th>
                                <th class="text-nowrap">{{'pages.customer.remark-per-item' | translate}}</th>
                                <th></th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let d of orderDetails; let i = index">
                                <td class="text-nowrap">{{d.sku}}</td>
                                <td class="text-nowrap">{{d.itemName}}</td>
                                <td class="text-nowrap">{{d.unit}}</td>
                                <td class="text-nowrap text-end">{{d.standardPrice | number: '1.2-2'}}</td>
                                <td class="text-nowrap text-end">{{d.margin | number: '1.2-2'}}%</td>
                                <td class="text-nowrap text-end">{{d.price | number: '1.2-2'}}</td>
                                <td class="text-nowrap text-end">{{d.currentStock | number: '1.2-2'}}</td>
                                <td class="text-nowrap text-end">
                                  <app-number-field
                                    [disabled]="disableButton"
                                    [(ngModel)]="d.quantity" [ngModelOptions]="{standalone: true}"
                                    (valueChange)="getQuantity(d, i, $event)"
                                  ></app-number-field>
                                </td>
                                <td class="text-nowrap text-end">
                                  <app-number-field
                                    [disabled]="disableButton"
                                    [(ngModel)]="d.quantityFinal" [ngModelOptions]="{standalone: true}"
                                    (valueChange)="getQuantityFinal(d, i, $event)"
                                  ></app-number-field>
                                </td>
                                <td class="text-nowrap text-end">
                                  {{d.subtotal | number: '1.2-2'}}
                                </td>
                                <td>
                                  <mat-checkbox (change)="checkQtyAndPrice($event, d)"
                                                [(ngModel)]="d.checked" [disabled]="disableButton"
                                                [ngModelOptions]="{standalone: true}"
                                  ></mat-checkbox>
                                </td>
                                <td>
                                  <app-text-field
                                    [(ngModel)]="d.remarkPerItem"
                                    [disabled]="disableButton"
                                    [ngModelOptions]="{standalone: true}"
                                    (valueChange)="addRemark(d, i, $event)"
                                  >

                                  </app-text-field>
                                </td>
                                <td>
                                  <div class="btn-group" role="group" aria-label="Button Action">
                                    <button
                                      [disabled]="disableButton || (customerOrder && customerOrder.status !== 'draft')"
                                      (click)="delete(d)"
                                      type="button"
                                      mat-stroked-button
                                      color="warn">{{'common.delete' | translate}}</button>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                              <tfoot>
                              <tr>
                                <td [colSpan]="9" class="text-end"><b>Free Delivery Fee</b></td>
                                <td>
                                  <b *ngIf="totalAfterTax > form.controls.freeDeliveryMinOrder.value">{{'common.yes' | translate}}</b>
                                  <b *ngIf="totalAfterTax <= form.controls.freeDeliveryMinOrder.value">{{'common.no' | translate}}</b>
                                </td>
                              </tr>
                              <tr>
                                <td [colSpan]="9" class="text-end"><b>{{'pages.customer.delivery-fee' | translate}}</b></td>
                                <td class="text-end">
                                  <app-number-field
                                    formControlName="deliveryFee" ></app-number-field>
                                </td>
                              </tr>
                              <tr>
                                <td [colSpan]="9"  class="text-end"><b>{{'pages.customer.discount' | translate}}</b></td>
                                <td class="text-end">
                                  <app-number-field
                                    formControlName="discount"
                                    (valueChange)="totalCount(1)"></app-number-field>
                                </td>
                              </tr>
                              <tr>
                                <td [colSpan]="9"  class="text-end"><b>{{'pages.customer.tax' | translate}}</b></td>
                                <td class="text-end">
                                  <app-number-field
                                    [readonly]="true"
                                    formControlName="tax" ></app-number-field>
                                </td>
                                <td>{{tax}}%</td>
                              </tr>
                              <tr>
                                <td [colSpan]="9"  class="text-end"><b>{{'pages.customer.grand-total' | translate}} {{'pages.customer.before-tax' | translate}}</b></td>
                                <td class="text-end"><b>{{total | number: '1.2-2'}}</b></td>
                              </tr>
                              <tr>
                                <td [colSpan]="9"  class="text-end"><b>{{'pages.customer.grand-total' | translate}} {{'pages.customer.after-tax' | translate}}</b></td>
                                <td class="text-end"><b>{{totalAfterTax | number: '1.2-2'}}</b></td>
                              </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </div>

                    </mat-expansion-panel>
                  </mat-accordion>

                    <div class="row mt-3" *ngIf="mode !== 'view'">
                        <div class="col-12 text-end justify-content-end">
                            <button type="button"
                                    mat-stroked-button
                                    class="bg-info text-white me-2"
                                    (click)="downloadInvoice()"
                            >{{'common.print' | translate}}</button>
                            <button
                                    *ngIf="(form.controls.status.value === 'draft')" [disabled]="disableButton || form.invalid"
                                    mat-flat-button
                                    color="primary"
                                    type="button"
                                    (click)="save()"
                                    class="text-white me-2">{{'common.draft' | translate}}</button>
                            <button
                                    *ngIf="(form.controls.status.value === 'draft' || form.controls.status.value === 'proceed')"
                                    [disabled]="disableButton || form.invalid"
                                    mat-flat-button
                                    color="primary"
                                    type="button"
                                    (click)="proceed()"
                                    class="text-white me-2">{{'common.proceed' | translate}}</button>

                          <button
                            type="button"
                            (click)="cancel()"
                            mat-stroked-button
                            color="warn"
                            class="">{{'common.cancel' | translate}}</button>


                        </div>
                    </div>
                    <div class="row mt-3" *ngIf="mode == 'view'">
                      <div class="col-12 text-end justify-content-end">
                        <button type="button"
                                mat-stroked-button
                                class="bg-info text-white me-2"
                                (click)="downloadInvoice()"
                        >{{'common.print' | translate}}</button>
                        <button
                          type="button"
                          (click)="cancel()"
                          mat-stroked-button
                          color="warn"
                          class="">{{'common.cancel' | translate}}</button>
                      </div>
                    </div>
                </form>

            </div>

        </div>
    </div>
</div>
