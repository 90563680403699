import {JwtHelperService} from '@auth0/angular-jwt';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class Auth {
  constructor(public jwtHelper: JwtHelperService) {}
  loggedIn(): boolean {
    return !this.jwtHelper.isTokenExpired();
  }
}

