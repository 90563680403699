export const BACKOFFICE = '/backoffice';
export const AUTH_BASE_URL = '/auth/login';
export const AUTH_PROFILE_BASE_URL = '/auth/current-user';
export const AREA_BASE_URL = '/area';
export const AREA_SUB_BASE_URL = '/area-sub';
export const IMAGE_BASE_URL = '/image';
export const STORAGE_BASE_URL = '/storage';
export const PRODUCT_BASE_URL = '/product';
export const PRODUCT_SUB_BASE_URL = '/product-sub';
export const MARGIN_BASE_URL = '/margin';
export const ITEM_GROUP_BASE_URL = '/item-group';
export const ITEM_BASE_URL = '/item';
export const SUPPLIER_BASE_URL = '/supplier';
export const SUPPLIER_ORDER_BASE_URL = '/supplier-order';
export const SUPPLIER_ORDER_RECEIVING_BASE_URL = '/supplier-order-receiving';
export const CUSTOMER_BASE_URL = '/customer';
export const CUSTOMER_ORDER_BASE_URL = '/customer-order';
export const SUPPLIER_ITEM_BASE_URL = '/supplier-item';
export const DISTRIBUTION_BASE_URL = '/distribution';
export const STOCK_BASE_URL = '/storage-item-stock';
export const STOCK_AUDIT_BASE_URL = '/stock-audit';
export const STOCK_AUDIT_DETAIL_BASE_URL = '/stock-audit-detail';
export const PARAMETER_BASE_URL = '/parameter';
export const PARAMETER_DETAIL_BASE_URL = '/parameter-detail';
export const USER_CUSTOMER_BASE_URL = '/user-customer';


