import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { BACKOFFICE, PARAMETER_DETAIL_BASE_URL } from '../../../../constants/api.constant';
import { PageInterface, PageQueryInterface } from '../../../../interfaces/page.interface';
import { ParameterDetailModel } from '../models/parameter-detail.model';

@Injectable({
  providedIn: 'root',
})
export class ParameterDetailService {

  constructor(private http: HttpClient) {
  }

  get baseUrl(): string {
    return environment.url + BACKOFFICE + PARAMETER_DETAIL_BASE_URL;
  }

  async findOne(id: number): Promise<ParameterDetailModel> {
    return await this.http.get<ParameterDetailModel>(this.baseUrl + '/' + id).toPromise();
  }

  async findAll(parameterId: number, p: PageQueryInterface): Promise<PageInterface<ParameterDetailModel>> {
    return await this.http.post<PageInterface<ParameterDetailModel>>(this.baseUrl + `/parameter/${parameterId}/list`,
      p).toPromise();
  }

  async store(parameterId: number, data: ParameterDetailModel): Promise<ParameterDetailModel> {
    return await this.http.post<ParameterDetailModel>(this.baseUrl + `/parameter/${parameterId}`,
      data).toPromise();
  }

  async update(parameterId: number, id: number, data: ParameterDetailModel): Promise<ParameterDetailModel> {
    return await this.http.patch<ParameterDetailModel>(this.baseUrl + `/parameter/${parameterId}/detail/${id}`,
      data).toPromise();
  }

  async delete(parameterId: number, id: number): Promise<ParameterDetailModel> {
    return await this.http.delete<ParameterDetailModel>(this.baseUrl + `/parameter/${parameterId}/detail/${id}`,
      {}).toPromise();
  }
}
