import {Component, OnInit, Injector, Inject, EventEmitter} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_EDIT, CRUD_MODE_VIEW, CRUD_MODE_CREATE } from 'src/app/constants/common.constant';
import { BaseComponentDirective } from 'src/app/shared/base/base.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { AreaSubModel } from '../../area-sub/models/area-sub.model';
import { AreaModel } from '../../area/models/area.model';
import { AreaService } from '../../area/services/area.service';
import { StorageModel } from '../models/storage.model';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-modal-storage',
  templateUrl: './storage.modal.html',
})
export class StorageModal extends BaseComponentDirective implements OnInit{
  public result = new EventEmitter();
  public form: FormGroup;
  public mode = '';
  public storage: StorageModel;
  public viewMode = false;

  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;

  areas: AreaModel[] = [];
  subAreas: AreaSubModel[] = [];

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<StorageModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private areaService: AreaService,
    private dialog: MatDialog
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      storageName: [null, [Validators.required]],
      address: [null, [Validators.required]],
      description: [null, [Validators.required]],
    });
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.storage = this.data.storage as StorageModel;
    this.mode = this.data.mode;

    this.areas = await this.areaService.findAllNoPaging();
    switch (this.mode) {
      case CRUD_MODE_EDIT: {
        this.fileUrl = this.storage.imageName;
        this.form.setValue({
          storageName: this.storage.storageName,
          address: this.storage.address,
          description: this.storage.description,
        });
        this.areas.forEach(area => {
          const i = this.storage.areaIds.findIndex(ai => ai === area.id);
          if (i > -1) {
            area.checked = true;
          }
        });

        break;
      }
      case CRUD_MODE_VIEW: {
        this.readonly = true;
        this.fileUrl = this.storage.imageName;
        this.form.setValue({
          storageName: this.storage.storageName,
          address: this.storage.address,
          description: this.storage.description,
        });

        this.areas.forEach(area => {
          const i = this.storage.areaIds.findIndex(ai => ai === area.id);
          if (i > -1) {
            area.checked = true;
          }
        });

        this.viewMode = true;
        break;
      }
    }
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }
    const payload = {
      id: (this.mode === CRUD_MODE_EDIT) ? this.storage.id : null,
      storageName: this.form.controls.storageName.value,
      address: this.form.controls.address.value,
      description: this.form.controls.description.value,
      areaIds: [],
    };

    let atLeastOneArea = 0;
    this.areas.forEach(area => {
      if (area.checked) {
        atLeastOneArea++;
        payload.areaIds.push(area.id);
      }
    });

    if (atLeastOneArea === 0) {
      this.snackBar.open('At least one area should be checked', null, {duration: 5000});
      return;
    }

    const isCreateMode = this.mode === CRUD_MODE_CREATE;

    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: (isCreateMode) ? `#${payload.storageName}` : `#${payload.id} - ${payload.storageName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.save')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        if (isCreateMode) {
          this.storageService.save(payload).then((r) => {
            this.doAfterSave(r.id);
          });
        } else {
          this.storageService.update(payload).then((_) => {
            this.doAfterSave(payload.id);
          });
        }
      }
    });
  }

  doAfterSave(id: number): void {
    if (this.fd) {
      this.uploadFile(id);
    }

    this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
      verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
    });
    this.result.emit(1);
    this.dialogRef.close();
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  async uploadFile(id: number): Promise<any> {
    return await this.storageService.uploadFile(this.fd, id);
  }
}
