import {Component, Injector, OnInit} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {MatDialog} from '@angular/material/dialog';
import {BreadcrumbService} from '../../../../services/breadcrumb.service';
import {DialogScrollComponent} from '../../../../shared/components/dialog/dialog-scroll.component';
import {CustomerOrderModel} from '../models/customer-order.model';
import {CustomerOrderService} from '../services/customer-order.service';
import {CustomerModel} from '../../customer/models/customer.model';
import {CustomerService} from '../../customer/services/customer.service';

@Component({
  selector: 'app-page-customer-receiving-order',
  templateUrl: './customer-order-receiving.page.html',
})
export class CustomerOrderReceivingPageComponent extends BasePageComponentDirective<CustomerOrderModel> implements OnInit{

  customers: CustomerModel[] = [];
  constructor(
    injector: Injector,
    private customerOrderService: CustomerOrderService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.query();

    this.customers = await this.customerService.findAllNoPaging();

    this.breadcrumbService.changeMessage([
      {label: 'pages.customer.customer', active: false},
      {label: 'pages.customer.customer-receiving-order', active: true, route: ''}
    ]);
  }

  query(): void {
    this.customerOrderService.findAll(this.pageQuery).then(async (res) => {
      this.page.emit(res);
    });
  }

  edit(d: CustomerOrderModel): void {
    this.router.navigate(['/customer/order-receiving/edit/' + d.id]).then();
  }

  view(d: CustomerOrderModel): void {
    this.router.navigate(['/customer/order-receiving/view/' + d.id]).then();
  }

  delete(d: CustomerOrderModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.invoiceNo}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-danger', color: 'primary', focus: true, label: this.translateService.instant('common.delete')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.customerOrderService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }


}
