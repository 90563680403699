<div class="form-group my-0">
    <label *ngIf="label">{{label}}<span *ngIf="checkRequiredValidator() == 'required'"> *</span></label>
    <input [readOnly]="readonly"
           class="form-control text-end my-0"
           type="text"
           [formControl]="ngControl.control"
           [class]="ngControl.control.dirty && ngControl.control.errors? 'is-invalid': ''"
           mask="separator.4"
           separatorLimit="100000000000000"
           [dropSpecialCharacters]="false"
           decimalMarker="{{decimalSeparator}}"
           thousandSeparator="{{thousandSeparator}}"
             />
    <div class="invalid-feedback" *ngIf="ngControl.control.dirty && ngControl.control.errors">{{'error.input-required' | translate}}</div>
  </div>
