import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgProgressModule } from 'ngx-progressbar';
import { MaterialModule } from 'src/app/material/material.module';
import { AppComponentModule } from 'src/app/shared/components/component.module';
import { AppSharedModule } from 'src/app/shared/shared.module';
import { ItemPageComponent } from './item/pages/item.page';
import { ItemRoutesModule } from './item-routes.module';
import { ItemGroupModal } from './item-group/modals/item-group.modal';
import { ItemGroupPageComponent } from './item-group/pages/item-group.page';
import { ItemModal } from './item/modals/item.modal';
import {ItemGroupPicker} from './item-group/modals/item-group.picker';
import {ItemSupplierItemPageComponent} from './item/pages/item-supplier-item.page';
import {ItemPicker} from './item/modals/item.picker';
import {ItemGroupReportComponent} from './item-group/pages/item-group.report';

@NgModule({
    imports: [
      CommonModule,
      MaterialModule,
      TranslateModule,
      FormsModule,
      ReactiveFormsModule,
      NgProgressModule,
      AppSharedModule,
      ItemRoutesModule,
      NgSelectModule,
      AppComponentModule
    ],
    exports: [
      ItemPageComponent,
      ItemGroupModal,
      ItemModal,
      ItemGroupPageComponent,
      ItemGroupPicker,
      ItemPicker,
      ItemSupplierItemPageComponent,
      ItemGroupReportComponent
    ],
    declarations: [
      ItemPageComponent,
      ItemGroupModal,
      ItemModal,
      ItemGroupPageComponent,
      ItemGroupPicker,
      ItemPicker,
      ItemSupplierItemPageComponent,
      ItemGroupReportComponent,
    ],
    providers: [

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
  })
  export class AppItemModule {}
