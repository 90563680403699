import {Component, EventEmitter, Inject, Injector, OnInit} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DIRECTORATES} from '../../../../constants/common.constant';

@Component({
  selector: 'app-picker-directorate',
  templateUrl: './directorate.picker.html',
})
export class DirectoratePicker extends BasePageComponentDirective<any> implements OnInit{
  directorates: any[] = [];
  public result = new EventEmitter();
  selectedDirectorate: any;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.query();
  }

  query(): void {
    this.directorates = DIRECTORATES;
    this.pageQuery.pageSize = 5;
  }

  choose(): void {
    this.result.emit(this.selectedDirectorate);
    this.dialogRef.close();
  }
}
