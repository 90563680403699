import {Component, EventEmitter, Inject, Injector, OnInit} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CustomerModel} from '../models/customer.model';
import {CustomerService} from '../services/customer.service';
import { AreaModel } from '../../../master/area/models/area.model';
import { AreaSubModel } from '../../../master/area-sub/models/area-sub.model';
import { AreaService } from '../../../master/area/services/area.service';
import { AreaSubService } from '../../../master/area-sub/services/area-sub.service';

@Component({
  selector: 'app-picker-customer',
  templateUrl: './customer.picker.html',
})
export class CustomerPicker extends BasePageComponentDirective<CustomerModel> implements OnInit{
  customers: CustomerModel[] = [];
  public result = new EventEmitter();
  selectedCustomer: CustomerModel;
  areas: AreaModel[] = [];
  subAreas: AreaSubModel[] = [];
  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<CustomerPicker>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,
    private areaService: AreaService,
    private areaSubService: AreaSubService,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.areas = await this.areaService.findAllNoPaging();
    this.subAreas = await this.areaSubService.findAllNoPaging(0);
    this.query();
  }

  query(): void {
    this.pageQuery.pageSize = 5;
    this.customerService.findAll(this.pageQuery).then((res) => {
      this.page.emit(res);
    });
  }

  choose(): void {
    this.result.emit(this.selectedCustomer);
    this.dialogRef.close();
  }
}
