import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_CREATE, CRUD_MODE_EDIT, CRUD_MODE_VIEW, UNIT_LIST } from 'src/app/constants/common.constant';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BasePageComponentDirective } from 'src/app/shared/base/base-page.component';
import { ItemModal } from '../modals/item.modal';
import { ItemModel } from '../models/item.model';
import { ItemService } from '../services/item.service';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ItemGroupService } from '../../item-group/services/item-group.service';
import { ItemGroupModel } from '../../item-group/models/item-group.model';
import { ItemGroupModal } from '../../item-group/modals/item-group.modal';
import { ProductSubModel } from 'src/app/pages/master/product-sub/models/product-sub.model';
import { ProductModel } from 'src/app/pages/master/product/models/product.model';
import { ProductService } from 'src/app/pages/master/product/services/product.service';
import { ProductSubService } from 'src/app/pages/master/product-sub/services/product-sub.service';
import { AreaService } from 'src/app/pages/master/area/services/area.service';
import { MarginService } from 'src/app/pages/master/margin/services/margin.service';
import { MarginModel } from 'src/app/pages/master/margin/models/margin.model';
import { AreaModel } from 'src/app/pages/master/area/models/area.model';
import { StorageModel } from 'src/app/pages/master/storage/models/storage.model';
import { StorageService } from 'src/app/pages/master/storage/services/storage.service';
import { SupplierModel } from '../../../supplier/supplier/models/supplier.model';
import { SupplierService } from '../../../supplier/supplier/services/supplier.service';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-page-item',
  templateUrl: './item.page.html',
})
export class ItemPageComponent extends BasePageComponentDirective<ItemModel> implements OnInit, AfterViewInit {
  itemGroupId: number;
  form: FormGroup;
  itemGroup: ItemGroupModel;
  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;
  public readonly = true;

  products: ProductModel[] = [];
  subProducts: ProductSubModel[] = [];
  margins: MarginModel[] = [];
  areas: AreaModel[] = [];
  storages: StorageModel[] = [];
  supplier: SupplierModel;
  units = UNIT_LIST;
  itemImageBaseURL = environment.storage + '/images/item-group/';
  priorities = [
    {
      value: 1,
      label: 'High 1',
    },
    {
      value: 2,
      label: 'High 2',
    },
    {
      value: 3,
      label: 'Normal',
    },
    {
      value: 4,
      label: 'Low 1',
    },
    {
      value: 5,
      label: 'Low 2',
    },
  ];

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private itemService: ItemService,
    private itemGroupService: ItemGroupService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private productService: ProductService,
    private subProductService: ProductSubService,
    private areaService: AreaService,
    private marginService: MarginService,
    private storageService: StorageService,
    private supplierService: SupplierService,
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      itemGroupCode: [null, [Validators.required]],
      itemGroupName: [null, [Validators.required]],
      productId: [null, [Validators.required]],
      subProductId: [null, [Validators.required]],
      supplierName: [null],
      priceChangeDate: [null],
      unit: [null],
      buyPrice: [null],
      standardPrice: [null],
      priority: [null],
    });
  }

  async ngAfterViewInit(): Promise<any> {
    setTimeout(_ => {
      this.accordion.openAll();
    }, 2000);
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
    this.itemGroupId = this.activatedRoute.snapshot.params.id;
    if (!this.itemGroupId) {
      this.router.navigate(['master/item-group']).then();
    }

    this.form.controls.productId.valueChanges.subscribe(async r => {
      this.form.controls.subProductId.setValue(null);
      this.subProducts = await this.subProductService.findAllNoPaging(r);

    });

    this.areas = await this.areaService.findAllNoPaging();
    this.storages = await this.storageService.findAllNoPaging();
    this.products = await this.productService.findAllNoPaging();
    this.margins = await this.marginService.findAllNoPaging();

    this.setItemGroupForm().then();

    this.query();

    this.breadcrumbService.changeMessage([
      { label: 'menu.master.index', active: false },
      { label: 'pages.item-group.item-group', active: false, route: '/item-group/item-group' },
      { label: 'pages.item.item', active: true, route: '' },
    ]);
  }

  async setItemGroupForm(): Promise<any> {
    this.itemGroup = await this.itemGroupService.findOne(this.itemGroupId);
    if (this.itemGroup.supplierId) {
      this.supplier = await this.supplierService.findOne(this.itemGroup.supplierId);
    }

    this.form.patchValue({
      itemGroupCode: this.itemGroup.itemGroupCode,
      itemGroupName: this.itemGroup.itemGroupName,
      productId: parseInt(this.itemGroup.productId.toString(), 10),
      subProductId: parseInt(this.itemGroup.subProductId.toString(), 10),
      supplierName: (this.supplier) ? this.supplier.supplierName : null,
      priceChangeDate: this.itemGroup.priceChangeDate,
      unit: this.itemGroup.unit,
      standardPrice: this.itemGroup.standardPrice,
      buyPrice: this.itemGroup.buyPrice,
      priority: this.itemGroup.priority,
    });
    this.fileUrl = environment.storage + '/images/item-group/' + this.itemGroup.imageName;
    this.fileUrl += '?rand=' + Math.random();
  }

  query(): void {
    this.itemService.findAll(this.pageQuery, this.itemGroupId).then((res) => {
      this.page.emit(res);
    });
  }

  save(): void {
    this.itemGroupService.findOne(this.itemGroup.id).then(itemGroup => {
      itemGroup.imageName = environment.storage + '/images/item-group/' + itemGroup.imageName;
      const dialog = this.dialog.open(ItemGroupModal, {
        data: {
          itemGroup,
          mode: CRUD_MODE_EDIT,
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.router.navigate(['/item-group/item-group']).then();
        }
      });
    });
  }

  create(): void {
    const dialog = this.dialog.open(ItemModal, {
      data: {
        itemGroupId: this.itemGroupId,
        itemGroupName: this.itemGroup.itemGroupName,
        itemGroupCode: this.itemGroup.itemGroupCode,
        itemGroup: this.itemGroup,
        mode: CRUD_MODE_CREATE,
      },
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res === 1) {
        this.query();
      }
    });
  }

  edit(d: ItemModel): void {
    const dialog = this.dialog.open(ItemModal, {
      data: {
        itemGroupId: this.itemGroupId,
        itemGroupName: this.itemGroup.itemGroupName,
        itemGroupCode: this.itemGroup.itemGroupCode,
        itemGroup: this.itemGroup,
        item: d,
        mode: CRUD_MODE_EDIT,
      },
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res === 1) {
        this.query();
      }
    });

  }

  view(d: ItemModel): void {
    this.itemService.findOne(d.id).then(item => {
      this.dialog.open(ItemModal, {
        data: {
          itemGroupId: this.itemGroupId,
          itemGroupName: this.itemGroup.itemGroupName,
          itemGroupCode: this.itemGroup.itemGroupCode,
          itemGroup: this.itemGroup,
          item,
          mode: CRUD_MODE_VIEW,
        },
      });
    });
  }

  delete(d: ItemModel): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.sku}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-danger',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.delete'),
          },
          { value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close') },
        ],
      },
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.itemService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000,
          });
          this.query();
        });
      }
    });
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  async uploadFile(id: number): Promise<any> {
    return await this.itemGroupService.uploadFile(this.fd, id);
  }

  getResult($event: any): void {
    if ($event === 'ok') {
      this.query();
      this.setItemGroupForm().then();
    }
  }
}
