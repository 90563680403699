import { Component, EventEmitter, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { ParameterModel } from '../models/parameter.model';
import { BaseComponentDirective } from '../../../../shared/base/base.component';
import { ParameterDetailService } from '../services/parameter-detail.service';
import { ParameterDetailModel } from '../models/parameter-detail.model';
import { AppComponentModule } from '../../../../shared/components/component.module';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

export interface ParameterDetailModalProps {
  model: ParameterModel;
  parameterId: number;
}

@Component({
  selector: 'app-parameter-detail-modal',
  standalone: true,
  imports: [
    AppComponentModule,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './parameter-detail.modal.html',
  styles: ``,
})
export class ParameterDetailModal extends BaseComponentDirective implements OnInit {
  public result = new EventEmitter();
  public form: FormGroup;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ParameterDetailModal>,
    @Inject(MAT_DIALOG_DATA) public data: ParameterDetailModalProps,
    private formBuilder: FormBuilder,
    private parameterDetailService: ParameterDetailService,
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      parameterName: [null, [Validators.required]],
      description: [null],
      label: [null],
      value: [null],
      valueAlt: [null],
      extras: [null],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadData();
  }

  async save() {
    try {
      let model: ParameterDetailModel;
      if (this.data?.model?.id == null) {
        model = await this.parameterDetailService.store(this.data.parameterId, this.form.value);
      } else {
        model = await this.parameterDetailService.update(this.data.parameterId, this.data.model.id, this.form.value);
      }
      this.result.emit(model);
      this.dialogRef.close();
    } catch (error) {
      console.log(error);
    }

  }

  loadData() {
    this.form.patchValue(this.data.model);
  }

  cancel() {
    this.dialogRef.close();
  }

}
