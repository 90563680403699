import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectDirective } from './select.directive';
import { injectNgControlSelect } from './select.function';

@Component({
    selector: 'app-select',
    hostDirectives: [SelectDirective],
    templateUrl: 'select.component.html',
    styleUrls: ['select.scss']
  })
  export class AppSelectComponent implements OnInit{
    ngControl = injectNgControlSelect();
    @Input() label = '';
    @Input() items: any[] = [];
    @Input() bindLabel = '';
    @Input() bindValue = '';
    @Input() readonly = false;
    @Output() valueChange: EventEmitter<any> = new EventEmitter();

    ngOnInit(): void {
        this.ngControl.control.valueChanges.subscribe(r => {
            this.valueChange.emit(r);
        });
    }

    checkRequiredValidator(): string {
        const inputFieldControl = this.ngControl.control;
        if (inputFieldControl && inputFieldControl.validator) {
          const validators = inputFieldControl.validator({} as FormControl);
          if (validators && validators.required) {
            return 'required';
          } else {
            return null;
          }
        }
      }
  }
