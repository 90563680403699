<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card m- white-background text-black-50 rounded-lg">
      <div class="mat-card-title px-3 py-3">
        <h3 class="pl-2">{{ 'pages.parameter.parameter' | translate }}</h3>
      </div>
      <x-table
        (onAddButtonClick)="openCreateDialog()"
        (onQueryChange)="changeQuery($event)"
        [buttons]="buttons"
        [columns]="columns"
        [data]="pageItems"
        [hasNumbering]="false"
        [query]="currentQuery"
        [rawData]="currentRawData"
      />
    </div>
  </div>
</div>
