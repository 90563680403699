import { Component, OnInit, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_CREATE, CRUD_MODE_EDIT, CRUD_MODE_VIEW } from 'src/app/constants/common.constant';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BasePageComponentDirective } from 'src/app/shared/base/base-page.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { environment } from 'src/environments/environment';
import { AreaModal } from '../modals/area.modal';
import { AreaModel } from '../models/area.model';
import { AreaService } from '../services/area.service';
import {StorageService} from '../../storage/services/storage.service';
import {StorageModel} from '../../storage/models/storage.model';

@Component({
  selector: 'app-page-area',
  templateUrl: './area.page.html',
})
export class AreaPageComponent extends BasePageComponentDirective<AreaModel> implements OnInit{
  storages: StorageModel[] = [];
  constructor(
    injector: Injector,
    private areaService: AreaService,
    private storageService: StorageService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.storages = await this.storageService.findAllNoPaging();
    this.query();

    this.breadcrumbService.changeMessage([
      {label: 'menu.master.index', active: false, route: ''},
      {label: 'pages.area.area', active: true, route: ''}
    ]);
  }

  query(): void {
    this.areaService.findAll(this.pageQuery).then((res) => {
      this.page.emit(res);
    });
  }

  create(): void {
    const dialog = this.dialog.open(AreaModal, {
      data: {
        storages: this.storages,
        mode: CRUD_MODE_CREATE
      }
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res === 1) {
        this.query();
      }
    });
  }

  edit(d: AreaModel): void {
    this.areaService.findOne(d.id).then(area => {
      area.imageName = (area.imageName) ? environment.storage + '/images/area/' + area.imageName : null;
      const dialog = this.dialog.open(AreaModal, {
        data: {
          area,
          storages: this.storages,
          mode: CRUD_MODE_EDIT
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.query();
        }
      });
    });

  }

  view(d: AreaModel): void {
    this.areaService.findOne(d.id).then(area => {
      area.imageName = (area.imageName) ? environment.storage + '/images/area/' + area.imageName : null;
      this.dialog.open(AreaModal, {
        data: {
          area,
          storages: this.storages,
          mode: CRUD_MODE_VIEW
        }
      });
    });

  }

  delete(d: AreaModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.areaName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-danger', color: 'primary', focus: true, label: this.translateService.instant('common.delete')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.areaService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }

  detail(d: AreaModel): void {
    this.router.navigate(['master/area/' + d.id]).then();
  }
}
