<mat-dialog-content style="width: 950px;">

  <div class="mat-card-title px-2 py-3">
    <h3 class="pl-2">{{'pages.customer.directorate' | translate}}</h3>
  </div>
  <div class="mat-card-content px-3 pb-5">
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th class="text-nowrap">{{'pages.customer.directorate' | translate}}</th>
          <th class="sticky-column" style="width: 80px;"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let directorate of directorates">
          <td class="text-nowrap">{{directorate.key}}</td>
          <td class="sticky-column">
            <div class="btn-group" role="group" aria-label="Button Action">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedDirectorate">
                <mat-radio-button [value]="directorate"></mat-radio-button>
              </mat-radio-group>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mat-card-content">
    <div class="row mt-4 mb-4">
      <div class="col-12 text-center">
        <app-pagination
          (goPage)="toPage($event)"
          [pagesToShow]="showPages"
          [page]="pageNo"
          [perPage]="pageSize"
          [count]="totalRow"></app-pagination>
      </div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions  class="justify-content-end pe-4">
  <button type="button" (click)="choose()" mat-raised-button class="py-1" color="primary">{{'common.choose' | translate}}</button>
  <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
</mat-dialog-actions>
