<div class="white-background p-5">
  <form (submit)="save()" [formGroup]="form">
    <h4 class="mat-dialog-title px-4">{{ 'pages.parameter.parameter' | translate }}</h4>
    <mat-dialog-content style="width: 600px;">

      <div class="row">
        <div class="col-12">
          <app-text-field
            [label]="'pages.parameter.parameter-name' | translate"
            formControlName="parameterName" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-area-field
            [cols]="10"
            [label]="'pages.parameter.description' | translate"
            [rows]="10"
            formControlName="description" />
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-end pe-4">
      <button class="py-1" color="primary" mat-raised-button>{{ 'common.save' | translate }}</button>
      <button class="py-1 white-background text-black-50" mat-dialog-close mat-stroked-button
              type="button">{{ 'common.close' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
