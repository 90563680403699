import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-upload-single',
    templateUrl: 'upload-single.component.html',
    styleUrls: ['upload-single.scss']
  })
  export class AppUploadSingleComponent {
    @Input() label = '';
    @Input() labelButton = '';
    @Input() required = false;
    @Input() accept = 'image/*';
    @Output() fileChange = new EventEmitter();
    @Input() readonly = false;
    showLoader = false;
    file: File;
    fileListAvatar: FileList;
    @Input() fileUrl: string | ArrayBuffer;

    onFileChange($event) {
        this.file = $event.srcElement.files[0];

        this.fileListAvatar = $event.target.files as FileList;

        const reader = new FileReader();
        reader.readAsDataURL(this.fileListAvatar[0]);
        reader.onload = () => {
          this.fileUrl = reader.result;
        };

        console.log(this.fileListAvatar);

        this.fileChange.emit(this.fileListAvatar);

    }
  }
