import {Component, Injector, OnInit} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {SupplierOrderModel} from '../../supplier-order/models/supplier-order.model';
import {BreadcrumbService} from '../../../../services/breadcrumb.service';
import {SupplierService} from '../../supplier/services/supplier.service';
import {SupplierModel} from '../../supplier/models/supplier.model';
import {SupplierOrderService} from '../../supplier-order/services/supplier-order.service';

@Component({
    selector: 'app-page-supplier-order-receiving',
    templateUrl: './supplier-order-receiving.page.html',
})
export class SupplierOrderReceivingPageComponent extends BasePageComponentDirective<SupplierOrderModel> implements OnInit{

    suppliers: SupplierModel[] = [];

    constructor(
        injector: Injector,
        private supplierService: SupplierService,
        private supplierOrderService: SupplierOrderService,
        private breadcrumbService: BreadcrumbService
        ) {
        super(injector);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();
        this.breadcrumbService.changeMessage([
            {label: 'pages.supplier.supplier', active: false, route: ''},
            {label: 'pages.supplier.order-receiving', active: true, route: ''}
        ]);
        this.suppliers = await this.supplierService.findAllNoPaging();
        this.query();
    }

    query(): void {
        this.supplierOrderService.findAllReceiving(this.pageQuery).then(async (res) => {
            this.page.emit(res);
        });
    }

    edit(d: SupplierOrderModel): void {
        this.router.navigate(['/supplier/receiving-order/edit/' + d.id]).then();
    }

}
