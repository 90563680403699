import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import { Auth } from './auth.service';
import {JWT_TOKEN_KEY} from '../constants/common.constant';
import {UiHelper} from '../helpers/ui.helper';
import {NEW_LOGIN_PAGE} from '../constants/route.constant';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  private ui = UiHelper;
  constructor(private auth: Auth, private router: Router) {}
  canActivate(): boolean {
    if (this.auth.loggedIn()) {
      return true;
    }else {
      if (localStorage.getItem(JWT_TOKEN_KEY)) {
        localStorage.removeItem(JWT_TOKEN_KEY);
      }
      this.router.navigate([this.ui.absoluteUrl(NEW_LOGIN_PAGE)]).then(() => {
        window.location.reload();
      });
    }
  }
}
