import {AfterViewChecked, ChangeDetectorRef, Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {
  AVAILABLE_LANGUAGES,
  CRUD_MODE_EDIT,
  DEFAULT_LANGUAGE,
  STORAGE_LANG_KEY,
} from '../../constants/common.constant';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from '../../services/login.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UiHelper} from '../../helpers/ui.helper';
import {MAIN_LANDING_PAGE} from '../../constants/route.constant';
import {NavDataInterface} from '../../interfaces/nav-data.interface';
import {Router, NavigationEnd} from '@angular/router';
import {ProfileInterface} from '../../interfaces/profile.interface';
import {BreadcrumbService} from '../../services/breadcrumb.service';
import {BreadcrumbInterface} from '../../interfaces/breadcrumb.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ItemGroupModal } from '../../pages/item/item-group/modals/item-group.modal';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordModal } from '../modals/change-password.modal';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SidenavComponent implements OnInit, AfterViewChecked {
  public languages = AVAILABLE_LANGUAGES;
  public isExpanded: boolean;
  public currentLang = DEFAULT_LANGUAGE;
  public isLogin: boolean;
  public ui = UiHelper;
  public notificationUrl = '';
  public navData: NavDataInterface = {state: ''};
  public mainPage = MAIN_LANDING_PAGE;
  public profile: ProfileInterface = {};
  public breadcrumbs: BreadcrumbInterface[] = [];
  constructor(
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private snackbar: MatSnackBar,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    this.isExpanded = true;
    const lang = this.languages.find((row) => row.langCode === localStorage.getItem(STORAGE_LANG_KEY));
    this.currentLang = (lang) ? lang.langValue : this.currentLang;
    this.isLogin = false;
  }
  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }
  ngOnInit(): void {
    this.isLogin = !this.jwtHelper.isTokenExpired();

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.breadcrumbService.currentMessage.subscribe((res) => {
      this.breadcrumbs = res;
    });

    this.profile = JSON.parse(localStorage.getItem('profile')) as ProfileInterface;
  }
  changeLanguage(langCode: string): void {
    localStorage.setItem(STORAGE_LANG_KEY, langCode);
    this.translate.setDefaultLang(langCode.toLocaleLowerCase());
    this.translate.use(langCode.toLocaleLowerCase());
    const lang = this.languages.find((row) => row.langCode === langCode);
    this.currentLang = lang.langValue;
  }

  changePassword(): void {
    const dialog = this.dialog.open(ChangePasswordModal, {
      data: {
        profile: this.profile,
      },
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res) {
        setTimeout(() => {
          // this.logout();
        }, 2000);
      }
    });
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['']).then(_ => {
      this.snackbar.open('Logout', '', {duration: 5000});
      window.location.reload();
    });
  }
}
