import {Component, Injector, OnInit} from '@angular/core';
import {BreadcrumbService} from '../../../services/breadcrumb.service';
import {DashboardService} from '../services/dashboard.service';
import {CustomerOrderModel} from '../../customer/customer-order/models/customer-order.model';
import { BasePageComponentDirective } from '../../../shared/base/base-page.component';
import { ItemGroupModel } from '../../item/item-group/models/item-group.model';
import { StorageService } from '../../master/storage/services/storage.service';
import { StorageModel } from '../../master/storage/models/storage.model';

@Component({
  selector: 'app-page-dashboard',
  templateUrl: './dashboard.page.html',
})
export class DashboardPageComponent extends BasePageComponentDirective<ItemGroupModel> implements OnInit{
  monthlyOrder: CustomerOrderModel[] = [];
  lastMonthOrder: CustomerOrderModel[] = [];
  monthlyPaidOrder: CustomerOrderModel[] = [];
  lastMonthPaidOrder: CustomerOrderModel[] = [];
  todayOrder: CustomerOrderModel[] = [];
  storages: StorageModel[] = [];
  storageName = 1;
  storageId = 1;
  constructor(
    injector: Injector,
    private dashboardService: DashboardService,
    private storageService: StorageService,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.breadcrumbService.changeMessage([
      {label: 'menu.dashboard.index', active: true, route: ''},
    ]);

    const date = new Date();
    const month = date.getMonth() + 1;
    const prevMonth = (date.getMonth() === 0) ? 12 : date.getMonth();
    const year = date.getFullYear();
    const prevYear = (date.getMonth() === 0) ? date.getFullYear() - 1 : date.getFullYear();

    this.monthlyOrder   = await this.dashboardService.getMonthlyCustomerOrderCount(month, year);
    this.lastMonthOrder = await this.dashboardService.getMonthlyCustomerOrderCount(prevMonth, prevYear);

    this.monthlyPaidOrder = await this.dashboardService.getMonthlyPaidCustomerOrderCount(month, year);
    this.lastMonthPaidOrder = await this.dashboardService.getMonthlyPaidCustomerOrderCount(prevMonth, prevYear);

    this.todayOrder = await this.dashboardService.getCustomerOrderCountByDate(this.date.nowToDbDate());
    this.storages = await this.storageService.findAllNoPaging();
  }

  changeCriticalStock(id: number): void {
    this.storageId = id;
    this.query();
  }

  query(): void {
    this.dashboardService.getCriticalStock(this.pageQuery, this.storageId, null).then((res) => {
      this.page.emit(res);
    });
  }

  printAll(): void {
    this.dashboardService.criticalStockPdf(this.storageId, null).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'critical-stock' + '.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching image:', error);
    });
  }

  viewAll(): void {
    this.router.navigate(['/critical-stock']).then();
  }
}
