import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgProgressModule } from 'ngx-progressbar';
import { MaterialModule } from 'src/app/material/material.module';
import { AppComponentModule } from 'src/app/shared/components/component.module';
import { AppSharedModule } from 'src/app/shared/shared.module';
import { CustomerRoutesModule } from './customer-routes.module';
import { CustomerPageComponent } from './customer/pages/customer.page';
import { CustomerModal } from './customer/modals/customer.modal';
import {CustomerOrderFormPageComponent} from './customer-order/pages/customer-order-form.page';
import {CustomerOrderPageComponent} from './customer-order/pages/customer-order.page';
import {CustomerOrderReceivingPageComponent} from './customer-order/pages/customer-order-receiving.page';
import {OrderReceivingFormPageComponent} from './customer-order/pages/order-receiving-form.page';
import {CustomerPicker} from './customer/modals/customer.picker';
import {DirectoratePicker} from './customer/modals/directorate.picker';
import {MatTooltipModule} from '@angular/material/tooltip';
import { CustomerOrderUploadPageComponent } from './customer-order-upload/pages/customer-order-upload.page';
import { CustomerOrderUploadModalDialog } from './customer-order-upload/modals/customer-order-upload-modal.dialog';

@NgModule({
    imports: [
      CommonModule,
      MaterialModule,
      TranslateModule,
      FormsModule,
      ReactiveFormsModule,
      NgProgressModule,
      AppSharedModule,
      CustomerRoutesModule,
      NgSelectModule,
      AppComponentModule,
      MatTooltipModule
    ],
    exports: [
      CustomerPageComponent,
      CustomerModal,
      CustomerPicker,
      DirectoratePicker,
    ],
    declarations: [
      CustomerPageComponent,
      CustomerModal,
      CustomerOrderFormPageComponent,
      CustomerOrderPageComponent,
      CustomerOrderReceivingPageComponent,
      OrderReceivingFormPageComponent,
      CustomerPicker,
      DirectoratePicker,
      CustomerOrderUploadPageComponent,
      CustomerOrderUploadModalDialog,
    ],
    providers: [

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
  })
  export class AppCustomerModule {}
