<div class="white-background p-5">
  <form (submit)="submitFilter()" [formGroup]="form">
    <h4 class="mat-dialog-title px-4">Advance Search</h4>
    <mat-dialog-content style="width: 600px;">
      <div *ngFor="let column of data.columns" class="row">
        <ng-container *ngIf="column.type == ColumnType.NUMBER; else formDate">
          <div class="col-6 mb-3">
            <label class="form-label">From {{ column.label ?? column.name | translate }}</label>
            <input [formControlName]="'from_' + column.name" class="form-control" type="number">
          </div>
          <div class="col-6 mb-3">
            <label class="form-label">To {{ column.label ?? column.name | translate }}</label>
            <input [formControlName]="'to_' + column.name" class="form-control" type="number">
          </div>
        </ng-container>
        <ng-template #formDate>
          <ng-container *ngIf="column.type == ColumnType.DATE; else formDateTime">
            <div class="col-6 mb-3">
              <label class="form-label">From {{ column.label ?? column.name | translate }}</label>
              <input [formControlName]="'from_' + column.name" class="form-control" type="date">
            </div>
            <div class="col-6 mb-3">
              <label class="form-label">To {{ column.label ?? column.name | translate }}</label>
              <input [formControlName]="'to_' + column.name" class="form-control" type="date">
            </div>
          </ng-container>
        </ng-template>
        <ng-template #formDateTime>
          <ng-container *ngIf="column.type == ColumnType.DATETIME; else formLookup">
            <div class="col-6 mb-3">
              <label class="form-label">From {{ column.label ?? column.name | translate }}</label>
              <input [formControlName]="'from_' + column.name" class="form-control" type="datetime-local">
            </div>
            <div class="col-6 mb-3">
              <label class="form-label">To {{ column.label ?? column.name | translate }}</label>
              <input [formControlName]="'to_' + column.name" class="form-control" type="datetime-local">
            </div>
          </ng-container>
        </ng-template>
        <ng-template #formLookup>
          <ng-container *ngIf="column.lookup; else formText">
            <div class="col-12 mb-3">
              <label class="form-label">{{ column.label ?? column.name | translate }}</label>
              <div class="input-group mb-3">
                <input [formControlName]="column.name" class="form-control">
                <select [formControlName]="column.name + '_operator'" class="form-select">
                  <option *ngFor="let operator of [Operator.LIKE, Operator.EQUAL]" value="{{operator}}">
                    {{ operator }}
                  </option>
                </select>
              </div>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #formText>
          <ng-container>
            <div class="col-12 mb-3">
              <label class="form-label">{{ column.label ?? column.name | translate }}</label>
              <div class="input-group mb-3 ">
                <select [formControlName]="column.name + '_operator'" class="form-select">
                  <option *ngFor="let operator of [Operator.LIKE, Operator.EQUAL]" value="{{operator}}">
                    {{ operator }}
                  </option>
                </select>
                <input [formControlName]="column.name" class="form-control">
              </div>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-end pe-4">
      <button class="py-1" color="primary" mat-raised-button>
        {{ 'common.submit' | translate }}
      </button>
      <button (click)="resetForm()" class="py-1" color="warn" mat-raised-button type="button">
        {{ 'Reset' | translate }}
      </button>
      <button (click)="close()" class="py-1" mat-raised-button type="button">
        {{ 'common.close' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
