import {Component, OnInit} from '@angular/core';
import {NavDataInterface} from '../../interfaces/nav-data.interface';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.scss']
})
export class MainComponent implements OnInit {
  public navData: NavDataInterface = {};
  constructor() {
  }
  ngOnInit(): void {

  }
}
