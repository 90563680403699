<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card m- white-background text-black-50 rounded-lg">
      <div class="mat-card-title px-2 py-3">
        <h3 class="pl-2">{{'pages.item.critical-stock' | translate}}</h3>
      </div>
      <div class="mat-card-content px-3 rounded">
        <div class="row">
          <div class="col-3">
            <label>{{'common.search' | translate}}</label>
            <div class="input-group mb-3">
              <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control" aria-label="search">
              <div class="input-group-append">
                    <span class="input-group-text" id="suffix_password_show">
                        <span class="icon-search"></span>
                    </span>
              </div>
            </div>
          </div>
          <div class="col-3">
            <app-select
              (valueChange)="changeCriticalStockStorage($event)"
              [readonly]="readonly"
              [items]="storages"
              [bindLabel]="'storageName'"
              [bindValue]="'id'"
              [label]="'pages.storage.storage-name' | translate"
              [(ngModel)]="storageName"></app-select>
          </div>
          <div class="col-3">
            <app-select
              (valueChange)="changeCriticalStockProduct($event)"
              [readonly]="readonly"
              [items]="products"
              [bindLabel]="'productName'"
              [bindValue]="'id'"
              [label]="'pages.product.product-name' | translate"
              [(ngModel)]="productName"></app-select>
          </div>
          <div class="col-3 pt-4">
            <button (click)="printAll()" type="button"  mat-raised-button color="primary">
              {{ 'common.print' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="mat-card-content px-3 pb-5">
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead>
            <tr>
              <th class="text-nowrap text-center">{{'pages.item-group.image' | translate}}</th>
              <th class="text-nowrap">{{'pages.item-group.item-group-code' | translate}}</th>
              <th class="text-nowrap">{{'pages.item-group.item-group-name' | translate}}</th>
              <th class="text-nowrap">{{'pages.item.unit' | translate}}</th>
              <th class="text-nowrap">{{'pages.product.product-name' | translate}}</th>
              <th class="text-nowrap">{{'pages.product-sub.name' | translate}}</th>
              <th class="text-nowrap">{{'pages.supplier.supplier-name' | translate}}</th>
              <th class="text-nowrap">{{'pages.supplier.phone-number' | translate}}</th>
              <th class="text-nowrap text-end">{{'pages.item.current-stock' | translate}}</th>
              <th class="text-nowrap text-end">{{'pages.item.remind-stock' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let itemGroup of pageItems">
              <td class="text-nowrap text-center">
                <img alt="" class="image-round-multiply" style="width: 80px; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;" [src]="itemImageBaseURL + itemGroup.imageName" />
              </td>
              <td class="text-nowrap" style="">{{itemGroup.itemGroupCode}}</td>
              <td class="text-nowrap" style="">{{itemGroup.itemGroupName}}</td>
              <td class="text-nowrap" style="">{{itemGroup.unit}}</td>
              <td class="text-nowrap" style="">{{itemGroup.productName}}</td>
              <td class="text-nowrap" style="">{{itemGroup.subProductName}}</td>
              <td class="text-nowrap" style="">{{itemGroup.supplierName}}</td>
              <td class="text-nowrap" style="">{{itemGroup.phoneNumber}}</td>
              <td class="text-nowrap text-end" style="">{{(itemGroup.currentStock ? itemGroup.currentStock : 0) | number: '1.2-2'}}</td>
              <td class="text-nowrap text-end" style="">{{(itemGroup.remindStockWhen ? itemGroup.remindStockWhen : 0) | number: '1.2-2'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mat-card-content">
        <div class="row mt-4 mb-4">
          <div class="col-12 text-center">
            <app-pagination
              (goPage)="toPage($event)"
              [pagesToShow]="showPages"
              [page]="pageNo"
              [perPage]="pageSize"
              [count]="totalRow"></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
