<div class="white-background p-5">
    <form [formGroup]="form" (submit)="save()">
      <h4 class="mat-dialog-title px-4" *ngIf="mode == 'create'">{{'pages.supplier.supplier-editor' | translate}}</h4>
      <h2 class="mat-dialog-title px-4" *ngIf="mode == 'edit' || mode == 'view'">{{'pages.supplier.supplier-editor' | translate}} #{{supplier.id}}</h2>
      <mat-dialog-content style="width: 600px;">

        <div class="row">
          <div class="col-12">
            <app-text-field [readonly]="readonly" [label]="'pages.supplier.supplier-name' | translate" formControlName="supplierName"></app-text-field>
          </div>
        </div>

        <div class="row">
            <div class="col-6">
                <app-text-field [readonly]="readonly" [label]="'pages.supplier.contact-person' | translate" formControlName="contactPerson"></app-text-field>
            </div>
            <div class="col-6">
                <app-text-field [readonly]="readonly" [label]="'pages.supplier.phone-number' | translate" formControlName="phoneNumber"></app-text-field>
              </div>
        </div>


        <div class="row">
            <div class="col-12">
                <app-area-field [cols]="10" [rows]="5" [readonly]="readonly" [label]="'pages.supplier.address' | translate" formControlName="address"></app-area-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <app-upload-single
                [readonly]="readonly"
                [fileUrl]="fileUrl"
                [accept]="'image/*'"
                [label]="'pages.supplier.image' | translate"
                [labelButton]="'pages.supplier.image-button' | translate" (fileChange)="getFileChange($event)">

              </app-upload-single>
            </div>
          </div>

      </mat-dialog-content>
      <mat-dialog-actions  class="justify-content-end pe-4">
        <button *ngIf="!viewMode" mat-raised-button class="py-1" color="primary">{{'common.save' | translate}}</button>
        <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
      </mat-dialog-actions>
    </form>
  </div>
