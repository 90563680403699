import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnType, PageInterface, PageQueryInterface, SortBy } from '../../../interfaces/page.interface';
import { AppComponentModule } from '../component.module';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AdvancedSearchComponent, AdvancedSearchProps } from './advanced-search.component';

export interface XTableColumn<T extends unknown = any> {
  name: string | keyof T;
  type?: ColumnType;
  label?: string;
  isSortable?: boolean;
  isFilterable?: boolean;
  isSearchable?: boolean;
  class?: string;
  lookup?: any;
}

export interface XTableButton<T extends unknown = any> {
  icon: string;
  name: string;
  onClick: (data: T) => void;
  tooltip?: string;
  color?: string;
}

@Component({
  selector: 'x-table',
  standalone: true,
  imports: [
    AppComponentModule,
    FormsModule,
    MatButton,
    MatIcon,
    MatTooltip,
    NgForOf,
    TranslateModule,
    NgIf,
    NgClass,
    MatIconButton,
    NgStyle,
  ],
  templateUrl: './xtable.component.html',
  styles: ``,
})
export class XTableComponent implements OnInit {

  readonly PAGE_SIZE: number = 10;

  @Input() hasNumbering: boolean = false;
  @Input() columns: XTableColumn[] = [];
  @Input() buttons: XTableButton[] = [];
  @Input() rawData: PageInterface;
  @Input() data: any[] = [];
  @Input() query: PageQueryInterface;
  @Input() defaultQuery: PageQueryInterface;

  @Output() onQueryChange: EventEmitter<PageQueryInterface> = new EventEmitter();

  @Input() hasCreate: boolean = true;
  @Output() onAddButtonClick: EventEmitter<any> = new EventEmitter();

  keyword: string = '';
  sortList: SortBy[] = [];

  constructor(private dialog: MatDialog) {
  }

  get hasFilterable(): boolean {
    return this.columns.some(c => c.isFilterable);
  }

  clickAddButton() {
    this.onAddButtonClick.emit();
  }

  ngOnInit(): void {
    this.initSearchKeyword();
    this.initSortList();
    this.firstLoad();
  }

  masking(value: any, column: XTableColumn): string {
    switch (column.type) {
      case ColumnType.DATE:
        return value == null ? '-' : (new Date(value.toString())).toLocaleDateString('id-ID', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
      case ColumnType.DATETIME:
        return value == null ? '-' : (new Date(value.toString())).toLocaleDateString('id-ID', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false, // or false for 24-hour format
        });
      case ColumnType.NUMBER:
        return value == null ? '-' : new Intl.NumberFormat('id-ID', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
      default:
        if (column.lookup == null) {
          return value?.toString() ?? '';
        } else {
          return column.lookup[value] ?? '-';
        }
    }
  }

  onSortChange(fieldName: string) {
    let index = this.sortList.findIndex(sb => sb.fieldName == fieldName);
    if (index >= 0) {
      if (this.sortList[index].direction == 'ASC') {
        this.sortList[index].direction = 'DESC';
        this.changeSortQuery();
      } else {
        this.sortList.splice(index, 1);
        this.changeSortQuery();
      }
    } else {
      this.sortList.push({
        fieldName: fieldName,
        direction: 'ASC',
      });
      this.changeSortQuery();
    }
  }

  sortIndex(fieldName: string) {
    return this.sortList.findIndex(s => s.fieldName == fieldName);
  }

  changeSortQuery() {
    this.onQueryChange.emit({
      ...this.query,
      sortList: this.sortList,
    });
  }

  initSearchKeyword() {
    this.keyword = this.defaultQuery?.searchTerm ?? '';
  }

  firstLoad() {
    this.onQueryChange.emit(this.defaultQuery ?? {
      searchTerm: '',
      pageNo: 1,
      filters: [],
      pageSize: this.PAGE_SIZE,
      sortList: [],
    });
  }

  search() {
    this.onQueryChange.emit({
      ...this.query,
      searchTerm: this.keyword,
      pageNo: 1,
      filters: [],
      searchFields: this.columns
        .filter(c => c.isSearchable)
        .map<string>(c => c.name.toString()),
    });
  }

  onPageChange(page: number) {
    this.onQueryChange.emit({
      ...this.query,
      pageNo: page,
    });
  }

  openAdvanceSearch() {
    let payload: AdvancedSearchProps = {
      query: this.query,
      columns: this.columns
        .filter(c => c.isFilterable),
    };

    const dialog = this.dialog.open<
      AdvancedSearchComponent,
      AdvancedSearchProps,
      PageQueryInterface
    >
    (AdvancedSearchComponent, {
      data: payload,
    });

    dialog.afterClosed().subscribe(query => {
      if (query != null) {
        this.onQueryChange.emit(query);
      }
    });
  }

  reset() {
    this.keyword = '';
    this.sortList = [];
    this.onQueryChange.emit({
      searchTerm: '',
      pageNo: 1,
      filters: [],
      pageSize: this.PAGE_SIZE,
      sortList: [],
    });
  }

  private initSortList() {
    this.sortList = this.defaultQuery?.sortList ?? [];
  }
}
