import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {StockAuditModel} from '../models/stock-audit.model';
import {MatDialog} from '@angular/material/dialog';
import {BreadcrumbService} from '../../../../services/breadcrumb.service';
import {StockAuditService} from '../services/stock-audit.service';
import {StockAuditListModel, StockAuditMasterModel} from '../models/stock-audit-list.model';
import {MatAccordion} from '@angular/material/expansion';
import {StorageService} from '../../../master/storage/services/storage.service';
import {StorageModel} from '../../../master/storage/models/storage.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DialogScrollComponent} from '../../../../shared/components/dialog/dialog-scroll.component';

@Component({
  selector: 'app-page-storage-audit-form',
  templateUrl: './storage-audit-form.page.html',
})
export class StorageAuditFormPageComponent extends BasePageComponentDirective<StockAuditModel> implements OnInit {
  itemList: StockAuditListModel[] = [];
  mode = 'view';
  storage: StorageModel;
  form: FormGroup;
  audit: StockAuditListModel;
  isCreated = false;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  constructor(
    injector: Injector,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    private auditService: StockAuditService,
    private storageService: StorageService,
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      storageId: [null, [Validators.required]],
      storageName: [null, []],
      description: [null, []],
      areaName: [null, [Validators.required]],
      auditStart: [null, [Validators.required]],
      auditEnd: [null, []],
      note: [null, [Validators.required]]
    });
  }

  async ngOnInit(): Promise<void> {
    // add new
    super.ngOnInit();
    this.storage = await this.storageService.findOne(this.activatedRoute.snapshot.params.storageId);
    this.audit = await this.auditService.findOneByStorageId(this.activatedRoute.snapshot.params.storageId);
    if (this.audit) {
      this.openDialog();
    }
    this.fillStorage();
    this.query();

    this.breadcrumbService.changeMessage([
      {label: 'menu.master.index', active: false},
      {label: 'pages.audit.storage-audit', active: true, route: ''}
    ]);
  }

  openDialog(): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.process-exists'),
        subtitle: this.translateService.instant('dialog.data-exists-title'),
        content: `#${this.storage.storageName} `,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-danger',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.ok')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      this.router.navigate(['/audit/storage']).then();
    });
  }

  async ngAfterViewInit(): Promise<any> {
    setTimeout(_ => {
      this.accordion.openAll();
    }, 1500);
  }

  query(): void {
    this.auditService.findAll(this.pageQuery).then((res) => {
      this.page.emit(res);
    });
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }

    const payload: StockAuditMasterModel = {
      storageId: this.form.controls.storageId.value,
      auditStart: this.form.controls.auditStart.value,
      auditEnd: this.isCreated ? this.form.controls.auditEnd.value : null,
      note: this.form.controls.note.value
    };
    this.auditService.save(payload).then(r => {
      this.isCreated = true;
      this.router.navigate(['/audit/storage/edit/' + r.id]).then();
    }, (err) => {
      console.log(err);
    });
  }

  fillStorage(): void {
    this.form.controls.storageId.setValue(this.storage.id);
    this.form.controls.storageName.setValue(this.storage.storageName);
    this.form.controls.description.setValue(this.storage.description);
    this.form.controls.areaName.setValue(this.storage.address);

  }
}
