import {Component, EventEmitter, Inject, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

import {CRUD_MODE_CREATE, CRUD_MODE_EDIT, CRUD_MODE_VIEW} from 'src/app/constants/common.constant';
import {BaseComponentDirective} from 'src/app/shared/base/base.component';
import {DialogScrollComponent} from 'src/app/shared/components/dialog/dialog-scroll.component';
import {CustomerModel} from '../models/customer.model';
import {CustomerService} from '../services/customer.service';
import {AreaService} from '../../../master/area/services/area.service';
import {AreaSubService} from '../../../master/area-sub/services/area-sub.service';
import {AreaModel} from '../../../master/area/models/area.model';
import {AreaSubModel} from '../../../master/area-sub/models/area-sub.model';

@Component({
  selector: 'app-modal-customer',
  templateUrl: './customer.modal.html',
})
export class CustomerModal extends BaseComponentDirective implements OnInit {
  public result = new EventEmitter();
  public form: FormGroup;
  public mode = '';
  public customer: CustomerModel;
  public viewMode = false;

  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;

  areas: AreaModel[] = [];
  subAreas: AreaSubModel[] = [];

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<CustomerModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private areaService: AreaService,
    private areaSubService: AreaSubService,
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      customerName: [null, [Validators.required]],
      contactPerson: [null, [Validators.required]],
      address: [null, [Validators.required]],
      phoneNumbers: [null, [Validators.required]],
      areaId: [null, [Validators.required]],
      subAreaId: [null, [Validators.required]],
    });
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.mode = this.data.mode;
    this.customer = this.data.customer;

    this.areas = await this.areaService.findAllNoPaging();
    this.form.controls.areaId.valueChanges.subscribe(async res => {
      this.form.controls.subAreaId.setValue(null);
      this.subAreas = await this.areaSubService.findAllNoPaging(res);
    });

    switch (this.mode) {
      case CRUD_MODE_EDIT: {
        this.form.setValue({
          customerName: this.customer.customerName,
          address: this.customer.address,
          contactPerson: this.customer.contactPerson,
          phoneNumbers: this.customer.phoneNumbers,
          areaId: this.customer.areaId,
          subAreaId: this.customer.subAreaId
        });

        break;
      }
      case CRUD_MODE_VIEW: {
        this.readonly = true;
        this.form.setValue({
          customerName: this.customer.customerName,
          address: this.customer.address,
          contactPerson: this.customer.contactPerson,
          phoneNumbers: this.customer.phoneNumbers,
          areaId: this.customer.areaId,
          subAreaId: this.customer.subAreaId
        });

        this.viewMode = true;
        break;
      }
    }
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }
    const payload = {
      id: (this.mode === CRUD_MODE_EDIT) ? this.customer.id : null,
      customerName: this.form.controls.customerName.value,
      address: this.form.controls.address.value,
      contactPerson: this.form.controls.contactPerson.value,
      phoneNumbers: this.form.controls.phoneNumbers.value,
      areaId: this.form.controls.areaId.value,
      subAreaId: this.form.controls.subAreaId.value,
    };

    const isCreateMode = this.mode === CRUD_MODE_CREATE;

    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: (isCreateMode) ? `#${payload.customerName}` : `#${payload.id} - ${payload.customerName}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-primary',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.save')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        if (isCreateMode) {
          this.customerService.save(payload).then((r) => {
            this.doAfterSave(r.id);
          });
        } else {
          this.customerService.update(payload).then((_) => {
            this.doAfterSave(payload.id);
          });
        }
      }
    });
  }

  doAfterSave(id: number): void {
    if (this.fd) {
      this.uploadFile(id).then();
    }

    this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
      verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
    });
    this.result.emit(1);
    this.dialogRef.close();
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  async uploadFile(id: number): Promise<any> {
    return await this.customerService.uploadFile(this.fd, id);
  }
}
