import {Injectable} from '@angular/core';
import {BasePageService} from '../../../../shared/base/base-page.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {
  BACKOFFICE,
  IMAGE_BASE_URL,
  STOCK_AUDIT_BASE_URL,
  STOCK_AUDIT_DETAIL_BASE_URL
} from '../../../../constants/api.constant';
import {StockAuditListModel, StockAuditMasterModel} from '../models/stock-audit-list.model';
import {PageInterface, PageQueryInterface} from '../../../../interfaces/page.interface';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class StockAuditService extends BasePageService<StockAuditMasterModel>{

  detailUrl = environment.url + BACKOFFICE + STOCK_AUDIT_DETAIL_BASE_URL;

  constructor(public http: HttpClient) {
    super(http);
  }

  get baseUrl(): string {
    return environment.url + BACKOFFICE + STOCK_AUDIT_BASE_URL;
  }

  async uploadFile(fd: FormData, customerId: number): Promise<any> {
    const url = environment.url + BACKOFFICE + IMAGE_BASE_URL + `/${customerId}/upload/stock-audit`;
    return await this.http.post<any>(url, fd, {}).toPromise();
  }

  async findOneByStorageId(storageId: number): Promise<any> {
    return await this.http.get<any>(this.baseUrl + `/get-run-audit/${storageId}`).toPromise();
  }

  async templateById(id: number, productId: number): Promise<any> {
    return await this.http.get<any>(this.detailUrl + `/report/storage/${id}/${productId}/xlsx`, {
      responseType: 'blob' as 'json'
    }).toPromise();
  }

  async templateByIdPdf(id: number, productId: number): Promise<any> {
    return await this.http.get<any>(this.detailUrl + `/report/storage/${id}/${productId}/pdf`, {
      responseType: 'blob' as 'json'
    }).toPromise();
  }

  async findDetailAll(p: PageQueryInterface, auditId: number, storageId: number, productId: number): Promise<PageInterface>{
    return await this.http.get<PageInterface>(this.detailUrl + `?page=${p.pageNo}&limit=${p.pageSize}&search-term=${p.searchTerm}&audit-id=${auditId}&storage-id=${storageId}&product-id=${productId}`,
      {}).toPromise();
  }

  async updateOneChild(d: StockAuditListModel, auditId: number): Promise<any> {
    return await this.http.post<PageInterface>(this.detailUrl + `/one/${auditId}`, d).toPromise();
  }

  submitSave(id: number): Observable<any> {
    return this.http.post(this.baseUrl + '/submit-save', {id});
  }

  async uploadFileXlsx(fd: FormData, storageId: number, auditId: number): Promise<any> {
    return await this.http.post<any>(this.detailUrl + `/${auditId}/storage/${storageId}/upload/xlsx`,
      fd, {}).toPromise();
  }
}
