<div class="white-background p-5">
  <form [formGroup]="form" (submit)="save()">
    <h4 class="mat-dialog-title px-4">{{'common.change-password' | translate}}</h4>
    <mat-dialog-content style="width: 600px;">

      <div class="row">
        <div class="col-12">
          <app-text-field [mode]="toggleOldPass ? 'text' : 'password'"
                          [label]="'common.old-password' | translate"
                          formControlName="oldPassword">
            <div class="input-group-append" (click)="toggleOldPass = !toggleOldPass">
                <span class="input-group-text" id="basic-addon1">
                    <i class="icon-eye" *ngIf="toggleOldPass"></i>
                    <i class="icon-eye-blocked" *ngIf="!toggleOldPass"></i>
                </span>
            </div>
          </app-text-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <app-text-field [mode]="toggleNewPass ? 'text' : 'password'"
                          [label]="'common.new-password' | translate"
                          formControlName="newPassword">
            <div class="input-group-append" (click)="toggleNewPass = !toggleNewPass">
                <span class="input-group-text" id="basic-addon2">
                    <i class="icon-eye" *ngIf="toggleNewPass"></i>
                    <i class="icon-eye-blocked" *ngIf="!toggleNewPass"></i>
                </span>
            </div>
          </app-text-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <app-text-field [mode]="toggleConfirmPass ? 'text' : 'password'"
                          [label]="'common.confirm-new-password' | translate"
                          formControlName="confirmNewPassword">
            <div class="input-group-append" (click)="toggleConfirmPass = !toggleConfirmPass">
                <span class="input-group-text" id="basic-addon3">
                    <i class="icon-eye" *ngIf="toggleConfirmPass"></i>
                    <i class="icon-eye-blocked" *ngIf="!toggleConfirmPass"></i>
                </span>
            </div>
          </app-text-field>
        </div>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions  class="justify-content-end pr-4">
      <button
              mat-raised-button
              class="py-1"
              color="primary">{{'common.update' | translate}}</button>

      <button type="button"
              mat-dialog-close
              mat-stroked-button
              class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
    </mat-dialog-actions>
  </form>
</div>
