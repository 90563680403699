import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BACKOFFICE, IMAGE_BASE_URL, PRODUCT_SUB_BASE_URL } from 'src/app/constants/api.constant';
import { PageQueryInterface, PageInterface } from 'src/app/interfaces/page.interface';
import { AreaModel } from 'src/app/pages/master/area/models/area.model';
import { environment } from 'src/environments/environment';
import { ProductSubModel } from 'src/app/pages/master/product-sub/models/product-sub.model';

@Injectable({providedIn: 'root'})
export class ProductSubService {
  constructor(private http: HttpClient) {
  }
  async findAll(p: PageQueryInterface, productId: number): Promise<PageInterface>{
    return await this.http.get<PageInterface>(environment.url + BACKOFFICE + PRODUCT_SUB_BASE_URL + `?page=${p.pageNo}&limit=${p.pageSize}&search-term=${p.searchTerm}&product-id=${productId}`, {}).toPromise();
  }

  async findAllNoPaging(productId: number): Promise<ProductSubModel[]>{
    return await this.http.get<ProductSubModel[]>(environment.url + BACKOFFICE + PRODUCT_SUB_BASE_URL + `/all/${productId}`,
      {}).toPromise();
  }

  async findOne(productSubId: number): Promise<ProductSubModel>{
    return await this.http.get<AreaModel>(environment.url + BACKOFFICE + PRODUCT_SUB_BASE_URL + `/one/${productSubId}`, {}).toPromise();
  }

  async save(productSub: ProductSubModel): Promise<ProductSubModel>{
    return await this.http.post<ProductSubModel>(environment.url + BACKOFFICE + PRODUCT_SUB_BASE_URL, productSub , {}).toPromise();
  }

  async update(productSub: ProductSubModel): Promise<ProductSubModel>{
    return await this.http.put<ProductSubModel>(environment.url + BACKOFFICE + PRODUCT_SUB_BASE_URL, productSub , {}).toPromise();
  }

  async delete(productSubId: number): Promise<any>{
    return await this.http.delete<any>(environment.url + BACKOFFICE + PRODUCT_SUB_BASE_URL + `/one/${productSubId}`, {}).toPromise();
  }

  async uploadFile(fd: FormData, productSubId: number): Promise<any> {
    return await this.http.post<any>(environment.url + BACKOFFICE + IMAGE_BASE_URL + `/${productSubId}/upload/product-sub`,
      fd, {}).toPromise();
  }
}
