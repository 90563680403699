import { Component, EventEmitter, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CRUD_MODE_CREATE, CRUD_MODE_EDIT, CRUD_MODE_VIEW, UNIT_LIST } from 'src/app/constants/common.constant';
import { BaseComponentDirective } from 'src/app/shared/base/base.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { ItemGroupModel } from '../models/item-group.model';
import { ItemGroupService } from '../services/item-group.service';
import { ProductService } from 'src/app/pages/master/product/services/product.service';
import { ProductSubService } from 'src/app/pages/master/product-sub/services/product-sub.service';
import { ProductModel } from 'src/app/pages/master/product/models/product.model';
import { ProductSubModel } from 'src/app/pages/master/product-sub/models/product-sub.model';
import { SupplierService } from '../../../supplier/supplier/services/supplier.service';
import { SupplierModel } from '../../../supplier/supplier/models/supplier.model';

@Component({
  selector: 'app-modal-item-group',
  templateUrl: './item-group.modal.html',
})
export class ItemGroupModal extends BaseComponentDirective implements OnInit {
  public result = new EventEmitter();
  public form: FormGroup;
  public mode = '';
  public itemGroup: ItemGroupModel;
  public viewMode = false;

  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;

  units = UNIT_LIST;

  products: ProductModel[] = [];
  subProducts: ProductSubModel[] = [];
  suppliers: SupplierModel[] = [];

  priorities = [
    {
      value: 1,
      label: 'High 1',
    },
    {
      value: 2,
      label: 'High 2',
    },
    {
      value: 3,
      label: 'Normal',
    },
    {
      value: 4,
      label: 'Low 1',
    },
    {
      value: 5,
      label: 'Low 2',
    },
  ];

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ItemGroupModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private itemGroupService: ItemGroupService,
    private productService: ProductService,
    private subProductService: ProductSubService,
    private supplierService: SupplierService,
    private dialog: MatDialog,
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      itemGroupName: [null, [Validators.required]],
      itemGroupCode: [null, [Validators.required]],
      productId: [null, [Validators.required]],
      subProductId: [null, [Validators.required]],
      supplierId: [null],
      description: [null, [Validators.required]],
      buyPrice: [0, [Validators.required]],
      standardPrice: [0, [Validators.required]],
      unit: [null, [Validators.required]],
      priority: [null],
    });
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.itemGroup = this.data.itemGroup as ItemGroupModel;
    this.mode = this.data.mode;

    this.suppliers = await this.supplierService.findAllNoPaging();

    this.products = await this.productService.findAllNoPaging();
    this.form.controls.productId.valueChanges.subscribe(async r => {
      this.form.controls.subProductId.setValue(null);
      this.subProducts = await this.subProductService.findAllNoPaging(r);
    });

    switch (this.mode) {
      case CRUD_MODE_EDIT: {
        this.fillForm();

        break;
      }
      case CRUD_MODE_VIEW: {
        this.readonly = true;
        this.viewMode = true;
        this.fillForm();

        break;
      }
    }
  }

  fillForm(): void {
    this.fileUrl = this.itemGroup.imageName;
    this.form.patchValue({
      itemGroupCode: this.itemGroup.itemGroupCode,
      itemGroupName: this.itemGroup.itemGroupName,
      description: (this.itemGroup.description) ? this.itemGroup.description : '',
      productId: parseInt(this.itemGroup.productId.toString(), 10),
      subProductId: parseInt(this.itemGroup.subProductId.toString(), 10),
      supplierId: (this.itemGroup.supplierId) ? parseInt(this.itemGroup.supplierId.toString(), 10) : null,
      buyPrice: this.itemGroup.buyPrice,
      standardPrice: this.itemGroup.standardPrice,
      unit: this.itemGroup.unit,
      priority: this.itemGroup.priority,
    });
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }
    const payload = {
      id: (this.mode === CRUD_MODE_EDIT) ? this.itemGroup.id : null,
      description: this.form.controls.description.value,
      itemGroupName: this.form.controls.itemGroupName.value,
      itemGroupCode: this.form.controls.itemGroupCode.value,
      productId: this.form.controls.productId.value,
      subProductId: this.form.controls.subProductId.value,
      supplierId: this.form.controls.supplierId.value,
      unit: this.form.controls.unit.value,
      buyPrice: this.ui.moneyFormatToNumber(this.form.controls.buyPrice.value),
      standardPrice: this.ui.moneyFormatToNumber(this.form.controls.standardPrice.value),
      priority: this.form.controls.priority.value,
    };

    const isCreateMode = this.mode === CRUD_MODE_CREATE;

    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: (isCreateMode) ? `#${payload.itemGroupName}` : `#${payload.id} - ${payload.itemGroupName}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-primary',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.save'),
          },
          { value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close') },
        ],
      },
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        if (isCreateMode) {
          this.itemGroupService.save(payload).then((r) => {
            this.doAfterSave(r.id);
          });
        } else {
          this.itemGroupService.update(payload).then((_) => {
            this.doAfterSave(payload.id);
          });
        }
      }
    });
  }

  doAfterSave(id: number): void {
    if (this.fd) {
      this.uploadFile(id).then();
    }

    this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
      verticalPosition: 'top', horizontalPosition: 'end', duration: 3000,
    });
    this.result.emit(1);
    this.dialogRef.close();
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  async uploadFile(id: number): Promise<any> {
    return await this.itemGroupService.uploadFile(this.fd, id);
  }

  async generateItemGroupCode(): Promise<void> {
    if (this.mode === CRUD_MODE_CREATE) {
      if (this.form.controls.subProductId.value) {
        const subProductId = this.form.controls.subProductId.value;
        const subProduct = this.subProducts.find(sp => sp.id === parseInt(subProductId, 10));
        const itemGroupName = this.form.controls.itemGroupName.value;
        const itemGroupCode = subProduct.subProductName.substring(0, 2) + itemGroupName.substring(0, 2);
        const result = await this.itemGroupService.generateItemGroupCode(subProduct.subProductName, itemGroupName, itemGroupCode);
        this.form.controls.itemGroupCode.setValue(result.code);
      } else {
        this.form.controls.itemGroupCode.setValue(null);
      }
    }

  }
}
