import {Component, EventEmitter, Inject, Injector, OnInit} from '@angular/core';
import {BaseComponentDirective} from '../../../../shared/base/base.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CRUD_MODE_CREATE, CRUD_MODE_EDIT, CRUD_MODE_VIEW} from '../../../../constants/common.constant';
import {DialogScrollComponent} from '../../../../shared/components/dialog/dialog-scroll.component';
import {SupplierOrderDetailModel} from '../models/supplier-order.model';
import {ItemGroupModel} from '../../../item/item-group/models/item-group.model';
import {ItemService} from '../../../item/item/services/item.service';

@Component({
  selector: 'app-modal-supplier-order',
  templateUrl: './supplier-order.modal.html',
  styleUrls: ['../supplier-order.scss']
})
export class SupplierOrderModal extends BaseComponentDirective implements OnInit{
  public result = new EventEmitter();
  public form: FormGroup;
  public mode = '';
  public supplierOrderDetail: SupplierOrderDetailModel;
  public viewMode = false;
  public itemGroups: ItemGroupModel[] = [];

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<SupplierOrderModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private itemService: ItemService,
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      itemGroupId: [null, [Validators.required]],
      itemGroupName: [null, []],
      buyPrice: [null, []],
      standardPrice: [null, []],
      currentStock: [null, []],
      quantity: [null, [Validators.required]],
      subtotal: [null, []],
    });
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.mode = this.data.mode;
    this.supplierOrderDetail = this.data.supplierOrderDetail;
    this.itemGroups = this.data.itemGroups;

    switch (this.mode) {
      case CRUD_MODE_EDIT: {
        this.fillForm();
        break;
      }
      case CRUD_MODE_VIEW: {
        this.form.disable();
        this.fillForm();
        this.viewMode = true;
        break;
      }
    }

    this.form.controls.itemGroupId.valueChanges.subscribe(async r => {
      if (r) {
        const itemGroup = this.itemGroups.find(ig => ig.id === r);
        const items = await this.itemService.findAllNoPaging(r);
        const justStocks = await this.itemService.findStockAll(r);

        let total = 0;
        justStocks.forEach(i => {
          total += parseFloat(i.currentStock);
        });

        this.form.controls.currentStock.setValue(total);
        this.form.controls.buyPrice.setValue(itemGroup.buyPrice);
      }

    });

    this.form.controls.quantity.valueChanges.subscribe(r => {
      this.form.controls.subtotal.setValue(this.ui.moneyFormatToNumber(
          this.ui.moneyFormatToNumber(this.form.controls.buyPrice.value)
      ) * r);
    });


  }

  fillForm(): void {
    this.form.setValue({
      itemGroupId: this.supplierOrderDetail.itemGroupId,
      itemGroupName: this.supplierOrderDetail.itemGroupName,
      quantity: this.supplierOrderDetail.quantity,
      subtotal: this.supplierOrderDetail.subtotal,
      buyPrice: this.supplierOrderDetail.buyPrice,
      standardPrice: this.supplierOrderDetail.standardPrice,
      currentStock: this.supplierOrderDetail.currentStock
    });
  }

  async save(): Promise<void> {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }
    const itemGroup = this.itemGroups.find(ig => ig.id === this.form.controls.itemGroupId.value);
    const items = await this.itemService.findAllNoPaging(itemGroup.id);
    const justStocks = await this.itemService.findStockAll(itemGroup.id);
    let total = 0;
    justStocks.forEach(i => {
      total += parseFloat(i.currentStock);
    });
    const payload = {
      id: (this.supplierOrderDetail) ? this.supplierOrderDetail.id : null,
      itemGroupId: this.form.controls.itemGroupId.value,
      itemGroupName: itemGroup.itemGroupName,
      quantity: this.ui.moneyFormatToNumber(this.form.controls.quantity.value),
      subtotal: this.ui.moneyFormatToNumber(this.form.controls.subtotal.value),
      buyPrice: parseFloat(itemGroup.buyPrice),
      standardPrice: parseFloat(itemGroup.standardPrice),
      currentStock: total,
      mode: null
    };

    const isCreateMode = this.mode === CRUD_MODE_CREATE;
    payload.mode = isCreateMode;

    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: (isCreateMode) ? `#${payload.itemGroupName}` : `#${payload.id} - ${payload.itemGroupName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.save')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        console.log(payload);
        this.result.emit(payload);
      }
      this.dialogRef.close();
    });
  }
}
