import {Component, OnInit, Injector, ViewChild, AfterViewInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_EDIT, CRUD_MODE_CREATE, CRUD_MODE_VIEW } from 'src/app/constants/common.constant';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BasePageComponentDirective } from 'src/app/shared/base/base-page.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { environment } from 'src/environments/environment';
import { AreaModal } from '../../area/modals/area.modal';
import { AreaModel } from '../../area/models/area.model';
import { AreaService } from '../../area/services/area.service';
import { AreaSubModal } from '../modals/area-sub.modal';
import { AreaSubModel } from '../models/area-sub.model';
import { AreaSubService } from '../services/area-sub.service';
import {MatAccordion} from '@angular/material/expansion';

@Component({
  selector: 'app-page-area-sub',
  templateUrl: './area-sub.page.html',
})
export class AreaSubPageComponent extends BasePageComponentDirective<AreaSubModel> implements OnInit, AfterViewInit {

  areaId: number;
  area: AreaModel;
  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;
  public readonly = true;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    injector: Injector,
    private areaSubService: AreaSubService,
    private areaService: AreaService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
  }

  async ngAfterViewInit(): Promise<any> {
    setTimeout(_ => {
      this.accordion.openAll();
    }, 2000);
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
    this.areaId = this.activatedRoute.snapshot.params.id;
    if (!this.areaId) {
      this.router.navigate(['master/area']).then();
    }

    this.setAreaForm().then();

    this.query();

    this.breadcrumbService.changeMessage([
      {label: 'menu.master.index', active: false},
      {label: 'pages.area.area', active: false, route: '/master/area'},
      {label: 'pages.area-sub.area-sub', active: true, route: ''}
    ]);
  }

  async setAreaForm(): Promise<void> {
    this.area = await this.areaService.findOne(this.areaId);
    this.fileUrl =  environment.storage + '/images/area/' + this.area.imageName;
    this.fileUrl += '?rand=' + Math.random();
  }

  query(): void {
    this.areaSubService.findAll(this.pageQuery, this.areaId).then((res) => {
      this.page.emit(res);
    });
  }

  save(): void {
    this.areaService.findOne(this.area.id).then(area => {
      area.imageName = environment.storage + '/images/area/' + area.imageName;
      const dialog = this.dialog.open(AreaModal, {
        data: {
          area,
          mode: CRUD_MODE_EDIT
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.router.navigate(['/master/area']).then();
        }
      });
    });
  }

  create(): void {
    const dialog = this.dialog.open(AreaSubModal, {
      data: {
        areaId: this.areaId,
        mode: CRUD_MODE_CREATE
      }
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res === 1) {
        this.query();
      }
    });
  }

  edit(d: AreaSubModel): void {
    this.areaSubService.findOne(d.id).then(areaSub => {
      areaSub.imageName = (areaSub.imageName) ? environment.storage + '/images/sub-area/' + areaSub.imageName : null;
      const dialog = this.dialog.open(AreaSubModal, {
        data: {
          areaId: this.areaId,
          areaSub,
          mode: CRUD_MODE_EDIT
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.query();
        }
      });
    });

  }

  view(d: AreaSubModel): void {
    this.areaSubService.findOne(d.id).then(areaSub => {

      areaSub.imageName = (areaSub.imageName) ? environment.storage + '/images/sub-area/' + areaSub.imageName : null;
      this.dialog.open(AreaSubModal, {
        data: {
          areaId: this.areaId,
          areaSub,
          mode: CRUD_MODE_VIEW
        }
      });
    });

  }

  delete(d: AreaSubModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.subAreaName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-danger', color: 'primary', focus: true, label: this.translateService.instant('common.delete')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.areaSubService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  async uploadFile(id: number): Promise<any> {
    return await this.areaService.uploadFile(this.fd, id);
  }
}
