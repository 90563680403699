<div class="fixActionRow">
<div class="close-button d-flex justify-content-between p-3" *ngIf="dialogData.subtitle">
  <b>{{dialogData.subtitle}}</b>
  <button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
</div>
<div class="close-button" *ngIf="!dialogData.subtitle">
  <button class="clear-transparent" mat-dialog-close><span class="icon-close"></span></button>
</div>
<mat-dialog-content class="mat-typography">
  <div *ngIf="dialogData.imageUrl" class="text-center top-image">
    <img [alt]="dialogData.imageAlt" [src]="dialogData.imageUrl" />
  </div>
  <h4 class="text-center">{{dialogData.title}}</h4>
  <p *ngIf="dialogData.content" [innerHTML]="dialogData.content | keepHtml"></p>

  <div *ngIf="dialogData.extraText"  class="well red mb-3">
    {{ dialogData.extraText }}
  </div>
  <p *ngIf="dialogData.footContent" >{{dialogData.content}}</p>

</mat-dialog-content>
<div class="spacer"></div>
<mat-dialog-actions align="end">
  <ng-container *ngFor="let btn of dialogData.buttons">
    <button *ngIf="btn.focus" cdkFocusInitial [mat-dialog-close]="btn.value" mat-button mat-dialog-close class="{{btn.className}}">{{btn.label | translate}}</button>
    <button *ngIf="!btn.focus" [mat-dialog-close]="btn.value" mat-button mat-dialog-close class="{{btn.className}}">{{btn.label | translate}}</button>
  </ng-container>
</mat-dialog-actions>
</div>
