import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import { AREA_BASE_URL, BACKOFFICE, IMAGE_BASE_URL } from 'src/app/constants/api.constant';
import { BasePageService } from 'src/app/shared/base/base-page.service';
import { AreaModel } from '../models/area.model';

@Injectable({providedIn: 'root'})
export class AreaService extends BasePageService<AreaModel>{
  constructor(public http: HttpClient) {
    super(http);
  }

  get baseUrl(): string {
    return environment.url + BACKOFFICE + AREA_BASE_URL;
  }

  async uploadFile(fd: FormData, areaId: number): Promise<any> {
    return await this.http.post<any>(environment.url + BACKOFFICE + IMAGE_BASE_URL + `/${areaId}/upload/area`, fd, {}).toPromise();
  }
}
