import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {MatDialog} from '@angular/material/dialog';
import {BreadcrumbService} from '../../../../services/breadcrumb.service';
import {StockAuditService} from '../services/stock-audit.service';
import {StockAuditListModel, StockAuditMasterModel} from '../models/stock-audit-list.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StorageModel} from '../../../master/storage/models/storage.model';
import {MatAccordion} from '@angular/material/expansion';
import {StorageService} from '../../../master/storage/services/storage.service';
import {DialogScrollComponent} from '../../../../shared/components/dialog/dialog-scroll.component';
import {REPORT_TYPES} from '../../../../constants/common.constant';
import {ProductModel} from '../../../master/product/models/product.model';
import {ProductService} from '../../../master/product/services/product.service';
import { AuditTemplateUploadModal } from '../modals/audit-template-upload.modal';

@Component({
  selector: 'app-page-storage-audit-editor',
  templateUrl: './storage-audit-editor.page.html',
})
export class StorageAuditEditorPageComponent extends BasePageComponentDirective<any> implements OnInit {
  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private auditService: StockAuditService,
    private storageService: StorageService,
    private productService: ProductService
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      storageId: [null, [Validators.required]],
      storageName: [null, []],
      description: [null, []],
      areaName: [null, [Validators.required]],
      auditStart: [null, [Validators.required]],
      auditEnd: [null, []],
      note: [null, [Validators.required]]
    });
  }
  itemList: StockAuditListModel[] = [];
  mode = 'view';
  storage: StorageModel;
  form: FormGroup;
  audit: StockAuditMasterModel;
  disableSubmit = false;
  products: ProductModel[] = [];
  productId = 0;
  f = 0;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  protected readonly REPORT_TYPES = REPORT_TYPES;

  async ngOnInit(): Promise<void> {
    // editor
    super.ngOnInit();
    this.audit = await this.auditService.findOne(this.activatedRoute.snapshot.params.id);
    this.storage = await this.storageService.findOne(this.audit.storageId);
    this.products = await this.productService.findAllNoPaging();
    this.query();
    this.fillStorage();
    this.breadcrumbService.changeMessage([
      {label: 'menu.audit.index', active: false},
      {label: 'pages.audit.storage-audit', active: false, route: '/audit/storage'},
      {label: 'common.edit', active: true, route: ''}
    ]);
  }

  async ngAfterViewInit(): Promise<any> {
    setTimeout(_ => {
      this.accordion.openAll();
    }, 1500);
  }

  getChangedProductId($event): void {
    this.f = $event;
    if (this.audit) {
      this.query();
    }
  }

  query(): void {
    this.auditService.findDetailAll(this.pageQuery, this.audit.id, this.audit.storageId, this.f).then((res) => {
      this.page.emit(res);
    });
  }

  save(): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: `#${this.storage.storageName}` ,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-primary',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.save')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.auditService.update({
          id: this.audit.id,
          storageId: this.audit.storageId,
          auditStart: this.audit.auditStart,
          auditEnd: this.audit.auditEnd,
          note: this.form.controls.note.value
        }).then(r => {
          this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
        });
      }
    });
  }

  getAuditedStock(d: StockAuditListModel, i: number, auditedStock: any): void {
    this.pageItems[i].auditedStock = auditedStock;
  }

  getReason(d: StockAuditListModel, i: number, reason: any): void {
    this.pageItems[i].reason = reason;
  }

  updateAll(): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: this.translateService.instant('pages.audit.update-all') ,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-primary',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.save')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.pageItems.forEach(d => {
          if (d.currentStock !== d.auditedStock) {
            this.auditService.updateOneChild(d, this.audit.id).then(_ => {
              this.query();
              this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
                verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
              });
            });
          }
        });
      }
    });
  }

  update(d: StockAuditListModel, i: number): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: `#${d.itemGroupCode} ${d.itemGroupName}` ,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-primary',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.save')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.auditService.updateOneChild(d, this.audit.id).then(_ => {
          this.query();
          this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
        });
      }
    });
  }

  uploadTemplate(): void {
    //
  }

  downloadTemplate(): void {
    this.auditService.templateById(this.audit.id, this.f).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'template' + '.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching image:', error);
    });
  }

  downloadTemplatePdf(): void {
    this.auditService.templateByIdPdf(this.audit.id, this.f).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'template' + '.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching image:', error);
    });
  }

  uploadXlsx(): void {
    const dialog = this.dialog.open(AuditTemplateUploadModal, {
      data: {
        auditId: this.audit.id,
        storageId: this.storage.id
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.query();
      }
    });
  }

  fillStorage(): void {
    this.form.controls.storageId.setValue(this.storage.id);
    this.form.controls.storageName.setValue(this.storage.storageName);
    this.form.controls.description.setValue(this.storage.description);
    this.form.controls.areaName.setValue(this.storage.address);
    this.form.controls.auditStart.setValue(this.audit.auditStart);
    if (this.audit.auditEnd) {
      this.form.controls.auditEnd.setValue(this.audit.auditEnd);
    }
    this.form.controls.note.setValue(this.audit.note);
  }

  finish(): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: this.translateService.instant('pages.audit.submit-confirmation') ,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-primary',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.save')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.disableSubmit = true;
        this.auditService.submitSave(this.audit.id).pipe().subscribe({complete: () => {
            this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
              verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
            });
            this.router.navigate(['/audit/storage']).then();
        }, error: (err) => {
          this.disableSubmit = false;
          console.log(err);
        }});
      }
    });
  }
}
