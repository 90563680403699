import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../../material/material.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgProgressModule} from 'ngx-progressbar';
import {AppSharedModule} from '../../shared/shared.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {AppComponentModule} from '../../shared/components/component.module';
import {StorageAuditPageComponent} from './storage-audit/pages/storage-audit.page';
import {AuditRoutesModule} from './audit-routes.module';
import {StorageAuditFormPageComponent} from './storage-audit/pages/storage-audit-form.page';
import {StorageAuditEditorPageComponent} from './storage-audit/pages/storage-audit-editor.page';
import { AuditTemplateUploadModal } from './storage-audit/modals/audit-template-upload.modal';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule,
    AuditRoutesModule,
    NgSelectModule,
    AppComponentModule
  ],
  exports: [
    StorageAuditPageComponent,
    StorageAuditFormPageComponent,
    StorageAuditEditorPageComponent,
    AuditTemplateUploadModal
  ],
  declarations: [
    StorageAuditPageComponent,
    StorageAuditFormPageComponent,
    StorageAuditEditorPageComponent,
    AuditTemplateUploadModal
  ],
  providers: [

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppAuditModule {}
