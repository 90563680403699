import { Component, EventEmitter, Inject, Injector, OnInit } from '@angular/core';
import { BaseComponentDirective } from '../../../../shared/base/base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StockAuditService } from '../services/stock-audit.service';

@Component({
  selector: 'app-modal-audit-upload-template',
  templateUrl: './audit-template-upload.modal.html',
})
export class AuditTemplateUploadModal extends BaseComponentDirective implements OnInit {
  public result = new EventEmitter();
  public form: FormGroup;
  public mode = '';

  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<AuditTemplateUploadModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private auditService: StockAuditService,
    private dialog: MatDialog
  ) {
    super(injector);
    this.form = this.formBuilder.group({
    });
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
  }

  save(): void {
    this.uploadFile(0).then(_ => {
      this.snackBar.open(this.translateService.instant('pages.audit.upload-success'), '', {duration: 5000});
      this.result.emit(true);
      this.dialogRef.close();
    }, _ => {
      this.snackBar.open(this.translateService.instant('pages.audit.upload-failed'), '', {duration: 5000});
    });
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  async uploadFile(id: number): Promise<any> {
    return await this.auditService.uploadFileXlsx(this.fd, this.data.storageId, this.data.auditId);
  }
}
