<div class="mat-card-content px-3 rounded">
  <div class="row">
    <div class="col-4">
      <div class="form-group">
        <label>{{ 'common.search' | translate }}</label>
        <form (submit)="search()">
          <div class="input-group mb-3">
            <div class="input-group-append">
            <span class="input-group-text" id="suffix_password_show">
                <span class="icon-search"></span>
            </span>
            </div>
            <input [(ngModel)]="keyword" aria-label="search" class="form-control">
            <button class="btn btn-sm btn-outline-success" type="submit">
              Search
            </button>
            <button (click)="openAdvanceSearch()" *ngIf="hasFilterable" class="btn btn-sm btn-outline-primary"
                    type="button">
              Filter <span *ngIf="query?.filters?.length" class="badge bg-secondary">{{ query.filters.length }}</span>
            </button>
            <button (click)="reset()" class="btn btn-sm btn-outline-warning" type="button">
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="col-8 pt-3 text-end">
      <button (click)="clickAddButton()" *ngIf="hasCreate" class="text-white" color="primary"
              mat-flat-button>{{ 'common.create' | translate }}
      </button>
    </div>
  </div>
</div>
<div class="mat-card-content px-3 pb-3">
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead>
      <tr>
        <th *ngIf="hasNumbering" class="text-nowrap text-end">
          {{ 'common.no' | translate }}
        </th>
        <th (click)="column.isSortable ? onSortChange(column.name) : undefined"
            *ngFor="let column of columns" [ngClass]="column.class"
            [ngStyle]="{'cursor': column.isSortable ? 'pointer' : ''}"
            class="text-nowrap">
          {{ (column.label ?? column.name) | translate }}
          <span *ngIf="sortIndex(column.name) >= 0" class="badge bg-secondary">
            {{ sortIndex(column.name) + 1 }} : {{ sortList[sortIndex(column.name)].direction }}
          </span>
        </th>
        <th *ngIf="buttons.length > 0" class="sticky-column text-end">
          {{ 'common.action' | translate }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let d of data; index as i">
        <td *ngIf="hasNumbering" class="text-nowrap text-end">
          {{ (((query.pageNo - 1) * query.pageSize) + i + 1) }}
        </td>
        <td *ngFor="let column of columns" [ngClass]="column.class" class="text-nowrap">
          {{ masking(d[column.name], column) }}
        </td>
        <td *ngIf="buttons.length > 0" class="sticky-column text-end">
          <div aria-label="Button Action" class="btn-group" role="group">
            <button (click)="button.onClick(d)" *ngFor="let button of buttons" [color]="button.color ?? 'primary'"
                    [matTooltip]="button.tooltip ?? button.name | translate" mat-button
                    type="button">
              <mat-icon>{{ button.icon }}</mat-icon>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="rawData?.meta" class="mat-card-content">
  <div class="row mb-4">
    <div class="col-12 text-center">
      <app-pagination
        (goPage)="onPageChange($event)"
        [count]="rawData.meta.totalItems"
        [page]="rawData.meta.currentPage"
        [pagesToShow]="3"
        [perPage]="rawData.meta.itemsPerPage"></app-pagination>
    </div>
  </div>
</div>
