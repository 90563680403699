import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {SupplierItemModel} from '../models/supplier-item.model';
import {SupplierModel} from '../../supplier/models/supplier.model';
import {MatDialog} from '@angular/material/dialog';
import {BreadcrumbService} from '../../../../services/breadcrumb.service';
import {SupplierModal} from '../../supplier/modals/supplier.modal';
import {CRUD_MODE_EDIT} from '../../../../constants/common.constant';
import {DialogScrollComponent} from '../../../../shared/components/dialog/dialog-scroll.component';
import {SupplierItemService} from '../services/supplier-item.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SupplierService} from '../../supplier/services/supplier.service';
import {environment} from '../../../../../environments/environment';
import {ItemGroupPicker} from '../../../item/item-group/modals/item-group.picker';
import {MatAccordion} from '@angular/material/expansion';

@Component({
  selector: 'app-page-supplier-item',
  templateUrl: './supplier-item.page.html',
})
export class SupplierItemPageComponent extends BasePageComponentDirective<SupplierItemModel> implements OnInit {
  readonly = true;
  form: FormGroup;
  supplier: SupplierModel;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private svc: SupplierItemService,
    private supplierSvc: SupplierService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      supplierName: [null, [Validators.required]],
      contactPerson: [null, [Validators.required]],
      address: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
    });
  }

  async ngAfterViewInit(): Promise<any> {
    setTimeout(_ => {
      this.accordion.openAll();
    }, 2000);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.query();
    const id = this.activatedRoute.snapshot.params.id;
    this.supplierSvc.findOne(id).then(supplier => {
      this.supplier = supplier;
      this.form.setValue({
        supplierName: supplier.supplierName,
        contactPerson: supplier.contactPerson,
        address: supplier.address,
        phoneNumber: supplier.phoneNumber
      });
    });

    this.breadcrumbService.changeMessage([
      {label: 'pages.supplier.supplier', active: false},
      {label: 'pages.supplier.supplier', active: false, route: '/supplier/supplier'},
      {label: 'pages.supplier.supplier-item', active: true, route: ''}
    ]);
  }

  query(): void {
    const id = this.activatedRoute.snapshot.params.id;
    this.svc.findAllByType(this.pageQuery, id, 'supplier').then(async (res) => {
      this.page.emit(res);
    });
  }

  save(): void {
    this.supplierSvc.findOne(this.supplier.id).then(supplier => {
      supplier.imageName = environment.storage + '/images/supplier/' + supplier.imageName;
      const dialog = this.dialog.open(SupplierModal, {
        data: {
          supplier,
          mode: CRUD_MODE_EDIT
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.router.navigate(['/supplier/supplier']).then();
        }
      });
    });
  }

  create(): void {
    const dialog = this.dialog.open(ItemGroupPicker, {});

    dialog.componentInstance.result.subscribe((res) => {
      if (res) {
        this.svc.save({
          supplierId: this.supplier.id,
          itemGroupId: res.id,
          buyPrice: 0
        }).then(_ => {
          this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }

  getValueChange(d: SupplierItemModel, $event: any): void {
    d.buyPrice = $event;
  }

  saveItem(d: SupplierItemModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: `#${d.id} - ${d.itemGroupName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.save')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        d.buyPrice = this.ui.moneyFormatToNumber(d.buyPrice);
        this.svc.update(d).then((_) => {
          this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
        });
      }
    });
  }

  delete(d: SupplierItemModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.itemGroupName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-danger', color: 'primary', focus: true, label: this.translateService.instant('common.delete')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.svc.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }
}
