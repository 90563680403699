import {Injectable} from '@angular/core';
import {BasePageService} from '../../../../shared/base/base-page.service';
import {ItemGroupModel} from '../../../item/item-group/models/item-group.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {BACKOFFICE, ITEM_GROUP_BASE_URL, SUPPLIER_ITEM_BASE_URL} from '../../../../constants/api.constant';
import {SupplierItemModel} from '../models/supplier-item.model';
import {PageInterface, PageQueryInterface} from '../../../../interfaces/page.interface';

@Injectable({providedIn: 'root'})
export class SupplierItemService extends BasePageService<SupplierItemModel>{
  private urlItemGroup = environment.url + BACKOFFICE + ITEM_GROUP_BASE_URL;
  constructor(public http: HttpClient) {
    super(http);
  }

  get baseUrl(): string {
    return environment.url + BACKOFFICE + SUPPLIER_ITEM_BASE_URL;
  }

  async findBySupplierId(supplierId: number): Promise<SupplierItemModel[]> {
    return await this.http.get<ItemGroupModel[]>(this.baseUrl + '/supplier/' + supplierId).toPromise();
  }

  async findAllByType(p: PageQueryInterface, id: number, type: string): Promise<PageInterface>{
    return await this.http.get<PageInterface>(this.baseUrl + `?page=${p.pageNo}&limit=${p.pageSize}&search-term=${p.searchTerm}&id=${id}&type=${type}`,
      {}).toPromise();
  }

  async setItemGroupPrice(d: SupplierItemModel): Promise<SupplierItemModel> {
    return await this.http.post<SupplierItemModel>(this.urlItemGroup + `/pick`, d, {}).toPromise();
  }
}
