import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { ItemModel } from '../models/item.model';
import { environment } from 'src/environments/environment';
import { BACKOFFICE, ITEM_BASE_URL } from 'src/app/constants/api.constant';
import { PageInterface, PageQueryInterface } from 'src/app/interfaces/page.interface';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ItemService  {
  private selectedItemSubject: BehaviorSubject<ItemModel[]> = new BehaviorSubject<ItemModel[]>([]);
  public selectedItems$: Observable<ItemModel[]> = this.selectedItemSubject.asObservable();
  checkedItems: Map<number, ItemModel> = new Map<number, ItemModel>();
  constructor(public http: HttpClient) {
  }
  async findAll(p: PageQueryInterface, itemGroupId: number): Promise<PageInterface>{
    return await this.http.get<PageInterface>(environment.url + BACKOFFICE + ITEM_BASE_URL + `?page=${p.pageNo}&limit=${p.pageSize}&search-term=${p.searchTerm}&item-group-id=${itemGroupId}`, {}).toPromise();
  }

  async findAllNoPaging(itemGroupId: number): Promise<ItemModel[]>{
    return await this.http.get<ItemModel[]>(environment.url + BACKOFFICE + ITEM_BASE_URL + `/all/${itemGroupId}`, {}).toPromise();
  }

  async findOne(itemId: number): Promise<ItemModel>{
    return await this.http.get<ItemModel>(environment.url + BACKOFFICE + ITEM_BASE_URL + `/one/${itemId}`, {}).toPromise();
  }

  async findByAreaId(areaId: number): Promise<ItemModel[]>{
    return await this.http.get<ItemModel[]>(environment.url + BACKOFFICE + ITEM_BASE_URL + `/area/${areaId}`, {}).toPromise();
  }

  async findByAreaIdPaged(areaId: number, p: PageQueryInterface): Promise<PageInterface>{
    return await this.http.get<PageInterface>(environment.url + BACKOFFICE + ITEM_BASE_URL + `/area-paged/${areaId}?page=${p.pageNo}&limit=${p.pageSize}&search-term=${p.searchTerm}`, {}).toPromise();
  }

  async findStockAll(itemGroupId: number): Promise<any>{
    return await this.http.get<any>(environment.url + BACKOFFICE + ITEM_BASE_URL + `/stock/${itemGroupId}`, {}).toPromise();
  }

  async save(item: ItemModel): Promise<ItemModel>{
    return await this.http.post<ItemModel>(environment.url + BACKOFFICE + ITEM_BASE_URL, item , {}).toPromise();
  }

  async update(item: ItemModel): Promise<ItemModel>{
    return await this.http.put<ItemModel>(environment.url + BACKOFFICE + ITEM_BASE_URL, item , {}).toPromise();
  }

  async delete(itemId: number): Promise<any>{
    return await this.http.delete<any>(environment.url + BACKOFFICE + ITEM_BASE_URL + `/one/${itemId}`, {}).toPromise();
  }

  async uploadFile(fd: FormData, itemId: number): Promise<any> {
    return await this.http.post<any>(environment.url + BACKOFFICE + ITEM_BASE_URL + `/${itemId}/upload/item`, fd, {}).toPromise();
  }

  toggleItem(itemGroup: ItemModel): void {
    if (this.checkedItems.has(itemGroup.id)) {
      this.checkedItems.delete(itemGroup.id);
    } else {
      this.checkedItems.set(itemGroup.id, itemGroup);
    }
  }
}
