import { Component, EventEmitter, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponentDirective } from 'src/app/shared/base/base.component';
import { ParameterModel } from '../models/parameter.model';
import { ParameterService } from '../services/parameter.service';

export interface ParameterModalProps {
  model: ParameterModel;
}

@Component({
  selector: 'app-modal-parameter',
  templateUrl: './parameter.modal.html',
})
export class ParameterModal extends BaseComponentDirective implements OnInit {
  public result = new EventEmitter();
  public form: FormGroup;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ParameterModal>,
    @Inject(MAT_DIALOG_DATA) public data: ParameterModalProps,
    private formBuilder: FormBuilder,
    private parameterService: ParameterService,
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      parameterName: [null, [Validators.required]],
      description: [null],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadData();
  }

  async save() {
    try {
      let model: ParameterModel;
      if (this.data?.model?.id == null) {
        model = await this.parameterService.store(this.form.value);
      } else {
        model = await this.parameterService.update(this.data.model.id, this.form.value);
      }
      this.result.emit(model);
      this.dialogRef.close();
    } catch (error) {
      console.log(error);
    }

  }

  loadData() {
    this.form.patchValue(this.data.model);
  }

  cancel() {
    this.dialogRef.close();
  }
}
