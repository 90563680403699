<mat-dialog-content style="width: 1250px;">

  <div class="mat-card-title px-2 py-3">
    <h3 class="pl-2">{{ 'pages.item-group.item-group' | translate }}</h3>
  </div>
  <div class="mat-card-content px-3 rounded">
    <div class="row">
      <div class="col-4">
        <label>{{ 'common.search' | translate }}</label>
        <div class="input-group mb-3">
          <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control"
                 aria-label="search">
          <div class="input-group-append">
                    <span class="input-group-text" id="suffix_password_show">
                        <span class="icon-search"></span>
                    </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mat-card-content px-3 pb-5">
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th></th>
          <th class="text-nowrap">{{ 'pages.item-group.item-group-name' | translate }}</th>
          <th class="text-nowrap">{{ 'pages.item.sku' | translate }}</th>
          <th class="text-nowrap">{{ 'pages.item.unit' | translate }}</th>
          <th class="text-nowrap">{{ 'pages.product.product-name' | translate }}</th>
          <th class="text-nowrap">{{ 'pages.product-sub.name' | translate }}</th>
          <th class="text-nowrap">{{ 'pages.item.current-stock' | translate }}</th>
          <th class="text-nowrap">{{ 'pages.item.sell-price' | translate }}</th>
          <th class="sticky-column"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of pageItems; let arrIdx = index">
          <td class="text-nowrap">
            <img alt="" class="image-round-multiply"
                 style="width: 80px; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;"
                 [src]="itemImageBaseURL + item.imageName"/>
          </td>
          <td class="text-nowrap" style="">{{ item.itemGroupName }}</td>
          <td class="text-nowrap" style="">{{ item.sku }}</td>
          <td class="text-nowrap" style="">{{ item.unit }}</td>
          <td class="text-nowrap" style="">{{ maskingColumn('productName', 'id', products, item.productId) }}</td>
          <td class="text-nowrap" style="">{{ maskingColumn('subProductName', 'id', subProducts, item.subProductId) }}
          </td>
          <td class="text-nowrap text-end" style="">{{ item.currentStock | number }}</td>
          <td class="text-nowrap text-end" style="">{{ item.sellPrice | number }}</td>
          <td class="sticky-column" style="">
            <mat-checkbox (change)="checkMulti($event, item, arrIdx)"
                          [checked]="currentPageCheckedItems.has(item.id)">
            </mat-checkbox>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mat-card-content">
    <div class="row mt-4 mb-4">
      <div class="col-12 text-center">
        <app-pagination
          (goPage)="toPage($event)"
          [pagesToShow]="showPages"
          [page]="pageNo"
          [perPage]="pageSize"
          [count]="totalRow"></app-pagination>
      </div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions class="justify-content-end pe-4">
  <button type="button" (click)="choose()" mat-raised-button class="py-1"
          color="primary">{{ 'common.choose' | translate }}
  </button>
  <button type="button" mat-dialog-close mat-stroked-button
          class="py-1 white-background text-black-50">{{ 'common.close' | translate }}
  </button>
</mat-dialog-actions>
