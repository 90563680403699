import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {SupplierItemModel} from '../../../supplier/supplier-item/models/supplier-item.model';
import {SupplierModel} from '../../../supplier/supplier/models/supplier.model';
import {SupplierItemService} from '../../../supplier/supplier-item/services/supplier-item.service';
import {SupplierService} from '../../../supplier/supplier/services/supplier.service';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {SupplierModal} from '../../../supplier/supplier/modals/supplier.modal';
import {CRUD_MODE_EDIT} from '../../../../constants/common.constant';
import {DialogScrollComponent} from '../../../../shared/components/dialog/dialog-scroll.component';
import {SupplierPicker} from '../../../supplier/supplier/modals/supplier.picker';
import {ItemGroupModel} from '../../item-group/models/item-group.model';

@Component({
  selector: 'app-page-item-supplier-item',
  templateUrl: './item-supplier-item.page.html',
})
export class ItemSupplierItemPageComponent extends BasePageComponentDirective<SupplierItemModel> implements OnInit {
  readonly = true;
  supplier: SupplierModel;
  @Input() itemGroupId: number;
  @Input() itemGroup: ItemGroupModel;
  @Output() result = new EventEmitter();
  constructor(
    injector: Injector,
    private svc: SupplierItemService,
    private supplierSvc: SupplierService,
    private dialog: MatDialog,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.query();
  }

  query(): void {
    this.svc.findAllByType(this.pageQuery, this.itemGroupId, 'item').then(async (res) => {
      this.page.emit(res);
    });
  }

  save(): void {
    this.supplierSvc.findOne(this.supplier.id).then(supplier => {
      supplier.imageName = environment.storage + '/images/supplier/' + supplier.imageName;
      const dialog = this.dialog.open(SupplierModal, {
        data: {
          supplier,
          mode: CRUD_MODE_EDIT
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.router.navigate(['/supplier/supplier']).then();
        }
      });
    });
  }

  create(): void {
    const dialog = this.dialog.open(SupplierPicker, {});

    dialog.componentInstance.result.subscribe((res) => {
      if (res) {
        this.svc.save({
          supplierId: res.id,
          itemGroupId: this.itemGroupId,
          buyPrice: 0
        }).then(_ => {
          this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }

  getValueChange(d: SupplierItemModel, $event: any): void {
    d.buyPrice = $event;
  }

  getStandardChange(d: SupplierItemModel, $event: any): void {
    d.standardPrice = $event;
  }

  saveItem(d: SupplierItemModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: `#${d.id} - ${d.itemGroupName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.save')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        d.buyPrice = this.ui.moneyFormatToNumber(d.buyPrice);
        d.standardPrice = this.ui.moneyFormatToNumber(d.standardPrice);
        this.svc.update(d).then((_) => {
          this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
        });
      }
    });
  }

  pickMe(d: SupplierItemModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.pick-message'),
        subtitle: this.translateService.instant('dialog.pick-title'),
        content: `#${d.id} - ${d.itemGroupName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.save')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        d.buyPrice = this.ui.moneyFormatToNumber(d.buyPrice);
        d.standardPrice = this.ui.moneyFormatToNumber(d.standardPrice);
        this.svc.setItemGroupPrice(d).then((_) => {
          this.snackBar.open(this.translateService.instant('dialog.pick-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.result.emit('ok');
        });
      }
    });
  }

  delete(d: SupplierItemModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.itemGroupName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-danger', color: 'primary', focus: true, label: this.translateService.instant('common.delete')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.svc.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }
}
