import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../../material/material.module';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppTextFieldComponent } from './text-field/text-field.component';
import { AppAreaFieldComponent } from './area-field/area-field.component';
import { AppNumberFieldComponent } from './number-field/number-field.component';
import { AppSelectComponent } from './select/select.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AppUploadSingleComponent } from './upload-single/upload-single.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DialogScrollComponent } from './dialog/dialog-scroll.component';
import { EscapeHtmlPipe } from 'src/app/pipes/escape-html.pipe';
import {AppDatePickerComponent} from "./date-picker/date-picker.component";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {PICKER_DATE_FORMATS} from "../../constants/common.constant";


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  exports: [
    AppTextFieldComponent,
    AppAreaFieldComponent,
    AppNumberFieldComponent,
    AppSelectComponent,
    AppUploadSingleComponent,
    AppDatePickerComponent,
    PaginationComponent,
    DialogScrollComponent,
    EscapeHtmlPipe
  ],
  declarations: [
    AppTextFieldComponent,
    AppAreaFieldComponent,
    AppNumberFieldComponent,
    AppSelectComponent,
    AppUploadSingleComponent,
    AppDatePickerComponent,
    PaginationComponent,
    DialogScrollComponent,
    EscapeHtmlPipe
  ],
  providers: [
   provideNgxMask(),
    {provide: MAT_DATE_LOCALE, useValue: 'en-EN'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: PICKER_DATE_FORMATS},
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppComponentModule {}
