import {Component, Input, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TextAreaDirective } from './area-field.directive';
import { injectNgControlArea } from './area-field.function';

@Component({
    selector: 'app-area-field',
    hostDirectives: [TextAreaDirective],
    templateUrl: 'area-field.component.html',
    styleUrls: ['area-field.scss']
  })
  export class AppAreaFieldComponent implements OnInit{
    ngControl = injectNgControlArea();
    @Input() label = '';
    @Input() rows = 1;
    @Input() cols = 10;
    @Input() readonly = false;
    @Input() disabling = false;

    ngOnInit(): void {
      if (this.disabling) {
        this.ngControl.control.disable();
      }
    }

  checkRequiredValidator(): string {
        const inputFieldControl = this.ngControl.control;
        if (inputFieldControl && inputFieldControl.validator) {
          const validators = inputFieldControl.validator({} as FormControl);
          if (validators && validators.required) {
            return 'required';
          } else {
            return null;
          }
        }
      }
  }
