import {Component, EventEmitter, Inject, Injector, OnInit} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {SupplierModel} from '../models/supplier.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SupplierService} from '../services/supplier.service';

@Component({
  selector: 'app-picker-supplier',
  templateUrl: './supplier.picker.html',
})
export class SupplierPicker extends BasePageComponentDirective<SupplierModel> implements OnInit{
  suppliers: SupplierModel[] = [];
  public result = new EventEmitter();
  selectedSupplier: SupplierModel;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<SupplierPicker>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private supplierService: SupplierService,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.query();
  }

  query(): void {
    this.pageQuery.pageSize = 5;
    this.supplierService.findAll(this.pageQuery).then((res) => {
      this.page.emit(res);
    });
  }

  choose(): void {
    this.result.emit(this.selectedSupplier);
    this.dialogRef.close();
  }
}
