import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from 'src/app/auth/auth-guard.service';
import {ItemPageComponent} from './item/pages/item.page';
import {ItemGroupPageComponent} from './item-group/pages/item-group.page';
import {ItemGroupReportComponent} from './item-group/pages/item-group.report';

const routes: Routes = [
  {path: 'item-group', component: ItemGroupPageComponent, canActivate: [AuthGuard]},
  {path: 'item-group/:id', component: ItemPageComponent, canActivate: [AuthGuard]},
  {path: 'report', component: ItemGroupReportComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ItemRoutesModule {
}
