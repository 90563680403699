import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MenuItemInterface} from '../../interfaces/menu-item.interface';
import {MENU_ITEMS} from '../../constants/menu.constant';
import {UiHelper} from '../../helpers/ui.helper';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['sidenav.scss']
})
export class SidenavItemComponent implements OnInit {
  @Input() isExpanded: boolean;
  @Output() clicked = new EventEmitter<boolean>();
  ui = UiHelper;
  public menu = MENU_ITEMS;
  public policies: MenuItemInterface[] = [];

  activeIdentifier: string;

  constructor(private router: Router) {
  }


  ngOnInit(): void {
    this.policies = this.menu;
  }
  externalLoad(url: string): void {
    this.clicked.emit(true);
    window.open(url, '_blank');
  }
  clickMenu(url: string): void {
    // this.clicked.emit(true);
    this.router.navigate([url]).then(() => {});
  }
  toggleExpansion(activeIdentifier) {
    this.activeIdentifier = activeIdentifier;
  }
}
