import { Component, Injector, OnInit } from '@angular/core';
import { BasePageComponentDirective } from '../../../shared/base/base-page.component';
import { ItemGroupModel } from '../../item/item-group/models/item-group.model';
import { ProductModel } from '../../master/product/models/product.model';
import { ProductSubModel } from '../../master/product-sub/models/product-sub.model';
import { SupplierModel } from '../../supplier/supplier/models/supplier.model';
import { environment } from '../../../../environments/environment';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { DashboardService } from '../services/dashboard.service';
import { StorageModel } from '../../master/storage/models/storage.model';
import { StorageService } from '../../master/storage/services/storage.service';
import { ProductService } from '../../master/product/services/product.service';

@Component({
  selector: 'app-page-critical-stock',
  templateUrl: './critical-stock.page.html',
})
export class CriticalStockPageComponent extends BasePageComponentDirective<ItemGroupModel> implements OnInit{
  products: ProductModel[] = [];
  subProducts: ProductSubModel[] = [];
  suppliers: SupplierModel[] = [];
  itemImageBaseURL = environment.storage + '/images/item-group/';
  storages: StorageModel[] = [];
  storageName = 1;
  productName = 1;
  storageId = 1;
  productId = 1;
  constructor(
    injector: Injector,
    private productService: ProductService,
    private dashboardService: DashboardService,
    private storageService: StorageService,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.storages = await this.storageService.findAllNoPaging();
    this.products = await this.productService.findAllNoPaging();
    this.query();

    this.breadcrumbService.changeMessage([
      {label: 'menu.dashboard.index', active: false, route: '/'},
      {label: 'pages.item.critical-stock', active: true}
    ]);
  }

  query(): void {
    this.dashboardService.getCriticalStock(this.pageQuery, this.storageId, this.productId).then((res) => {
      this.page.emit(res);
    });
  }

  detail(d: ItemGroupModel): void {
    this.router.navigate(['item-group/item-group/' + d.id]).then();
  }

  changeCriticalStockStorage(id: number): void {
    this.storageId = id;
    this.searchTerm = '';
    this.pageQuery.searchTerm = '';
    this.query();
  }

  changeCriticalStockProduct(id: number): void {
    this.productId = id;
    this.searchTerm = '';
    this.pageQuery.searchTerm = '';
    this.query();
  }

  printAll(): void {
    this.dashboardService.criticalStockPdf(this.storageId, this.productId).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'critical-stock' + '.pdf';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching image:', error);
    });
  }
}
