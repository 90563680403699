<div style="padding: 100px 0 40px 0; margin: auto; text-align: center; background-color: rgb(250, 250, 250)" class="bg-">
  <img src="assets/images/topbar/patala.png" class="main-logo" alt="ramerame.co.id" />
</div>

<div *ngFor="let m of policies">
  <mat-nav-list class="single-menu" *ngIf="!m.hasChildMenu">
    <mat-list-item class="mat-list-item">
      <p (click)="clickMenu(m.url)" *ngIf="!m.externalURL" matLine >{{ m.label | translate }}</p>
      <p *ngIf="m.externalURL" matLine (click)="externalLoad(m.url)">{{ m.label | translate }}</p>
    </mat-list-item>
  </mat-nav-list>

  <mat-accordion *ngIf="m.hasChildMenu">
    <mat-expansion-panel class="expand-menu" [id]="m.id" [expanded]="m.id == activeIdentifier" (click)="toggleExpansion(m.id)" >
      <mat-expansion-panel-header>
        <mat-panel-title>{{m.label | translate}}</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list *ngFor="let n of m.childMenu">
        <mat-list-item class="mat-list-item">
          <ul style="margin: 0; padding: 20px;">
            <li style="padding-bottom: 15px; list-style: circle" (click)="clickMenu(n.url)" *ngIf="!n.externalURL" matLine >{{ n.label | translate }}</li>
            <li style="padding-bottom: 15px; list-style: circle" *ngIf="n.externalURL" matLine (click)="externalLoad(n.url)">{{ n.label | translate }}</li>
          </ul>
        </mat-list-item>
      </mat-nav-list>
    </mat-expansion-panel>

  </mat-accordion>
</div>

