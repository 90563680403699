<div class="white-background p-5">
  <form [formGroup]="form" (submit)="save()">
    <h4 class="mat-dialog-title px-4" *ngIf="mode == 'create'">{{'pages.area-sub.area-sub-editor' | translate}}</h4>
    <h2 class="mat-dialog-title px-4" *ngIf="mode == 'edit' || mode == 'view'">{{'pages.area-sub.area-sub-editor' | translate}} #{{areaSub.id}}</h2>
    <mat-dialog-content style="width: 600px;">

      <div class="row">
        <div class="col-12">
          <app-text-field [readonly]="readonly" [label]="'pages.area-sub.name' | translate" formControlName="subAreaName"></app-text-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <app-area-field [readonly]="readonly" [label]="'pages.area-sub.description' | translate" formControlName="subAreaDescription" [rows]="10" [cols]="10"></app-area-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <app-upload-single
            [readonly]="readonly"
            [fileUrl]="fileUrl"
            [accept]="'image/*'"
            [label]="'pages.area-sub.image' | translate"
            [labelButton]="'pages.area-sub.image-button' | translate" (fileChange)="getFileChange($event)">

          </app-upload-single>
        </div>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-end pe-4">
      <button *ngIf="!viewMode" mat-raised-button class="py-1" color="primary">{{'common.save' | translate}}</button>
      <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
    </mat-dialog-actions>
  </form>
</div>
