import { Component, Injector, OnInit } from '@angular/core';
import { BasePageTableComponent } from '../../../../shared/base/base-page-table.component';
import { CustomerOrderModel } from '../models/customer-order.model';
import { ColumnType, PageInterface, PageQueryInterface } from '../../../../interfaces/page.interface';
import { CustomerOrderService } from '../services/customer-order.service';
import { MatDialog } from '@angular/material/dialog';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { XTableButton, XTableColumn, XTableComponent } from '../../../../shared/components/xtable/xtable.component';
import { DialogScrollComponent } from '../../../../shared/components/dialog/dialog-scroll.component';
import { AppComponentModule } from '../../../../shared/components/component.module';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-order-table-page',
  standalone: true,
  imports: [
    AppComponentModule,
    DatePipe,
    MatButton,
    MatIcon,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    XTableComponent,
  ],
  templateUrl: './customer-order-table-page.component.html',
  styles: ``,
})
export class CustomerOrderTablePageComponent extends BasePageTableComponent<CustomerOrderModel> implements OnInit {

  columns: XTableColumn<CustomerOrderModel>[] = [
    {
      name: 'invoiceNo',
      label: 'pages.customer.invoice-no',
      class: 'text-end',
      isSearchable: true,
      isFilterable: true,
      isSortable: true,
    },
    {
      name: 'orderDate',
      label: 'pages.customer.order-date',
      type: ColumnType.DATE,
      isFilterable: true,
      isSortable: true,
    },
    {
      name: 'deliveryDate',
      label: 'pages.customer.delivery-date',
      type: ColumnType.DATE,
      isFilterable: true,
      isSortable: true,
    },
    {
      name: 'customerName',
      label: 'pages.customer.customer-name',
      isSearchable: true,
      isFilterable: true,
      isSortable: true,
    },
    {
      name: 'status',
      label: 'pages.customer.status',
      lookup: {
        'proceed': 'PROCEED',
        'draft': 'DRAFT',
      },
      isSortable: true,
    },
    {
      name: 'deliveryStatus',
      label: 'pages.customer.delivery-status',
      isSortable: true,
    },
    {
      name: 'grandTotal',
      type: ColumnType.NUMBER,
      isFilterable: true,
      isSortable: true,
    },
  ];

  buttons: XTableButton<CustomerOrderModel>[] = [
    {
      name: 'view',
      icon: 'fullscreen',
      onClick: (d) => this.view(d),
      tooltip: 'View Order',
    },
    {
      name: 'edit',
      icon: 'edit_square',
      onClick: (d) => this.edit(d),
      tooltip: 'Edit Order',
    },
    {
      name: 'delete',
      icon: 'delete',
      onClick: (d) => this.delete(d),
      color: 'warn',
      tooltip: 'Delete Order',
    },
  ];

  constructor(
    injector: Injector,
    private customerOrderService: CustomerOrderService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.breadcrumbService.changeMessage([
      { label: 'pages.customer.customer', active: false },
      { label: 'pages.customer.customer-order', active: true, route: '' },
    ]);
  }

  requestData(query: PageQueryInterface): Promise<PageInterface<CustomerOrderModel>> {
    return this.customerOrderService.testXTable(query);
  }

  create(): void {
    this.router.navigate(['/customer/order/add-new/0']).then();
  }

  edit(d: CustomerOrderModel): void {
    this.router.navigate(['/customer/order/edit/' + d.id]).then();
  }

  view(d: CustomerOrderModel): void {
    this.router.navigate(['/customer/order/view/' + d.id]).then();
  }

  delete(d: CustomerOrderModel): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.invoiceNo}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-danger',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.delete'),
          },
          { value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close') },
        ],
      },
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.customerOrderService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000,
          });

          this.reload();
        });
      }
    });
  }

}
