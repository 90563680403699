import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {
  BACKOFFICE,
  CUSTOMER_ORDER_BASE_URL,
  ITEM_GROUP_BASE_URL,
  STOCK_BASE_URL,
} from '../../../constants/api.constant';
import {CustomerOrderModel} from '../../customer/customer-order/models/customer-order.model';
import { PageInterface, PageQueryInterface } from '../../../interfaces/page.interface';

@Injectable({providedIn: 'root'})
export class DashboardService {
  private baseUrl = environment.url + BACKOFFICE;
  constructor(public http: HttpClient) {

  }

  async getMonthlyCustomerOrderCount(month: number, year: number): Promise<CustomerOrderModel[]> {
    const url = this.baseUrl + CUSTOMER_ORDER_BASE_URL + `/dashboard/monthly/${month}/${year}`;
    return await this.http.get<CustomerOrderModel[]>(url).toPromise();
  }

  async getCustomerOrderCountByDate(date: string): Promise<any> {
    return await this.http.get(this.baseUrl + CUSTOMER_ORDER_BASE_URL + '/dashboard/daily/' + date).toPromise();
  }

  async getMonthlyPaidCustomerOrderCount(month: number, year: number): Promise<any> {
    const url = this.baseUrl + CUSTOMER_ORDER_BASE_URL + `/dashboard/monthly-paid/${month}/${year}`;
    return await this.http.get<any>(url).toPromise();
  }

  async getSumMonthlyPaidCustomerOrderCount(month: number, year: number): Promise<any> {
    const url = this.baseUrl + CUSTOMER_ORDER_BASE_URL + `/dashboard/monthly-paid-sum/${month}/${year}`;
    return await this.http.get<any>(url).toPromise();
  }

  async getCriticalStock(p: PageQueryInterface, storageId: number, productId: number): Promise<any> {
    productId = productId ? productId : 0;
    const url = this.baseUrl + STOCK_BASE_URL + `/dashboard/critical-stock/${storageId}/${productId}`;
    return await this.http.get<PageInterface>(url + `?page=${p.pageNo}&limit=${p.pageSize}&search-term=${p.searchTerm}`,
      {}).toPromise();
  }

  async criticalStockPdf(storageId: number, productId: number): Promise<any> {
    productId = productId ? productId : 0;
    const url = this.baseUrl + STOCK_BASE_URL + `/report/critical-stock/${storageId}/${productId}`;
    return await this.http.get<any>(url, {
      responseType: 'blob' as 'json'
    }).toPromise();
  }
}
