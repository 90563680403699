<div class="main-content bg-black full-height-page sign-in">
  <div class="logo">
    <img src="./assets/images/topbar/patala.png?rand=123" alt="patala-stock.com" class="logo">
  </div>
  <div class="box">
    <h2>{{'pages.login.sign-in-to-account' | translate}}</h2>
    <form [formGroup]="loginFG" (submit)="signIn()">
      <div class="row">
        <div class="col-md-12">
          <div [ngClass]="{'has-error':(errorPayload.length > 0 && errorPayload[0].input == 'email'), 'form-group': true}">
            <label for="email">{{'pages.login.username' | translate}}</label>
            <div class="input-group mb-1">
              <input type="email" id="email" formControlName="usernameFC" class="form-control" placeholder="username">
            </div>
            <small class="form-text" *ngIf="errorPayload.length > 0 && errorPayload[0].input == 'email'">{{errorPayload[0].message | translate}}</small>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div [ngClass]="{'has-error':(errorPayload.length > 0 && errorPayload[0].input == 'password'), 'form-group': true}" id="input-group-password">
            <label for="password">{{'pages.login.password' | translate}}</label>
            <div class="input-group mb-1">
              <input type="password" *ngIf="!togglePassword" id="password" formControlName="passwordFC" class="form-control" placeholder="{{'pages.login.password' | translate}}">
              <input type="text" *ngIf="togglePassword" formControlName="passwordFC" class="form-control" placeholder="{{'pages.login.password' | translate}}">
              <div class="input-group-append">
                <span *ngIf="!togglePassword" class="input-group-text" id="suffix_password_show" (click)="togglePassword = true">
                    <span class="icon-eye"></span>
                </span>
                <span *ngIf="togglePassword" class="input-group-text" id="suffix_password_hide" (click)="togglePassword = false">
                  <span class="icon-eye-blocked"></span>
                </span>
              </div>
            </div>
            <small class="form-text" *ngIf="errorPayload.length > 0 && errorPayload[0].input == 'password'">{{errorPayload[0].message | translate}}</small>
          </div>
        </div>
      </div>
      <div class="row margin-top-14px">
        <div class="col-md-12">
          <div class="d-grid">
            <button color="primary" mat-flat-button class="btn mt-2 primary-button big-button" [disabled]="loginFG.status != flagValidForm">{{'pages.login.sign-in' | translate}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
