import {Component, Injector, OnInit} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {MatDialog} from '@angular/material/dialog';
import {BreadcrumbService} from '../../../../services/breadcrumb.service';
import {StockAuditService} from '../services/stock-audit.service';
import {StockAuditModel} from '../models/stock-audit.model';
import {CustomerModel} from '../../../customer/customer/models/customer.model';
import {DialogScrollComponent} from '../../../../shared/components/dialog/dialog-scroll.component';
import {StoragePicker} from '../../../master/storage/modals/storage.picker';
import {StockAuditMasterModel} from '../models/stock-audit-list.model';

@Component({
  selector: 'app-page-storage-audit',
  templateUrl: './storage-audit.page.html',
})
export class StorageAuditPageComponent extends BasePageComponentDirective<StockAuditModel> implements OnInit {

  constructor(
    injector: Injector,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private auditService: StockAuditService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.query();

    this.breadcrumbService.changeMessage([
      {label: 'menu.master.index', active: false},
      {label: 'pages.audit.storage-audit', active: true, route: ''}
    ]);
  }

  query(): void {
    this.auditService.findAll(this.pageQuery).then(async (res) => {
      this.page.emit(res);
    });
  }

  create(): void {
    const dialog = this.dialog.open(StoragePicker, {});
    dialog.componentInstance.result.subscribe(async r => {
      this.router.navigate(['/audit/storage/add-new/' + r.id]).then();
    });
  }

  edit(d: StockAuditMasterModel): void {
    this.router.navigate(['/audit/storage/edit/' + d.id]).then();
  }

  view(d: CustomerModel): void {
    //
  }

  delete(d: CustomerModel): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.customerName}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-danger',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.delete')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.auditService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }
}
