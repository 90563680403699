import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from '../../material/material.module';
import {AppRoutingModule} from '../../app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgProgressModule} from 'ngx-progressbar';
import {AppSharedModule} from '../../shared/shared.module';
import {LoginService} from '../../services/login.service';
import { NewLoginPageComponent } from './new-login.page';
import { MailSentPageComponent } from './mail-sent.page';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule,
  ],
  exports: [
    NewLoginPageComponent,
    MailSentPageComponent,
  ],
  declarations: [
    NewLoginPageComponent,
    MailSentPageComponent,
  ],
  providers: [
    LoginService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppLoginModule {}
