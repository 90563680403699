import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgProgressModule } from 'ngx-progressbar';
import { MaterialModule } from 'src/app/material/material.module';
import { AppComponentModule } from 'src/app/shared/components/component.module';
import { AppSharedModule } from 'src/app/shared/shared.module';
import { SupplierPageComponent } from './supplier/pages/supplier.page';
import { SupplierRoutesModule } from './supplier-routes.module';
import { SupplierModal } from './supplier/modals/supplier.modal';
import {SupplierOrderPageComponent} from './supplier-order/pages/supplier-order.page';
import {SupplierOrderModal} from './supplier-order/modals/supplier-order.modal';
import {SupplierOrderFormPageComponent} from './supplier-order/pages/supplier-order-form.page';
import {SupplierOrderReceivingPageComponent} from './supplier-order-receiving/pages/supplier-order-receiving.page';
import {
    SupplierOrderReceivingFormPageComponent
} from './supplier-order-receiving/pages/supplier-order-receiving-form.page';
import {SupplierItemPageComponent} from './supplier-item/pages/supplier-item.page';
import {SupplierItemModal} from './supplier-item/modals/supplier-item.modal';
import {AppItemModule} from "../item/item.module";
import {SupplierPicker} from "./supplier/modals/supplier.picker";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule,
    SupplierRoutesModule,
    NgSelectModule,
    AppComponentModule,
    AppItemModule
  ],
  exports: [
    SupplierPageComponent,
    SupplierModal,
    SupplierOrderPageComponent,
    SupplierOrderModal,
    SupplierOrderFormPageComponent,
    SupplierOrderReceivingPageComponent,
    SupplierOrderReceivingFormPageComponent,
    SupplierItemPageComponent,
    SupplierItemModal,
    SupplierPicker,
  ],
  declarations: [
    SupplierPageComponent,
    SupplierModal,
    SupplierOrderPageComponent,
    SupplierOrderModal,
    SupplierOrderFormPageComponent,
    SupplierOrderReceivingPageComponent,
    SupplierOrderReceivingFormPageComponent,
    SupplierItemPageComponent,
    SupplierItemModal,
    SupplierPicker
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppSupplierModule {
}
