import {Component, EventEmitter, Inject, Injector, OnInit} from '@angular/core';
import {BasePageComponentDirective} from '../../../../shared/base/base-page.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {StorageModel} from '../models/storage.model';
import {AreaModel} from '../../area/models/area.model';
import {StorageService} from '../services/storage.service';
import {AreaService} from '../../area/services/area.service';

@Component({
  selector: 'app-picker-storage',
  templateUrl: './storage.picker.html',
})
export class StoragePicker extends BasePageComponentDirective<StorageModel> implements OnInit{
  areas: AreaModel[] = [];
  storages: StorageModel[] = [];
  public result = new EventEmitter();
  selectedItem: StorageModel;
  itemImageBaseURL = environment.storage + '/images/item-group/';

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<StoragePicker>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storageService: StorageService,
    private areaService: AreaService,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.areas = await this.areaService.findAllNoPaging();
    this.query();
  }

  query(): void {
    this.pageQuery.pageSize = 5;
    this.storageService.findAll(this.pageQuery).then((res) => {
      this.page.emit(res);
    });
  }

  choose(): void {
    this.result.emit(this.selectedItem);
    this.dialogRef.close();
  }
}
