import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ItemGroupModel} from '../models/item-group.model';

@Injectable({
  providedIn: 'root'
})
export class SelectedItemGroupService {
  private selectedItemGroupsSubject: BehaviorSubject<ItemGroupModel[]> = new BehaviorSubject<ItemGroupModel[]>([]);
  public selectedItemGroups$: Observable<ItemGroupModel[]> = this.selectedItemGroupsSubject.asObservable();
  checkedItemGroups: Map<number, ItemGroupModel> = new Map<number, ItemGroupModel>();

  constructor() { }

  setSelectedItemGroups(itemGroups: ItemGroupModel[]): void {
    this.selectedItemGroupsSubject.next(itemGroups);
  }

  getSelectedItemGroups(): ItemGroupModel[] {
    return this.selectedItemGroupsSubject.value;
  }

  toggleItemGroup(itemGroup: ItemGroupModel): void {
    if (this.checkedItemGroups.has(itemGroup.id)) {
      this.checkedItemGroups.delete(itemGroup.id);
    } else {
      this.checkedItemGroups.set(itemGroup.id, itemGroup);
    }
  }
}
