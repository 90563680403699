import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AUTH_BASE_URL, AUTH_PROFILE_BASE_URL} from '../constants/api.constant';
import {TokenInterface} from '../interfaces/token.interface';
import {UserInterface} from '../interfaces/user.interface';
import { ChangePasswordInterface } from '../interfaces/profile.interface';

@Injectable({providedIn: 'root'})
export class LoginService {
  constructor(private http: HttpClient) {
  }
  async verify(username: string, password: string): Promise<TokenInterface> {
    const payload = {username, password};
    return await this.http.post<TokenInterface>(environment.url + AUTH_BASE_URL, payload , {}).toPromise();
  }
  async getProfile(): Promise<UserInterface> {
    return await this.http.get(environment.url + AUTH_PROFILE_BASE_URL).toPromise();
  }
  async changePassword(payload: ChangePasswordInterface): Promise<any> {
    return await this.http.put<any>(environment.url + '/backoffice/profile/change-password', payload, {}).toPromise();
  }
}
