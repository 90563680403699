<div class="main-content homepage grey-background">
    <div class="container-fluid">
      <div class="mat-card m- white-background text-black-50 rounded-lg">
        <div class="mat-card-title px-3 py-3">
          <h3 class="pl-2">{{'pages.item-group.item-group' | translate}}</h3>
        </div>
        <div class="mat-card-content px-3 rounded">
          <div class="row">
            <div class="col-3">
              <div class="form-group">
                <label>{{'common.search' | translate}}</label>
                <div class="input-group mb-3">
                  <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control" aria-label="search">
                  <div class="input-group-append">
                    <span class="input-group-text" id="suffix_password_show">
                        <span class="icon-search"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3">
              <app-select (valueChange)="getChanged('product', $event)"
                          [items]="products"
                          [bindLabel]="'productName'"
                          [bindValue]="'id'"
                          [label]="'pages.supplier.stock-status' | translate" [(ngModel)]="product"></app-select>
            </div>
            <div class="col-3">
              <app-select (valueChange)="getChanged('subProduct', $event)"
                          [items]="subProducts"
                          [bindLabel]="'subProductName'"
                          [bindValue]="'id'"
                          [label]="'pages.supplier.stock-status' | translate" [(ngModel)]="subProduct"></app-select>
            </div>
            <div class="col-3 pt-3 text-end">
              <button (click)="create()" mat-flat-button color="primary" class="text-white">{{'common.create' | translate}}</button>
            </div>
          </div>
        </div>
        <div class="mat-card-content px-3 pb-5">
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th class="text-nowrap text-center">{{'pages.item-group.image' | translate}}</th>
                  <th class="text-nowrap">{{'pages.item-group.item-group-code' | translate}}</th>
                  <th class="text-nowrap">{{'pages.item-group.item-group-name' | translate}}</th>
                  <th class="text-nowrap">{{'pages.item.unit' | translate}}</th>
                  <th class="text-nowrap">{{'pages.product.product-name' | translate}}</th>
                  <th class="text-nowrap">{{'pages.product-sub.name' | translate}}</th>
                  <th class="text-nowrap">{{'pages.item.standard-price' | translate}}</th>
                  <th class="text-nowrap">{{'pages.item.buy-price' | translate}}</th>
                  <th class="sticky-column text-center">{{'common.action' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let itemGroup of pageItems">
                  <td class="text-nowrap text-center">
                    <img alt="" class="image-round-multiply" style="width: 80px; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;" [src]="itemImageBaseURL + itemGroup.imageName" />
                  </td>
                  <td class="text-nowrap" style="">{{itemGroup.itemGroupCode}}</td>
                  <td class="text-nowrap" style="">{{itemGroup.itemGroupName}}</td>
                  <td class="text-nowrap" style="">{{itemGroup.unit}}</td>
                  <td class="text-nowrap" style="">{{maskingColumn('productName', 'id', products, itemGroup.productId)}}</td>
                  <td class="text-nowrap" style="">{{maskingColumn('subProductName', 'id', subProducts, itemGroup.subProductId)}}</td>
                  <td class="text-nowrap text-end" style="">{{(itemGroup.standardPrice ? itemGroup.standardPrice : 0) | number: '1.2-2'}}</td>
                  <td class="text-nowrap text-end" style="">{{(itemGroup.buyPrice ? itemGroup.buyPrice : 0) | number: '1.2-2'}}</td>
                  <td class="sticky-column" style="">
                    <div class="btn-group" role="group" aria-label="Button Action">
                      <button (click)="view(itemGroup)" type="button" mat-button color="primary">
                        <mat-icon>fullscreen</mat-icon>
                      </button>
                      <button (click)="edit(itemGroup)" mat-button class="text-orange" color="primary">
                        <mat-icon>edit_square</mat-icon>
                      </button>
                      <button (click)="delete(itemGroup)" type="button"  mat-button color="warn">
                        <mat-icon>delete</mat-icon>
                      </button>
                      <button (click)="detail(itemGroup)" type="button"  mat-button color="primary">
                        <mat-icon>filter_none</mat-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="mat-card-content">
          <div class="row mt-4 mb-4">
            <div class="col-12 text-center">
              <app-pagination
                (goPage)="toPage($event)"
                [pagesToShow]="showPages"
                [page]="pageNo"
                [perPage]="pageSize"
                [count]="totalRow"></app-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
