<div class="dropzone" *ngIf="!readonly">
    <div class="img-preview">
      <img *ngIf="!fileUrl" class="img-fluid" src="assets/images/others/image-placeholder@2x.png" alt="image placeholder" />
      <img *ngIf="fileUrl" class="img-fluid" [src]="fileUrl" alt="preview image" />


    </div>
    <caption>{{label}}</caption><span *ngIf="required"> *</span>
    <caption>{{(file)? file.name : ''}}</caption>
    <input type="file" style="display: none;" accept="{{accept}}" #hiddenSelfie (change)="onFileChange($event)" />
    <button [disabled]="showLoader" type="button" (click)="hiddenSelfie.click()" class="btn btn-primary inline">{{labelButton}}</button>
</div>
<div class="dropzone" *ngIf="readonly">
  <div class="img-preview">
    <img *ngIf="fileUrl" class="img-fluid" [src]="fileUrl" alt="preview image" />
  </div>
</div>
