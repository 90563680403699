<div class="white-background p-5">
  <form (submit)="save()" [formGroup]="form">
    <h4 *ngIf="mode == 'create'" class="mat-dialog-title px-4">{{ 'pages.item-group.editor' | translate }}</h4>
    <h2 *ngIf="mode == 'edit' || mode == 'view'"
        class="mat-dialog-title px-4">{{ 'pages.item-group.editor' | translate }} #{{ itemGroup.id }}</h2>
    <mat-dialog-content style="width: 600px;">

      <div class="row">
        <div class="col-12">
          <app-text-field [disabling]="true"
                          [label]="'pages.item-group.item-group-code' | translate"
                          formControlName="itemGroupCode">
          </app-text-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <app-text-field (valueChange)="generateItemGroupCode()"
                          [label]="'pages.item-group.item-group-name' | translate"
                          [readonly]="readonly"
                          formControlName="itemGroupName">
          </app-text-field>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <app-select [bindLabel]="'productName'"
                      [bindValue]="'id'"
                      [items]="products"
                      [label]="'pages.product.product' | translate"
                      [readonly]="readonly"
                      formControlName="productId">
          </app-select>
        </div>
        <div class="col-6">
          <app-select (valueChange)="generateItemGroupCode()"
                      [bindLabel]="'subProductName'"
                      [bindValue]="'id'"
                      [items]="subProducts"
                      [label]="'pages.product-sub.product-sub' | translate"
                      [readonly]="readonly"
                      formControlName="subProductId">
          </app-select>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <app-select [bindLabel]="'value'"
                      [bindValue]="'key'"
                      [items]="units"
                      [label]="'pages.item.unit' | translate"
                      [readonly]="readonly"
                      formControlName="unit">
          </app-select>
        </div>
        <div class="col-6">
          <app-select [bindLabel]="'label'"
                      [bindValue]="'value'"
                      [items]="priorities"
                      [label]="'pages.item-group.priority' | translate"
                      [readonly]="readonly"
                      formControlName="priority">
          </app-select>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <app-select *ngIf="this.mode == 'edit'"
                      [bindLabel]="'supplierName'"
                      [bindValue]="'id'"
                      [items]="suppliers"
                      [label]="'pages.supplier.supplier-name' | translate"
                      [readonly]="true"
                      formControlName="supplierId">
          </app-select>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <app-number-field [label]="'pages.item.buy-price' | translate"
                            [readonly]="true"
                            formControlName="buyPrice">
          </app-number-field>
        </div>
        <div class="col-6">
          <app-number-field [label]="'pages.item.standard-price' | translate"
                            [readonly]="true"
                            formControlName="standardPrice">
          </app-number-field>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <app-area-field [cols]="10"
                          [label]="'pages.item-group.description' | translate"
                          [readonly]="readonly"
                          [rows]="10"
                          formControlName="description">
          </app-area-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <app-upload-single
            (fileChange)="getFileChange($event)"
            [accept]="'image/*'"
            [fileUrl]="fileUrl"
            [labelButton]="'pages.item-group.image-button' | translate"
            [label]="'pages.item-group.image' | translate" [readonly]="readonly">

          </app-upload-single>
        </div>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-end pe-4">
      <button *ngIf="!viewMode"
              class="py-1"
              color="primary"
              mat-raised-button>{{ 'common.save' | translate }}
      </button>

      <button class="py-1 white-background text-black-50"
              mat-dialog-close
              mat-stroked-button
              type="button">{{ 'common.close' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
