<div class="container-fluid">
    <div class="mat-card m- white-background text-black-50 rounded-lg">
        <div class="mat-card-title px-2 py-3">
            <h3 class="pl-2 text-capitalize">{{mode}} {{'pages.supplier.order-receiving' | translate}}</h3>
        </div>

        <div class="mat-card-content px-3 pb-5">
            <form [formGroup]="form" (submit)="save()">
                <div class="row">
                  <div class="col-4">
                    <app-datepicker formControlName="orderDate" [label]="'pages.supplier.order-date' | translate"></app-datepicker>
                  </div>
                  <div class="col-4">
                    <app-text-field formControlName="internalInvoiceNo" [readonly]="true" [label]="'pages.supplier.internal-invoice-no' | translate"></app-text-field>
                  </div>
                </div>
                <mat-accordion class="example-headers-align" multi >
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{'pages.supplier.supplier-info' | translate}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row mt-3">
                      <div class="col-4">
                        <app-text-field [readonly]="true"
                                        [label]="'pages.supplier.supplier-name' | translate"
                                        formControlName="supplierName"></app-text-field>
                      </div>
                      <div class="col-8">
                        <app-text-field [readonly]="true"
                                        [label]="'pages.supplier.address' | translate"
                                        formControlName="address"></app-text-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4"></div>
                      <div class="col-4">
                        <app-text-field [readonly]="true"
                                        [label]="'pages.supplier.contact-person' | translate"
                                        formControlName="contactPerson"></app-text-field>
                      </div>

                      <div class="col-4">
                        <app-text-field [readonly]="true"
                                        [label]="'pages.supplier.phone-number' | translate"
                                        formControlName="phone"></app-text-field>
                      </div>

                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{'pages.supplier.order-info' | translate}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row mt-3">
                      <div class="col-4">
                        <app-select [readonly]="readonly"
                                    [items]="statuses"
                                    [bindLabel]="'key'"
                                    [bindValue]="'value'"
                                    [label]="'pages.supplier.status' | translate" formControlName="status"></app-select>
                      </div>
                      <div class="col-4">
                        <app-select [readonly]="readonly" *ngIf="mode !== 'create'"
                                    [items]="stockStatuses"
                                    [bindLabel]="'key'"
                                    [bindValue]="'value'"
                                    [label]="'pages.supplier.stock-status' | translate" formControlName="stockStatus"></app-select>
                      </div>
                      <div class="col-4">
                        <app-text-field formControlName="supplierInvoiceNo" [readonly]="false" [label]="'pages.supplier.supplier-invoice-no' | translate"></app-text-field>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-4">
                        <app-datepicker formControlName="receivedDate" [label]="'pages.supplier.received-date' | translate"></app-datepicker>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4 pt-5">
                        <app-upload-single
                          [readonly]="readonly"
                          [fileUrl]="fileUrl"
                          [accept]="'image/*'"
                          [label]="'pages.supplier.invoice-evidence' | translate"
                          [labelButton]="'pages.supplier.image-button' | translate" (fileChange)="getFileChange($event)">

                        </app-upload-single>
                      </div>
                      <div class="col-4 pt-3">
                        <app-area-field formControlName="note" [rows]="5" [label]="'pages.supplier.note' | translate"></app-area-field>
                      </div>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{'pages.supplier.order-details' | translate}}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="table-responsive">
                          <table class="table table-striped table-hover">
                            <thead>
                            <tr>
                              <th class="text-nowrap text-end">{{'pages.supplier.item-id' | translate}}</th>
                              <th class="text-nowrap">{{'pages.supplier.item-name' | translate}}</th>
                              <th class="text-nowrap">{{'pages.supplier.unit' | translate}}</th>
                              <th class="text-nowrap text-end">{{'pages.supplier.current-stock' | translate}}</th>
                              <th class="text-nowrap text-end">{{'pages.supplier.buy-price' | translate}}</th>
                              <th class="text-nowrap text-end">{{'pages.supplier.new-buy-price' | translate}}</th>
                              <th class="text-nowrap text-end">{{'pages.supplier.order-quantity' | translate}}</th>
                              <th class="text-nowrap text-end">{{'pages.supplier.received-quantity' | translate}}</th>
                              <th class="text-nowrap text-end">{{'pages.supplier.item-discount' | translate}}</th>
                              <th class="text-nowrap text-end">{{'pages.supplier.subtotal' | translate}}</th>
                              <th class="text-nowrap text-center">{{'pages.supplier.checked' | translate}}</th>
                              <th class="text-nowrap">{{'pages.supplier.remark' | translate}}</th>
                              <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let d of orderDetails; let i = index;">
                              <td class="text-nowrap text-end">{{d.itemGroupId}}</td>
                              <td class="text-nowrap">{{d.itemGroupName}}</td>
                              <td class="text-nowrap">{{d.unit}}</td>
                              <td class="text-nowrap text-end">{{d.currentStock | number: '1.2-2'}}</td>
                              <td class="text-nowrap text-end">{{d.lastBuyPrice | number: '1.2-2'}}</td>
                              <td class="text-nowrap text-end" style="width: 200px;">
                                <app-number-field
                                  [(ngModel)]="d.buyPrice"
                                  [ngModelOptions]="{standalone: true}"
                                  (valueChange)="changeTotal('buyPrice', i, $event)"
                                ></app-number-field>
                              </td>
                              <td class="text-nowrap text-end">{{d.quantity | number: '1.2-2'}}</td>
                              <td class="text-nowrap text-end">
                                <app-number-field
                                  [(ngModel)]="d.realQuantity"
                                  [ngModelOptions]="{standalone: true}"
                                  (valueChange)="changeTotal('realQuantity', i, $event)"
                                ></app-number-field>
                              </td>
                              <td class="text-nowrap text-end">
                                <app-number-field
                                  [(ngModel)]="d.itemDiscount"
                                  [ngModelOptions]="{standalone: true}"
                                  (valueChange)="changeTotal('itemDiscount', i, $event)"
                                ></app-number-field>
                              </td>
                              <td class="text-nowrap text-end" style="width: 200px !important;">{{d.subtotal | number: '1.2-2'}}</td>
                              <td class="text-nowrap text-center">
                                <mat-checkbox (change)="checkQtyAndPrice($event, d)"
                                              [(ngModel)]="d.checked"
                                              [ngModelOptions]="{standalone: true}"
                                ></mat-checkbox>
                              </td>
                              <td class="text-nowrap" style="width: 300px;">
                                <app-text-field
                                  [(ngModel)]="d.remarkOnReceiving"
                                  [ngModelOptions]="{standalone: true}"
                                ></app-text-field>
                              </td>
                              <td>
                                <mat-icon color="primary" *ngIf="ui.moneyFormatToNumber(d.realQuantity) === ui.moneyFormatToNumber(d.quantity) && ui.moneyFormatToNumber(d.buyPrice) === ui.moneyFormatToNumber(d.lastBuyPrice); else notRight">check_circle</mat-icon>
                                <ng-template #notRight>
                                  <mat-icon color="accent">do_not_disturb</mat-icon>
                                </ng-template>

                              </td>
                            </tr>
                            </tbody>
                            <tfoot>

                            <tr class="border-0">
                              <td colspan="9" class="text-end border-0" style=";"><b>{{'pages.supplier.tax' | translate}}</b></td>
                              <td class="text-end border-0">
                                <app-number-field formControlName="tax" ></app-number-field>
                              </td>
                            </tr>
                            <tr class="border-0">
                              <td colspan="9" class="text-end border-0" style=";"><b>{{'pages.supplier.extra-expense' | translate}}</b></td>
                              <td class="text-end border-0">
                                <app-number-field formControlName="otherAddition" ></app-number-field>
                              </td>
                            </tr>
                            <tr class="border-0">
                              <td colspan="9" class="text-end border-0" style=";"><b>{{'pages.supplier.other-discount' | translate}}</b></td>
                              <td class="text-end border-0">
                                <app-number-field formControlName="otherSubtraction" ></app-number-field>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="9" class="text-end"><b>Grand Total</b></td>
                              <td class="text-end"><b>{{total | number: '1.2-2'}}</b></td>
                              <td colspan="4"></td>
                            </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <div class="row mt-3">
                    <div class="col-12 text-end justify-content-end">
                        <button
                                type="button"
                                mat-stroked-button
                                class="bg-info text-white me-2"
                                (click)="downloadInvoice()"
                        >{{'common.print' | translate}}</button>
                        <button
                                mat-raised-button
                                color="primary"
                                class="me-2">{{'common.save' | translate}}</button>
                        <button
                                type="button"
                                (click)="cancel()"
                                mat-stroked-button
                                color="warn"
                                class="">{{'common.cancel' | translate}}</button>
                    </div>
                </div>
            </form>

        </div>

    </div>
</div>
