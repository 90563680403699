export const ERROR_INVALID_CREDENTIAL = 'error.invalid-credential';
export const ERROR_EMAIL_NOT_FOUND = 'error.email-not-found';
export const ERROR_COULD_NOT_CREATE_TOKEN = 'error.could-not-create-token';
export const ERROR_UNKNOWN_OR_NETWORK = 'error.unknown-or-network';
export const ERROR_TERM_CONDITION_NOT_ACCEPTED = 'error.term-condition-not-accepted';
export const ERROR_INVALID_VERIFY_TOKEN = 'error.invalid-verify-token';
export const ERROR_INVALID_EMAIL_ADDRESS = 'error.invalid-email-address';
export const ERROR_VALIDATION = 'error.error_validation';
export const ERROR_MINIMUM_WITHDRAW = 'error.default-withdraw-error';
export const ERROR_BANK_ACCOUNT_NOT_VERIFIED = 'error.bank-not-verified';
export const ERROR_BANK_ACCOUNT_NOT_FOUND = 'error.bank-not-found';
export const ERROR_WITHDRAW_AMOUNT_LESS_THAN_HUNDRED_THOUSAND = 'error.amount-lt-thousand';
export const ERROR_WITHDRAW_AMOUNT_GREATER_THAN_BALANCE = 'error.amount-gt-balance';
export const ERROR_WALLET_NOT_FOUND = 'error.wallet-not-found';
export const ERROR_INCOMPLETE_INPUT = 'error.incomplete-input';
export const ERROR_OLD_NEW_SIMILAR = 'error.old-new-password-similar';
export const ERROR_INVALID_INPUT = 'error.invalid-input';
export const ERROR_PASSWORD_NOT_MATCH = 'error.password-not-match';
