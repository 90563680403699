import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BreadcrumbInterface} from '../interfaces/breadcrumb.interface';

@Injectable({providedIn: 'root'})
export class BreadcrumbService {

  private messageSource = new BehaviorSubject<BreadcrumbInterface[]>([]);
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: BreadcrumbInterface[]): void {
    this.messageSource.next(message);
  }

}
