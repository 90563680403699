<div class="form-group">
    <label>{{label}}<span *ngIf="checkRequiredValidator() == 'required'"> *</span></label>
    <div class="input-group">
        <input
                type="text"
                matInput [matDatepicker]="picker"
                class="form-control"
                [readOnly]="readonly"
                [formControl]="ngControl.control"
                [class]="ngControl.control.dirty && ngControl.control.errors? 'is-invalid': ''"
        >
        <div class="input-group-append" (click)="picker.open()">
            <span class="input-group-text" id="basic-addon2">
                <i class="icon-date"></i>
            </span>
        </div>
        <mat-datepicker #picker></mat-datepicker>
    </div>
    <div class="invalid-feedback" *ngIf="ngControl.control.invalid && ngControl.control.errors">{{'error.input-required' | translate}}</div>
</div>
