<div class="white-background p-5">
    <form [formGroup]="form" (submit)="save()">
      <h4 class="mat-dialog-title px-4" *ngIf="mode == 'create'">{{'pages.item.item-editor' | translate}}</h4>
      <h2 class="mat-dialog-title px-4" *ngIf="mode == 'edit' || mode == 'view'">{{'pages.item.item-editor' | translate}} #{{item.id}}</h2>
      <mat-dialog-content style="width: 600px;">

        <div class="row">
          <div class="col-12">
            <app-text-field
              [readonly]="readonly"
              [label]="'pages.item.name' | translate"
              formControlName="itemName"></app-text-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <app-text-field
              [readonly]="readonly"
              [label]="'pages.product.product' | translate"
              formControlName="productId" ></app-text-field>
          </div>
          <div class="col-6">
            <app-text-field [readonly]="readonly" [label]="'pages.product-sub.product-sub' | translate" formControlName="subProductId" ></app-text-field>
          </div>
        </div>

        <div class="row">
            <div class="col-6">
                <app-text-field [readonly]="readonly" [label]="'pages.item.sku' | translate" formControlName="sku"></app-text-field>
            </div>
            <div class="col-6">
                <app-select [readonly]="readonly" [items]="units" [bindLabel]="'key'" [bindValue]="'value'" [label]="'pages.item.unit' | translate" formControlName="unit"></app-select>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <app-select [readonly]="readonly" [items]="margins" [bindLabel]="'marginName'" [bindValue]="'id'" [label]="'pages.item.margin' | translate" formControlName="marginId"></app-select>
            </div>
            <div class="col-6">
                <app-select [readonly]="readonly" [items]="areas" [bindLabel]="'areaName'" [bindValue]="'id'" [label]="'pages.item.area' | translate" formControlName="areaId"></app-select>
            </div>

        </div>




        <div class="row">
          <div class="col-6">
            <app-select [readonly]="readonly" [items]="storages" [bindLabel]="'storageName'" [bindValue]="'id'" [label]="'pages.item.storage' | translate" formControlName="storageId"></app-select>
           </div>
            <div class="col-6">

            </div>
        </div>


        <div class="row">
          <div class="col-6">
            <app-number-field [readonly]="readonly" [label]="'pages.item.margin-percentage' | translate" formControlName="marginPercentage" ></app-number-field>
          </div>
          <div class="col-6">
              <app-number-field [readonly]="readonly" [label]="'pages.item.buy-price' | translate" formControlName="buyPrice" ></app-number-field>
          </div>

      </div>


        <div class="row">
            <div class="col-6">
                <app-number-field [readonly]="true" [label]="'pages.item.standard-price' | translate" formControlName="standardPrice" ></app-number-field>
            </div>
            <div class="col-6">
                <app-number-field [readonly]="true" [label]="'pages.item.sell-price' | translate" formControlName="sellPrice" ></app-number-field>
              </div>
        </div>


        <div class="row">
          <div class="col-6">
            <app-number-field [readonly]="readonly" [label]="'pages.item.current-stock' | translate" formControlName="currentStock" ></app-number-field>
          </div>
          <div class="col-6">
            <app-number-field [readonly]="readonly" [label]="'pages.item.remind-stock' | translate" formControlName="remindStockWhen" ></app-number-field>
          </div>
        </div>

      </mat-dialog-content>
      <mat-dialog-actions class="justify-content-end pe-4">
        <button *ngIf="!viewMode" mat-raised-button class="py-1" color="primary">{{'common.save' | translate}}</button>
        <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
      </mat-dialog-actions>
    </form>
  </div>
