import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppSharedModule} from '../shared/shared.module';
import {NgProgressModule} from 'ngx-progressbar';
import {AppLoginModule} from './login/login.module';
import {AppDashboardModule} from './dashboard/dashboard.module';
import {AppMasterModule} from './master/master.module';
import { AppSupplierModule } from './supplier/supplier.module';
import { AppCustomerModule } from './customer/customer.module';
import { AppAuditModule } from './audit/audit.module';
@NgModule({
  imports: [
    CommonModule,
    NgProgressModule,
    AppSharedModule,
    AppLoginModule,
    AppDashboardModule,
    AppMasterModule,
    AppSupplierModule,
    AppCustomerModule,
    AppAuditModule,
  ],
  exports: [
  ],
  declarations: [
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppPageModule {}
