<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card m- white-background text-black-50 rounded-lg">
      <div class="mat-card-title px-2 py-3">
        <h3 class="pl-2 text-capitalize">{{mode}} {{'pages.customer.customer-order' | translate}}</h3>
      </div>

      <div class="mat-card-content px-3 pb-5">
        <form [formGroup]="form" (submit)="save()">
          <div class="row">
            <div class="col-4">
              <app-select [readonly]="readonly"
                          [items]="customers"
                          [bindLabel]="'customerName'"
                          [bindValue]="'id'"
                          [label]="'pages.customer.customer-name' | translate" formControlName="customerId"></app-select>
            </div>
            <div class="col-3">
              <app-text-field
                formControlName="address"
                [readonly]="true"
                [label]="'pages.customer.address' | translate"></app-text-field>
            </div>
            <div class="col-3">
              <app-text-field
                formControlName="contactPerson"
                [readonly]="true"
                [label]="'pages.customer.contact-person' | translate"></app-text-field>
            </div>
            <div class="col-2">
              <app-text-field
                formControlName="phoneNumbers"
                [readonly]="true"
                [label]="'pages.customer.phone-number' | translate"></app-text-field>
            </div>

          </div>
          <div class="row mt-3">
            <div class="col-4">
              <app-select [readonly]="readonly"
                          [items]="directorates"
                          [bindLabel]="'key'"
                          [bindValue]="'value'"
                          [label]="'pages.customer.directorate' | translate" formControlName="directorate"></app-select>
            </div>
            <div class="col-3">
              <app-text-field
                formControlName="areaName"
                [readonly]="true"
                [label]="'pages.customer.area-name' | translate"></app-text-field>
            </div>
            <div class="col-3">
              <app-text-field
                formControlName="subAreaName"
                [readonly]="true"
                [label]="'pages.customer.sub-area-name' | translate"></app-text-field>
            </div>
            <div class="col-2">
              <app-number-field
                formControlName="freeDeliveryMinOrder"
                [readonly]="true" [label]="'pages.area.free-delivery-min-order' | translate"></app-number-field>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-2">
              <app-select [readonly]="readonly"
                          [items]="statuses"
                          [bindLabel]="'key'"
                          [bindValue]="'value'"
                          [label]="'pages.customer.status' | translate" formControlName="status"></app-select>
            </div>
            <div class="col-2">
              <app-select [readonly]="true"
                          [items]="stockStatuses"
                          [bindLabel]="'key'"
                          [bindValue]="'value'"
                          [label]="'pages.customer.delivery-status' | translate" formControlName="deliveryStatus"></app-select>
            </div>

            <div class="col-2" *ngIf="hideOnNew">
              <app-datepicker
                formControlName="receivedDate"
                [label]="'pages.customer.received-date' | translate"></app-datepicker>
            </div>

            <div class="col-2" *ngIf="hideOnNew && form.controls.deliveryDate.value">
              <app-datepicker
                formControlName="deliveryDate"
                [label]="'pages.customer.delivery-date' | translate"></app-datepicker>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-4">
              <app-datepicker
                formControlName="orderDate"
                [label]="'pages.customer.order-date' | translate"></app-datepicker>
            </div>
            <div class="col-2">
              <app-text-field
                formControlName="invoiceNo"
                [readonly]="true"
                [label]="'pages.customer.invoice-no' | translate"></app-text-field>
            </div>
            <div class="col-2" *ngIf="hideOnNew">
              <app-datepicker
                formControlName="paidDate"
                [label]="'pages.customer.paid-date' | translate"></app-datepicker>
            </div>
          </div>
          <div class="row">
            <div class="col-4 pt-3">
              <app-area-field
                formControlName="note"
                [rows]="5"
                [label]="'pages.customer.note' | translate"></app-area-field>
            </div>
            <div class="col-4 pt-3" *ngIf="img">
              <div class="dropzone" >
                <div class="img-preview">
                  <img [src]="img" style="height: 250px;" alt="evidence">
                </div>
              </div>
              <div class="mt-2">
                <button
                  (click)="downloadEvidence()"
                  type="button"
                  class="btn btn-primary">{{'common.download' | translate}}</button>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-4">

            </div>
            <div class="col-8 pt-3 text-end">
              <button
                type="button"
                [disabled]="disableButton || (customerOrder && customerOrder.status !== 'draft')"
                (click)="create()"
                mat-flat-button
                color="primary"
                class="text-white">
                {{'common.create' | translate}}
              </button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <table class="table table-striped table-hover">
                <thead>
                <tr>
                  <th class="text-nowrap text-end">{{'pages.customer.item-group-id' | translate}}</th>
                  <th class="text-nowrap">{{'pages.customer.sku' | translate}}</th>
                  <th class="text-nowrap">{{'pages.customer.item-name' | translate}}</th>
                  <th class="text-nowrap">{{'pages.customer.unit' | translate}}</th>
                  <th class="text-nowrap text-end">{{'pages.customer.standard-price' | translate}}</th>
                  <th class="text-nowrap text-end">{{'pages.customer.margin' | translate}}</th>
                  <th class="text-nowrap text-end">{{'pages.customer.sell-price' | translate}}</th>
                  <th class="text-nowrap text-end">{{'pages.customer.quantity' | translate}}</th>
                  <th class="text-nowrap text-end" *ngIf="hideOnNew">{{'pages.customer.quantity-final' | translate}}</th>
                  <th class="text-nowrap text-end">{{'pages.supplier.subtotal' | translate}}</th>
                  <th style="width: 200px;"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let d of orderDetails; let i = index">
                  <td class="text-nowrap text-end">{{d.itemGroupId}}</td>
                  <td class="text-nowrap">{{d.sku}}</td>
                  <td class="text-nowrap">{{d.itemName}}</td>
                  <td class="text-nowrap">{{d.unit}}</td>
                  <td class="text-nowrap text-end">{{d.standardPrice | number: '1.2-2'}}</td>
                  <td class="text-nowrap text-end">{{d.margin | number: '1.2-2'}}%</td>
                  <td class="text-nowrap text-end">{{d.price | number: '1.2-2'}}</td>
                  <td class="text-nowrap text-end">
                    <app-number-field
                      [disabled]="hideOnNew"
                      [(ngModel)]="d.quantity"
                      [ngModelOptions]="{standalone: true}"
                      (valueChange)="getQuantity(d, i, $event)"
                    ></app-number-field>
                  </td>
                  <td class="text-nowrap text-end" *ngIf="hideOnNew">{{d.quantityFinal | number: '1.2-2'}}</td>
                  <td class="text-nowrap text-end">{{d.subtotal | number: '1.2-2'}}</td>

                  <td>
                    <div class="btn-group" role="group" aria-label="Button Action">
                      <button
                        [disabled]="disableButton || (customerOrder && customerOrder.status !== 'draft')"
                        (click)="delete(d)"
                        type="button"
                        mat-stroked-button
                        color="warn">{{'common.delete' | translate}}</button>
                    </div>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td colspan="8" class="text-end"><b>Free Delivery Fee</b></td>
                  <td>
                    <b *ngIf="totalAfterTax > form.controls.freeDeliveryMinOrder.value">{{'common.yes' | translate}}</b>
                    <b *ngIf="totalAfterTax <= form.controls.freeDeliveryMinOrder.value">{{'common.no' | translate}}</b>
                  </td>
                </tr>
                <tr>
                  <td colspan="8" class="text-end"><b>{{'pages.customer.delivery-fee' | translate}}</b></td>
                  <td class="text-end">
                    <app-number-field
                      formControlName="deliveryFee" ></app-number-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="8" class="text-end"><b>{{'pages.customer.discount' | translate}}</b></td>
                  <td class="text-end">
                    <app-number-field
                      formControlName="discount"
                      (valueChange)="totalCount()"></app-number-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="8" class="text-end"><b>{{'pages.customer.tax' | translate}}</b></td>
                  <td class="text-end">
                    <app-number-field
                      formControlName="tax" ></app-number-field>
                  </td>
                  <td>{{tax}}%</td>
                </tr>
                <tr>
                  <td colspan="8" class="text-end"><b>{{'pages.customer.grand-total' | translate}} {{'pages.customer.before-tax' | translate}}</b></td>
                  <td class="text-end"><b>{{total | number: '1.2-2'}}</b></td>
                </tr>
                <tr>
                  <td colspan="8" class="text-end"><b>{{'pages.customer.grand-total' | translate}} {{'pages.customer.after-tax' | translate}}</b></td>
                  <td class="text-end"><b>{{totalAfterTax | number: '1.2-2'}}</b></td>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="row" *ngIf="mode !== 'view'">
            <div class="col-12 text-end justify-content-end">
              <button
                type="button"
                mat-stroked-button
                class="bg-info text-white me-2">{{'common.print' | translate}}</button>
              <button
                *ngIf="mode === 'create'" [disabled]="disableButton"
                mat-flat-button
                color="primary"
                class="text-white me-2">{{'common.save' | translate}}</button>
              <button
                *ngIf="mode !== 'create' && customerOrder && customerOrder.status.toLowerCase() === 'draft'"
                [disabled]="disableButton"
                mat-flat-button
                color="primary"
                class="text-white me-2">{{'common.save' | translate}}</button>
              <button
                *ngIf="mode !== 'create' && customerOrder && customerOrder.status.toLowerCase() === 'draft'"
                type="button"
                [disabled]="disableButton"
                mat-flat-button
                [ngClass]="disableButton ? 'text-black me-2':'bg-success text-white me-2'"
                (click)="sent()">{{'common.sent' | translate}}</button>
              <button
                *ngIf="mode !== 'create' && customerOrder && customerOrder.status.toLowerCase() === 'received'"
                type="button"
                [disabled]="disableButton"
                mat-flat-button
                [ngClass]="disableButton ? 'text-black me-2':'bg-success text-white me-2'"
                (click)="verified()">{{'common.verified' | translate}}</button>
              <button
                *ngIf="mode !== 'create' && (customerOrder && (customerOrder.status.toLowerCase() === 'received' || customerOrder.status.toLowerCase() === 'verified'))"
                type="button"
                [disabled]="disableButton"
                mat-flat-button
                [ngClass]="disableButton ? 'text-black me-2':'bg-warning text-black me-2'"
                class="bg-warning text-black me-2"
                (click)="pendingPayment()">{{'common.pending-payment' | translate}}</button>
              <button
                *ngIf="mode !== 'create' && (customerOrder && (customerOrder.status.toLowerCase() === 'pending' || customerOrder.status.toLowerCase() === 'verified'))"
                type="button"
                [disabled]="disableButton"
                mat-flat-button
                color="primary"
                class="text-white me-2"
                (click)="paid()">{{'common.paid' | translate}}</button>
              <button
                type="button"
                (click)="cancel()"
                mat-stroked-button
                color="warn"
                class="">{{'common.cancel' | translate}}</button>
            </div>
          </div>
        </form>

      </div>

    </div>
  </div>
</div>
