import { Component, EventEmitter, Inject, Injector, OnInit } from '@angular/core';
import { BaseComponentDirective } from '../../shared/base/base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-modal-change-password',
  templateUrl: './change-password.modal.html',
})
export class ChangePasswordModal extends BaseComponentDirective implements OnInit {
  public result = new EventEmitter();
  public form: FormGroup;
  public mode = '';
  public toggleOldPass = false;
  public toggleNewPass = false;
  public toggleConfirmPass = false;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ChangePasswordModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private dialog: MatDialog
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      oldPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required]],
      confirmNewPassword: [null, [Validators.required]]
    });
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }
    if (this.form.controls.newPassword.value !== this.form.controls.confirmNewPassword.value) {
      this.snackBar.open(this.translateService.instant('dialog.new-confirm-not-equal'), '', {duration: 5000});
      return;
    }

    this.loginService.changePassword({
      email: this.data.profile.email,
      newPassword: this.form.controls.newPassword.value,
      oldPassword: this.form.controls.oldPassword.value,
      confirmationPassword: this.form.controls.confirmNewPassword.value
    }).then(_ => {
      this.snackBar.open(this.translateService.instant('dialog.change-pass-success'), '', {duration: 5000});
      this.result.emit(true);
      this.dialogRef.close();
    }, _ => {
      this.result.emit(null);
      this.snackBar.open(this.translateService.instant('dialog.change-pass-failed'), '', {duration: 5000});
    });

  }
}
