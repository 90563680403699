<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card m- white-background text-black-50">

      <div class="mat-card-content px-3 mt-3 py-5">

        <mat-accordion class="example-headers-align" multi >
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'pages.audit.audit-info' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="form">
              <div class="row mt-3">
                <div class="col-4">
                  <app-text-field
                    [readonly]="true"
                    [label]="'pages.storage.storage-name' | translate"
                    formControlName="storageName"></app-text-field>
                </div>
                <div class="col-4">
                  <app-text-field
                    [readonly]="true"
                    [label]="'pages.storage.description' | translate"
                    formControlName="description"></app-text-field>
                </div>
                <div class="col-4">
                  <app-text-field
                    [readonly]="true"
                    [label]="'pages.area.area-name' | translate"
                    formControlName="areaName"></app-text-field>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-4">
                  <app-datepicker
                    [disabling]="true"
                    formControlName="auditStart"
                    [label]="'pages.audit.audit-start' | translate"></app-datepicker>
                </div>
                <div class="col-4" >
                  <app-datepicker
                    [disabling]="true"
                    formControlName="auditEnd"
                    [label]="'pages.audit.audit-end' | translate"></app-datepicker>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-4">
                  <app-area-field
                    [readonly]="audit && audit.auditEnd !== null"
                    formControlName="note"
                    [label]="'pages.audit.note' | translate"></app-area-field>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 justify-content-end text-end">
                  <button *ngIf="audit && !audit.auditEnd"
                    (click)="save()"
                    mat-flat-button
                    color="primary"
                    class="text-white">{{'common.save' | translate}}</button>
                </div>
              </div>
            </form>

          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'pages.audit.item-list' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row mt-3">
              <div class="col-12">
                <div class="row">
                  <div class="col-3">
                    <div class="form-group">
                      <label>{{'common.search' | translate}}</label>
                      <div class="input-group mb-3">
                        <input
                          [(ngModel)]="searchTerm"
                          (keyup)="doSearch(searchTerm, $event)"
                          class="form-control"
                          aria-label="search">
                        <div class="input-group-append">
                        <span class="input-group-text" id="suffix_password_show">
                            <span class="icon-search"></span>
                        </span>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-3">
                    <app-select (valueChange)="getChangedProductId($event)"
                      [readonly]="readonly"
                      [items]="products"
                      [bindLabel]="'productName'"
                      [bindValue]="'id'"
                      [label]="'pages.product.product-name' | translate"
                      [(ngModel)]="productId"
                    ></app-select>
                  </div>
                  <div class="col-6 pt-4 justify-content-end text-end">
                    <button
                      type="button"
                      (click)="downloadTemplate()"
                      class="btn btn-success text-white me-2">{{'pages.audit.download-template-xlsx' | translate}}</button>

                    <button
                      type="button"
                      (click)="downloadTemplatePdf()"
                      mat-flat-button
                      color="warn"
                      class="text-white me-2">{{'pages.audit.download-template-pdf' | translate}}</button>

                    <button
                      type="button"
                      (click)="uploadXlsx()"
                      mat-flat-button
                      color="primary"
                      class="text-white me-2">{{'pages.audit.upload-template-xlsx' | translate}}</button>

                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                      <th class="text-nowrap">{{'pages.item-group.item-group-code' | translate}}</th>
                      <th class="text-nowrap">{{'pages.item.item-name' | translate}}</th>
                      <th class="text-nowrap">{{'pages.product.product-name' | translate}}</th>
                      <th class="text-nowrap">{{'pages.product-sub.product-name' | translate}}</th>
                      <th class="text-nowrap text-end">{{'pages.audit.current-stock' | translate}}</th>
                      <th class="text-nowrap text-end" *ngIf="audit && audit.auditEnd">{{'pages.audit.previous-stock' | translate}}</th>
                      <th class="text-nowrap text-end">{{'pages.audit.audited-stock' | translate}}</th>
                      <th class="text-nowrap">{{'pages.audit.reason' | translate}}</th>
                      <th class="sticky-column text-center" *ngIf="audit && !audit.auditEnd">
                        <button  *ngIf="audit && !audit.auditEnd"
                                (click)="updateAll()"
                                type="button"
                                mat-raised-button color="warn">
                          <mat-icon>done_all</mat-icon>
                        </button>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let d of pageItems;  index as i">
                      <td class="text-nowrap">{{d.itemGroupCode}}</td>
                      <td class="text-nowrap">{{d.itemGroupName}}</td>
                      <td class="text-nowrap">{{d.productName}}</td>
                      <td class="text-nowrap">{{d.subProductName}}</td>
                      <td class="text-nowrap text-end">{{d.currentStock | number: '1.0'}}</td>
                      <td class="text-nowrap text-end" *ngIf="audit.auditEnd">{{d.previousStock | number: '1.0'}}</td>
                      <td class="text-nowrap text-end">
                        <app-number-field *ngIf="audit && !audit.auditEnd"
                          [(ngModel)]="d.auditedStock"
                          [ngModelOptions]="{standalone: true}"
                          (valueChange)="getAuditedStock(d, i, $event)"
                        ></app-number-field>
                        <span *ngIf="audit.auditEnd">{{d.auditedStock}}</span>
                      </td>
                      <td class="text-nowrap pt-4">
                        <app-text-field *ngIf="audit && !audit.auditEnd"
                          [(ngModel)]="d.reason"
                          [ngModelOptions]="{standalone: true}"
                          (valueChange)="getReason(d, i, $event)"
                        ></app-text-field>
                        <span *ngIf="audit.auditEnd">{{d.reason}}</span>
                      </td>
                      <td class="sticky-column text-center" *ngIf="audit && !audit.auditEnd">
                        <div class="btn-group" role="group" aria-label="Button Action">
                          <button *ngIf="d.id"
                            (click)="update(d, i)"
                            type="button"
                            mat-raised-button color="primary">
                            <mat-icon>check</mat-icon>
                          </button>
                          <button *ngIf="!d.id"
                                  (click)="update(d, i)"
                                  type="button"
                                  mat-raised-button color="warn">
                            <mat-icon>check</mat-icon>
                          </button>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <app-pagination
                  (goPage)="toPage($event)"
                  [pagesToShow]="showPages"
                  [page]="pageNo"
                  [perPage]="pageSize"
                  [count]="totalRow"></app-pagination>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 justify-content-end text-end">
                <button *ngIf="audit && !audit.auditEnd"
                  (click)="finish()"
                  [disabled]="disableSubmit"
                  type="button"
                  mat-raised-button color="primary">
                  {{'pages.audit.submit' | translate}}
                </button>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>

      </div>
    </div>
  </div>
</div>
