import { Component, OnInit, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_CREATE, CRUD_MODE_EDIT, CRUD_MODE_VIEW } from 'src/app/constants/common.constant';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BasePageComponentDirective } from 'src/app/shared/base/base-page.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { environment } from 'src/environments/environment';
import { StorageModal } from '../modals/storage.modal';
import { StorageModel } from '../models/storage.model';
import { StorageService } from '../services/storage.service';
import {AreaService} from "../../area/services/area.service";
import {AreaModel} from "../../area/models/area.model";

@Component({
  selector: 'app-page-storage',
  templateUrl: './storage.page.html',
})
export class StoragePageComponent extends BasePageComponentDirective<StorageModel> implements OnInit{
  areas: AreaModel[] = [];
  constructor(
    injector: Injector,
    private storageService: StorageService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private areaService: AreaService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.areas = await this.areaService.findAllNoPaging();
    this.query();

    this.breadcrumbService.changeMessage([
      {label: 'menu.master.index', active: false},
      {label: 'pages.storage.storage', active: true, route: ''}
    ]);
  }

  query(): void {
    this.storageService.findAll(this.pageQuery).then(async (res) => {
      this.page.emit(res);
    });
  }

  create(): void {
    const dialog = this.dialog.open(StorageModal, {
      data: {
        mode: CRUD_MODE_CREATE
      }
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res === 1) {
        this.query();
      }
    });
  }

  edit(d: StorageModel): void {
    this.storageService.findOne(d.id).then(storage => {
      storage.imageName = (storage.imageName) ? environment.storage + '/images/storage/' + storage.imageName : null;
      const dialog = this.dialog.open(StorageModal, {
        data: {
          storage,
          mode: CRUD_MODE_EDIT
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.query();
        }
      });
    });

  }

  view(d: StorageModel): void {
    this.storageService.findOne(d.id).then(storage => {
      storage.imageName = (storage.imageName) ? environment.storage + '/images/storage/' + storage.imageName : null;
      this.dialog.open(StorageModal, {
        data: {
          storage,
          mode: CRUD_MODE_VIEW
        }
      });
    });

  }

  delete(d: StorageModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.storageName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-danger', color: 'primary', focus: true, label: this.translateService.instant('common.delete')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.storageService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }


}
