<div class="form-group">
    <label>{{label}}<span *ngIf="checkRequiredValidator() == 'required'"> *</span></label>
    <ng-select [readonly]="readonly" [items]="items"  [class]="ngControl.control.dirty && ngControl.control.errors? 'is-invalid': ''"
           bindLabel="{{bindLabel}}"
           bindValue="{{bindValue}}"
           class="form-control"
           [formControl]="ngControl.control">
    </ng-select>
    <div class="invalid-feedback" *ngIf="ngControl.control.dirty && ngControl.control.errors">{{'error.input-required' | translate}}</div>
  </div>
