import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerPageComponent } from './customer/pages/customer.page';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { CustomerOrderFormPageComponent } from './customer-order/pages/customer-order-form.page';
import { CustomerOrderReceivingPageComponent } from './customer-order/pages/customer-order-receiving.page';
import { OrderReceivingFormPageComponent } from './customer-order/pages/order-receiving-form.page';
import { CustomerOrderTablePageComponent } from './customer-order/pages/customer-order-table-page.component';
import { CustomerOrderPageComponent } from './customer-order/pages/customer-order.page';
import { CustomerOrderUploadPageComponent } from './customer-order-upload/pages/customer-order-upload.page';

const routes: Routes = [
  { path: 'customer', component: CustomerPageComponent, canActivate: [AuthGuard] },
  { path: 'order', component: CustomerOrderPageComponent, canActivate: [AuthGuard] },
  { path: 'order-xtable', component: CustomerOrderTablePageComponent, canActivate: [AuthGuard] },
  { path: 'order/:action/:id', component: CustomerOrderFormPageComponent, canActivate: [AuthGuard] },
  { path: 'order-receiving', component: CustomerOrderReceivingPageComponent, canActivate: [AuthGuard] },
  { path: 'order-receiving/:action/:id', component: OrderReceivingFormPageComponent, canActivate: [AuthGuard] },
  { path: 'order-upload', component: CustomerOrderUploadPageComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRoutesModule {
}
