import {RouterModule, Routes} from '@angular/router';
import {DashboardPageComponent} from './pages/dashboard.page';
import {NgModule} from '@angular/core';
import {AuthGuard} from '../../auth/auth-guard.service';
import { CriticalStockPageComponent } from './pages/critical-stock.page';

const routes: Routes = [
  {path: '', component: DashboardPageComponent, canActivate: [AuthGuard]},
  {path: 'critical-stock', component: CriticalStockPageComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutesModule {
}
