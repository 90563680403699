<mat-dialog-content style="width: 1200px;">

  <div class="mat-card-title px-2 py-3">
    <h3 class="pl-2">{{ 'pages.item-group.item-group' | translate }}</h3>
  </div>
  <div class="mat-card-content px-3 rounded">
    <div class="row">
      <div class="col-4">
        <label>{{ 'common.search' | translate }}</label>
        <div class="input-group mb-3">
          <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control"
                 aria-label="search">
          <div class="input-group-append">
                    <span class="input-group-text" id="suffix_password_show">
                        <span class="icon-search"></span>
                    </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mat-card-content px-3">
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th class="text-nowrap text-end"></th>
          <th class="text-nowrap">{{ 'pages.item-group.item-group-code' | translate }}</th>
          <th class="text-nowrap">{{ 'pages.item-group.item-group-name' | translate }}</th>
          <th class="text-nowrap">{{ 'pages.item.unit' | translate }}</th>
          <th class="text-nowrap">{{ 'pages.product.product-name' | translate }}</th>
          <th class="text-nowrap">{{ 'pages.product-sub.name' | translate }}</th>
          <th class="sticky-column"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let itemGroup of pageItems; let arrIdx = index">
          <td class="text-nowrap">
            <img alt="" class="image-round-multiply" [src]="itemImageBaseURL + itemGroup.imageName"/>
          </td>
          <td class="text-nowrap" style="">{{ itemGroup.itemGroupCode }}</td>
          <td class="text-nowrap" style="">{{ itemGroup.itemGroupName }}</td>
          <td class="text-nowrap" style="">{{ itemGroup.unit }}</td>
          <td class="text-nowrap" style="">{{ maskingColumn('productName', 'id', products, itemGroup.productId) }}</td>
          <td class="text-nowrap"
              style="">{{ maskingColumn('subProductName', 'id', subProducts, itemGroup.subProductId) }}
          </td>
          <td class="sticky-column">
            <mat-checkbox (change)="checkMulti($event, itemGroup, arrIdx)"
                          [checked]="currentPageCheckedItemGroups.has(itemGroup.id)">
            </mat-checkbox>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mat-card-content">
    <div class="row mb-4">
      <div class="col-12 text-center">
        <app-pagination
          (goPage)="toPage($event)"
          [pagesToShow]="showPages"
          [page]="pageNo"
          [perPage]="pageSize"
          [count]="totalRow"></app-pagination>
      </div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions class="justify-content-end pe-4">
  <button type="button" (click)="choose()" mat-raised-button class="py-1"
          color="primary">{{ 'common.choose' | translate }}
  </button>
  <button type="button" mat-dialog-close mat-stroked-button
          class="py-1 white-background text-black-50">{{ 'common.close' | translate }}
  </button>
</mat-dialog-actions>
