import { Component, OnInit, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_CREATE, CRUD_MODE_EDIT, CRUD_MODE_VIEW } from 'src/app/constants/common.constant';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BasePageComponentDirective } from 'src/app/shared/base/base-page.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { SupplierModel } from '../models/supplier.model';
import { SupplierService } from '../services/supplier.service';
import { SupplierModal } from '../modals/supplier.modal';

@Component({
    selector: 'app-page-supplier',
    templateUrl: './supplier.page.html',
})
export class SupplierPageComponent extends BasePageComponentDirective<SupplierModel> implements OnInit{

    suppliers: SupplierModel[] = [];
    constructor(
      injector: Injector,
      private supplierService: SupplierService,
      private dialog: MatDialog,
      private breadcrumbService: BreadcrumbService
    ) {
      super(injector);
    }

    async ngOnInit(): Promise<void> {
      super.ngOnInit();

      this.query();

      this.breadcrumbService.changeMessage([
        {label: 'pages.supplier.supplier', active: false},
        {label: 'pages.supplier.supplier', active: true, route: ''}
      ]);
    }

    query(): void {
      this.supplierService.findAll(this.pageQuery).then(async (res) => {
        this.page.emit(res);
      });
    }

    create(): void {
      const dialog = this.dialog.open(SupplierModal, {
        data: {
          mode: CRUD_MODE_CREATE
        }
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.query();
        }
      });
    }

    edit(d: SupplierModel): void {
      this.supplierService.findOne(d.id).then(supplier => {
        const dialog = this.dialog.open(SupplierModal, {
          data: {
            supplier,
            mode: CRUD_MODE_EDIT
          },
        });

        dialog.componentInstance.result.subscribe((res) => {
          if (res === 1) {
            this.query();
          }
        });
      });

    }

    view(d: SupplierModel): void {
      this.supplierService.findOne(d.id).then(supplier => {
        this.dialog.open(SupplierModal, {
          data: {
            supplier,
            mode: CRUD_MODE_VIEW
          }
        });
      });

    }

    supplierItem(d: SupplierModel): void {
      this.router.navigate([`/supplier/supplier/${d.id}/item`]).then();
    }

    delete(d: SupplierModel): void {
      const dialog = this.dialog.open(DialogScrollComponent,  {
        data: {
          title: this.translateService.instant('dialog.delete-message'),
          subtitle: this.translateService.instant('dialog.delete-title'),
          content: `#${d.id} - ${d.supplierName}`,
          buttons: [
            {value: 1, className: 'text-red btn btn-danger', color: 'primary', focus: true, label: this.translateService.instant('common.delete')},
            {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
          ]
        }
      });

      dialog.afterClosed().subscribe((res) => {
        if (res === 1) {
          this.supplierService.delete(d.id).then(() => {
            this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
              verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
            });
            this.query();
          });
        }
      });
    }


  }
