import { Component, Injector, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ParameterModel } from '../models/parameter.model';
import { ParameterService } from '../services/parameter.service';
import { BasePageTableComponent } from '../../../../shared/base/base-page-table.component';
import { PageInterface, PageQueryInterface } from '../../../../interfaces/page.interface';
import { XTableButton, XTableColumn } from '../../../../shared/components/xtable/xtable.component';
import { CustomerOrderModel } from '../../../customer/customer-order/models/customer-order.model';
import { MatDialog } from '@angular/material/dialog';
import { ParameterModal, ParameterModalProps } from '../modals/parameter.modal';
import { DialogScrollComponent } from '../../../../shared/components/dialog/dialog-scroll.component';

@Component({
  selector: 'app-page-parameter',
  templateUrl: './parameter.page.html',
})
export class ParameterPageComponent extends BasePageTableComponent<ParameterModel> implements OnInit {

  columns: XTableColumn<ParameterModel>[] = [
    {
      name: 'parameterName',
      label: 'pages.parameter.parameter-name',
      isSearchable: true,
      isFilterable: true,
      isSortable: true,
    },
    {
      name: 'description',
      label: 'pages.parameter.description',
      isFilterable: true,
      isSearchable: true,
    },
  ];

  buttons: XTableButton<CustomerOrderModel>[] = [
    {
      name: 'view',
      icon: 'fullscreen',
      onClick: (d) => {
        this.router.navigate(['master/parameter/' + d.id]).then();
      },
      tooltip: 'pages.parameter.view-parameter',
    },
    {
      name: 'edit',
      icon: 'edit',
      onClick: (d) => this.openEditParameterDialog(d),
      tooltip: 'pages.parameter.edit-parameter',
    },
    {
      name: 'delete',
      icon: 'delete',
      onClick: (d) => this.delete(d),
      color: 'warn',
      tooltip: 'pages.parameter.delete-parameter',
    },
  ];

  constructor(
    injector: Injector,
    private parameterService: ParameterService,
    private breadcrumbService: BreadcrumbService,
    private dialog: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.breadcrumbService.changeMessage([
      { label: 'menu.master.index', active: false, route: '' },
      { label: 'pages.parameter.parameter', active: true, route: '' },
    ]);
  }

  requestData(query: PageQueryInterface): Promise<PageInterface<ParameterModel>> {
    return this.parameterService.findAll(query);
  }

  openCreateDialog() {
    const dialog = this.dialog.open<ParameterModal, ParameterModalProps>(ParameterModal, {
      data: {
        model: {},
      },
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res !== undefined) {
        this.reload();
      }
    });
  }

  openEditParameterDialog(model: ParameterModel) {
    const dialog = this.dialog.open<ParameterModal, ParameterModalProps>(ParameterModal, {
      data: {
        model: model,
      },
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res !== undefined) {
        this.reload();
      }
    });
  }

  delete(d: ParameterModel): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.parameterName}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-danger',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.delete'),
          },
          { value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close') },
        ],
      },
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.parameterService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000,
          });
          this.reload();
        });
      }
    });
  }
}
