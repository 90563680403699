import { Component, EventEmitter, Inject, Injector, OnInit } from '@angular/core';
import { BaseComponentDirective } from '../../../../shared/base/base.component';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-customer-order-upload',
  templateUrl: './customer-order-upload-modal.dialog.html',
})
export class CustomerOrderUploadModalDialog extends BaseComponentDirective implements OnInit {
  public result = new EventEmitter();
  public form: FormGroup;
  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;
  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<CustomerOrderUploadModalDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  async uploadFile(id: number): Promise<any> {
    return null;
  }
}
