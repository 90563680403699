import { Component, OnInit, Injector, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { CRUD_MODE_EDIT, CRUD_MODE_VIEW, CRUD_MODE_CREATE } from 'src/app/constants/common.constant';
import { BaseComponentDirective } from 'src/app/shared/base/base.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { ProductModel } from '../models/product.model';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-modal-product',
  templateUrl: './product.modal.html',
})
export class ProductModal extends BaseComponentDirective implements OnInit{
  public result = new EventEmitter();
  public form: FormGroup;
  public mode = '';
  public product: ProductModel;
  public viewMode = false;

  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ProductModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private dialog: MatDialog
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      productName: [null, [Validators.required]],
      description: [null, [Validators.required]]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.product = this.data.product as ProductModel;
    this.mode = this.data.mode;


    switch (this.mode) {
      case CRUD_MODE_EDIT: {
        this.fileUrl = this.product.imageName;
        this.form.setValue({
          productName: this.product.productName,
          description: this.product.description,
        });

        break;
      }
      case CRUD_MODE_VIEW: {
        this.readonly = true;
        this.fileUrl = this.product.imageName;
        this.form.setValue({
          productName: this.product.productName,
          description: this.product.description,
        });

        this.viewMode = true;
        break;
      }
    }
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }
    const payload = {
      id: (this.mode === CRUD_MODE_EDIT) ? this.product.id : null,
      productName: this.form.controls.productName.value,
      description: this.form.controls.description.value,
    };

    const isCreateMode = this.mode === CRUD_MODE_CREATE;

    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: (isCreateMode) ? `#${payload.productName}` : `#${payload.id} - ${payload.productName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.save')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        if (isCreateMode) {
          this.productService.save(payload).then((r) => {
            this.doAfterSave(r.id);
          });
        } else {
          this.productService.update(payload).then((_) => {
            this.doAfterSave(payload.id);
          });
        }
      }
    });
  }

  doAfterSave(id: number): void {
    if (this.fd) {
      this.uploadFile(id);
    }

    this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
      verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
    });
    this.result.emit(1);
    this.dialogRef.close();
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  async uploadFile(id: number): Promise<any> {
    return await this.productService.uploadFile(this.fd, id);
  }


}
