import {Component, OnInit, Injector, ViewChild, AfterViewInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_EDIT, CRUD_MODE_CREATE, CRUD_MODE_VIEW } from 'src/app/constants/common.constant';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BasePageComponentDirective } from 'src/app/shared/base/base-page.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { environment } from 'src/environments/environment';
import { ProductModal } from '../../product/modals/product.modal';
import { ProductModel } from '../../product/models/product.model';
import { ProductService } from '../../product/services/product.service';
import { ProductSubModal } from '../modals/product-sub.modal';
import { ProductSubModel } from '../models/product-sub.model';
import { ProductSubService } from '../services/product-sub.service';
import {MatAccordion} from "@angular/material/expansion";

@Component({
  selector: 'app-page-product-sub',
  templateUrl: './product-sub.page.html',
})
export class ProductSubPageComponent extends BasePageComponentDirective<ProductSubModel> implements OnInit, AfterViewInit{
  productId: number;
  form: FormGroup;
  product: ProductModel;
  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;
  public readonly = true;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private productSubService: ProductSubService,
    private productService: ProductService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      productName: [null, [Validators.required]],
      description: [0, [Validators.required]],
    });
  }

  async ngAfterViewInit(): Promise<any> {
    setTimeout(_ => {
      this.accordion.openAll();
    }, 2000);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.productId = this.activatedRoute.snapshot.params.id;
    if (!this.productId) {
      this.router.navigate(['master/product']).then();
    }

    this.setProductForm().then();

    this.query();

    this.breadcrumbService.changeMessage([
      {label: 'menu.master.index', active: false},
      {label: 'pages.product.product', active: false, route: '/master/product'},
      {label: 'pages.product-sub.product-sub', active: true, route: ''}
    ]);
  }

  async setProductForm(): Promise<void> {
    this.product = await this.productService.findOne(this.productId);
    this.form.patchValue({
      productName: this.product.productName,
      description: this.product.description,
    });
    this.fileUrl =  environment.storage + '/images/product/' + this.product.imageName;
  }

  query(): void {
    this.productSubService.findAll(this.pageQuery, this.productId).then((res) => {
      this.page.emit(res);
    });
  }

  save(): void {
    this.productService.findOne(this.product.id).then(product => {
      product.imageName = environment.storage + '/images/product/' + product.imageName;
      const dialog = this.dialog.open(ProductModal, {
        data: {
          product,
          mode: CRUD_MODE_EDIT
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.setProductForm();
        }
      });
    });
  }

  create(): void {
    const dialog = this.dialog.open(ProductSubModal, {
      data: {
        productId: this.productId,
        mode: CRUD_MODE_CREATE
      }
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res === 1) {
        this.query();
      }
    });
  }

  edit(d: ProductSubModel): void {
    this.productSubService.findOne(d.id).then(productSub => {
      productSub.imageName = (productSub.imageName) ? environment.storage + '/images/sub-product/' + productSub.imageName : null;
      const dialog = this.dialog.open(ProductSubModal, {
        data: {
          productId: this.productId,
          productSub,
          mode: CRUD_MODE_EDIT
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.query();
        }
      });
    });

  }

  view(d: ProductSubModel): void {
    this.productSubService.findOne(d.id).then(productSub => {

      productSub.imageName = (productSub.imageName) ? environment.storage + '/images/sub-product/' + productSub.imageName : null;
      this.dialog.open(ProductSubModal, {
        data: {
          productId: this.productId,
          productSub,
          mode: CRUD_MODE_VIEW
        }
      });
    });

  }

  delete(d: ProductSubModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.subProductName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-danger', color: 'primary', focus: true, label: this.translateService.instant('common.delete')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.productSubService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  async uploadFile(id: number): Promise<any> {
    return await this.productSubService.uploadFile(this.fd, id);
  }

}
