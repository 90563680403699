import {Component, Injector, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CRUD_MODE_CREATE, CRUD_MODE_EDIT, CRUD_MODE_VIEW} from 'src/app/constants/common.constant';
import {BreadcrumbService} from 'src/app/services/breadcrumb.service';
import {BasePageComponentDirective} from 'src/app/shared/base/base-page.component';
import {DialogScrollComponent} from 'src/app/shared/components/dialog/dialog-scroll.component';
import {CustomerModel} from '../models/customer.model';
import {CustomerService} from '../services/customer.service';
import {CustomerModal} from '../modals/customer.modal';
import {AreaModel} from '../../../master/area/models/area.model';
import {AreaSubModel} from '../../../master/area-sub/models/area-sub.model';
import {AreaService} from '../../../master/area/services/area.service';
import {AreaSubService} from '../../../master/area-sub/services/area-sub.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-page-customer',
  templateUrl: './customer.page.html',
})
export class CustomerPageComponent extends BasePageComponentDirective<CustomerModel> implements OnInit {

  suppliers: CustomerModel[] = [];
  areas: AreaModel[] = [];
  subAreas: AreaSubModel[] = [];

  constructor(
    injector: Injector,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private areaService: AreaService,
    private areaSubService: AreaSubService,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.areas = await this.areaService.findAllNoPaging();
    this.subAreas = await this.areaSubService.findAllNoPaging(0);
    this.query();

    this.breadcrumbService.changeMessage([
      {label: 'menu.master.index', active: false},
      {label: 'pages.customer.customer', active: true, route: ''}
    ]);
  }

  query(): void {
    this.customerService.findAll(this.pageQuery).then(async (res) => {
      this.page.emit(res);
    });
  }

  create(): void {
    const dialog = this.dialog.open(CustomerModal, {
      data: {
        mode: CRUD_MODE_CREATE
      }
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res === 1) {
        this.query();
      }
    });
  }

  edit(d: CustomerModel): void {
    this.customerService.findOne(d.id).then(customer => {
      customer.imageName = (customer.imageName) ? environment.storage + '/images/area/' + customer.imageName : null;
      const dialog = this.dialog.open(CustomerModal, {
        data: {
          customer,
          mode: CRUD_MODE_EDIT
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.query();
        }
      });
    });

  }

  view(d: CustomerModel): void {
    this.customerService.findOne(d.id).then(customer => {
      customer.imageName = (customer.imageName) ? environment.storage + '/images/area/' + customer.imageName : null;
      this.dialog.open(CustomerModal, {
        data: {
          customer,
          mode: CRUD_MODE_VIEW
        }
      });
    });

  }

  delete(d: CustomerModel): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.customerName}`,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-danger',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.delete')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.customerService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }
  // tslint:disable-next-line:typedef
  manageUser(d: CustomerModel) {
    console.log('customer ', d);
    this.router.navigate(['user/user/' + d.id]).then();
  }
}
