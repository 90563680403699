import {Injectable} from '@angular/core';
import {BasePageService} from '../../../../shared/base/base-page.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {
  BACKOFFICE,
  IMAGE_BASE_URL,
  SUPPLIER_ORDER_BASE_URL, SUPPLIER_ORDER_RECEIVING_BASE_URL
} from '../../../../constants/api.constant';
import {SupplierOrderModel} from '../models/supplier-order.model';
import {PageInterface, PageQueryInterface} from '../../../../interfaces/page.interface';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SupplierOrderService extends BasePageService<SupplierOrderModel>{

  orderReceiving = environment.url + BACKOFFICE + SUPPLIER_ORDER_RECEIVING_BASE_URL;

  constructor(public http: HttpClient) {
    super(http);
  }

  get baseUrl(): string {
    return environment.url + BACKOFFICE + SUPPLIER_ORDER_BASE_URL;
  }

  async uploadFile(fd: FormData, supplierOrderId: number): Promise<any> {
    return await this.http.post<any>(environment.url + BACKOFFICE + IMAGE_BASE_URL + `/${supplierOrderId}/upload/supplier-order`,
      fd, {}).toPromise();
  }

  async findAllBySearch(p: PageQueryInterface, date: string, status: string): Promise<PageInterface>{
    return await this.http.get<PageInterface>(this.baseUrl + `?page=${p.pageNo}&limit=${p.pageSize}&search-term=${p.searchTerm}&date=${date}&status=${status}`,
        {}).toPromise();
  }

  async generateInvoice(supplierId: number, orderDate: string): Promise<any> {
    return await this.http.get<any>(this.baseUrl + `/generate-invoice/${supplierId}/${orderDate}`).toPromise();
  }

  async downloadEvidenceFile(supplierOrderId: number): Promise<any> {
    return await this.http.get<any>(environment.url + BACKOFFICE + IMAGE_BASE_URL + `/${supplierOrderId}/stream/supplier-order`, {
      responseType: 'blob' as 'json'
    })
      .toPromise();
  }

  async findAllReceiving(p: PageQueryInterface): Promise<PageInterface>{
    return await this.http.get<PageInterface>(this.orderReceiving + `?page=${p.pageNo}&limit=${p.pageSize}&search-term=${p.searchTerm}`,
        {}).toPromise();
  }

  downloadFile(id: number): Observable<Blob> {
    // Make an HTTP GET request to the API
    return this.http.get(this.baseUrl + '/invoice-pdf/' + id, {
      responseType: 'blob', // Set the response type to 'blob' for binary data
    });
  }
}
