import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent {

  @Input() page: number;
  @Input() count: number;
  @Input() perPage: number;
  @Input() pagesToShow: number;
  @Input() loading: boolean;

  @Output() goPrev = new EventEmitter<boolean>();
  @Output() goNext = new EventEmitter<boolean>();
  @Output() goPage = new EventEmitter<number>();

  curPage = 1;

  onPage(n: number): void {
    this.goPage.emit(n);
    this.curPage = n;
  }

  toFirst(): void {
    this.goPage.emit(1);
    this.curPage = 1;
  }

  toLast(): void {
    this.goPage.emit(this.totalPages());
    this.curPage = this.totalPages();
  }

  onPrev(): void {
    if (this.page >= 1) {
      this.goPrev.emit(true);
      this.curPage--;
    }
  }

  onNext(next: boolean): void {
    if (this.totalPages() > this.page + 1) {
      this.goNext.emit(next);
      this.curPage++;
    }
  }

  totalPages(): number {
    return Math.ceil(this.count / this.perPage) || 0;
  }

  getPages(): number[] {
    const c = Math.ceil(this.count / this.perPage);
    const p = this.page || 1;
    const pagesToShow = this.pagesToShow || 9;
    const pages: number[] = [];
    pages.push(p);
    const times = pagesToShow - 1;
    for (let i = 0; i < times; i++) {
      if (pages.length < pagesToShow) {
        if (Math.min.apply(null, pages) > 1) {
          pages.push(Math.min.apply(null, pages) - 1);
        }
      }
      if (pages.length < pagesToShow) {
        if (Math.max.apply(null, pages) < c) {
          pages.push(Math.max.apply(null, pages) + 1);
        }
      }
    }
    pages.sort((a, b) => a - b);
    return pages;
  }
}
