import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import { XTableColumn } from './xtable.component';
import { ColumnType, Filter, Operator, PageQueryInterface } from '../../../interfaces/page.interface';
import { AppComponentModule } from '../component.module';
import { MatButton } from '@angular/material/button';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

export interface AdvancedSearchProps {
  columns: XTableColumn[];
  query: PageQueryInterface;
}

@Component({
  selector: 'app-advanced-search',
  standalone: true,
  imports: [
    AppComponentModule,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    NgForOf,
  ],
  templateUrl: './advanced-search.component.html',
  styles: ``,
})
export class AdvancedSearchComponent implements OnInit {
  form: UntypedFormGroup;

  protected readonly ColumnType = ColumnType;
  protected readonly Operator = Operator;

  constructor(
    public dialogRef: MatDialogRef<AdvancedSearchComponent, PageQueryInterface>,
    @Inject(MAT_DIALOG_DATA) public data: AdvancedSearchProps,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.createFormControls();
    this.loadPreviousFilter();
  }

  resetForm(): void {
    const defaultValues = this.data.columns.reduce((values, column) => {
      if ([ColumnType.NUMBER, ColumnType.DATE, ColumnType.DATETIME].includes(column.type)) {
        values[`from_${column.name.toString()}`] = '';
        values[`to_${column.name.toString()}`] = '';
      } else {
        values[column.name] = '';
        values[`${column.name.toString()}_operator`] = Operator.EQUAL;
      }
      return values;
    }, {});

    this.form.reset(defaultValues);
  }

  submitFilter(): void {
    let filters: Filter[] = [];
    for (let column of this.data.columns) {
      if ([ColumnType.NUMBER, ColumnType.DATE, ColumnType.DATETIME].includes(column.type)) {
        let fromValue = this.form.controls[`from_${column.name.toString()}`].value;
        if (fromValue !== null) {
          filters.push({
            fieldName: `from_${column.name.toString()}`,
            fieldValue: fromValue,
            operator: Operator.GREATER_THAN_EQUAL,
            type: column.type,
          });
        }

        let toValue = this.form.controls[`to_${column.name.toString()}`].value;
        if (toValue !== null) {
          filters.push({
            fieldName: `to_${column.name.toString()}`,
            fieldValue: toValue,
            operator: Operator.LESS_THAN_EQUAL,
            type: column.type,
          });
        }
      } else {
        let value = this.form.controls[column.name.toString()].value;
        if (value !== null) {
          let operator = this.form.controls[`${column.name.toString()}_operator`].value;
          filters.push({
            fieldName: column.name.toString(),
            fieldValue: value,
            operator: operator,
            type: ColumnType.STRING,
          });
        }
      }
    }

    this.dialogRef.close({
      filters: filters.filter(f => f.fieldValue != null && f.fieldValue != ''),
      pageNo: 1,
      pageSize: this.data.query.pageSize,
    });
  }

  close() {
    this.dialogRef.close(null);
  }

  private createFormControls(): void {
    const formControls = this.data.columns.reduce((controls, column) => {
      if ([ColumnType.NUMBER, ColumnType.DATE, ColumnType.DATETIME].includes(column.type)) {
        controls[`from_${column.name.toString()}`] = [];
        controls[`to_${column.name.toString()}`] = [];
      } else {
        controls[column.name] = [];
        controls[`${column.name.toString()}_operator`] = [Operator.EQUAL];
      }
      return controls;
    }, {});
    this.form = this.formBuilder.group(formControls);
  }

  private loadPreviousFilter() {
    for (let column of this.data.columns) {
      if ([ColumnType.NUMBER, ColumnType.DATE, ColumnType.DATETIME].includes(column.type)) {
        let fromFilterList = this.data.query.filters.filter(filter => filter.fieldName == `from_${column.name.toString()}`);
        if (fromFilterList.length > 0) {
          let fromFilter = fromFilterList[0];
          this.form.controls[`from_${column.name.toString()}`].setValue(fromFilter.fieldValue);
        }
        let toFilterList = this.data.query.filters.filter(filter => filter.fieldName == `to_${column.name.toString()}`);
        if (toFilterList.length > 0) {
          let toFilter = toFilterList[0];
          this.form.controls[`to_${column.name.toString()}`].setValue(toFilter.fieldValue);
        }
      } else {
        let filter = this.data.query.filters.filter(filter => filter.fieldName == column.name)[0];
        if (filter != null) {
          this.form.controls[column.name.toString()].setValue(filter.fieldValue);
          this.form.controls[`${column.name.toString()}_operator`].setValue(filter.operator);
        }
      }
    }
  }
}
