import { Component, OnInit, Injector, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { CRUD_MODE_EDIT, CRUD_MODE_VIEW, CRUD_MODE_CREATE } from 'src/app/constants/common.constant';
import { BaseComponentDirective } from 'src/app/shared/base/base.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { ProductSubModel } from '../models/product-sub.model';
import { ProductSubService } from '../services/product-sub.service';

@Component({
  selector: 'app-modal-product-sub',
  templateUrl: './product-sub.modal.html',
})
export class ProductSubModal extends BaseComponentDirective implements OnInit{
  public result = new EventEmitter();
  public form: FormGroup;
  public mode = '';
  public productSub: ProductSubModel;
  public viewMode = false;

  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ProductSubModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private productSubService: ProductSubService,
    private dialog: MatDialog
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      subProductName: [null, [Validators.required]],
      description: [null, [Validators.required]]
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.productSub = this.data.productSub as ProductSubModel;
    this.mode = this.data.mode;

    switch (this.mode) {
      case CRUD_MODE_EDIT: {
        this.fileUrl = this.productSub.imageName;
        this.form.setValue({
          subProductName: this.productSub.subProductName,
          description: this.productSub.description,
        });
        break;
      }
      case CRUD_MODE_VIEW: {
        this.readonly = true;
        this.fileUrl = this.productSub.imageName;
        this.form.setValue({
          subProductName: this.productSub.subProductName,
          description: this.productSub.description,
        });

        this.viewMode = true;
        break;
      }
    }
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }

    const payload = {
      id: (this.mode === CRUD_MODE_EDIT) ? this.productSub.id : null,
      subProductName: this.form.controls.subProductName.value,
      description: this.form.controls.description.value,
      productId: this.data.productId
    };

    const isCreateMode = this.mode === CRUD_MODE_CREATE;

    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: (isCreateMode) ? `#${payload.subProductName}` : `#${payload.id} - ${payload.subProductName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.save')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        if (isCreateMode) {
          this.productSubService.save(payload).then((r) => {
            this.doAfterSave(r.id);
          });
        } else {
          this.productSubService.update(payload).then((_) => {
            this.doAfterSave(payload.id);
          });
        }
      }
    });
  }

  doAfterSave(id: number): void {
    if (this.fd) {
      this.uploadFile(id).then();
    }

    this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
      verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
    });
    this.result.emit(1);
    this.dialogRef.close();
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  async uploadFile(id: number): Promise<any> {
    return await this.productSubService.uploadFile(this.fd, id);
  }
}
