<mat-dialog-content style="width: 1250px;">

  <div class="mat-card-title px-3 py-3">
    <h3 class="pl-2">{{'pages.storage.storage' | translate}}</h3>
  </div>
  <div class="mat-card-content px-3 rounded">
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <label>{{'common.search' | translate}}</label>
          <div class="input-group mb-3">
            <input [(ngModel)]="searchTerm" (keyup)="doSearch(searchTerm, $event)" class="form-control" aria-label="search">
            <div class="input-group-append">
              <span class="input-group-text" id="suffix_password_show">
                  <span class="icon-search"></span>
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="mat-card-content px-3 pb-3">
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th class="text-nowrap">{{'pages.storage.storage-name' | translate}}</th>
          <th class="text-nowrap">{{'pages.storage.description' | translate}}</th>
          <th class="text-nowrap">{{'pages.storage.address' | translate}}</th>
          <th class="sticky-column"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of pageItems">
          <td class="text-nowrap" style="">{{item.storageName}}</td>
          <td class="text-nowrap" style="">{{item.description}}</td>
          <td class="text-nowrap" style="">{{item.address}}</td>
          <td class="sticky-column" style="">
            <div class="btn-group" role="group" aria-label="Button Action">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedItem">
                <mat-radio-button [value]="item"></mat-radio-button>
              </mat-radio-group>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mat-card-content">
    <div class="row mb-4">
      <div class="col-12 text-center">
        <app-pagination
          (goPage)="toPage($event)"
          [pagesToShow]="showPages"
          [page]="pageNo"
          [perPage]="pageSize"
          [count]="totalRow"></app-pagination>
      </div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions  class="justify-content-end pe-4">
  <button type="button" (click)="choose()" mat-raised-button class="py-1" color="primary">{{'common.choose' | translate}}</button>
  <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
</mat-dialog-actions>
