<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card m- white-background text-black-50 rounded-lg">
      <div class="mat-card-title px-3 py-3">
        <h3 class="pl-2">{{'pages.item-group.report' | translate}}</h3>
      </div>
      <div class="mat-card-content px-3 rounded">
        <div class="row">
          <div class="col-4">

            <mat-tab-group>
              <mat-tab label="{{'pages.item-group.report-per-storage' | translate}}">
                <form [formGroup]="form" (submit)="save()">
                  <div class="row mt-4">
                    <div class="col-12">
                        <app-select
                          [readonly]="readonly"
                          [items]="areas"
                          [bindLabel]="'areaName'"
                          [bindValue]="'id'"
                          [label]="'pages.area.area-name' | translate"
                          formControlName="areaId"
                        ></app-select>

                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12">
                      <app-select
                        [readonly]="readonly"
                        [items]="products"
                        [bindLabel]="'productName'"
                        [bindValue]="'id'"
                        [label]="'pages.product.product-name' | translate"
                        formControlName="productId"
                      ></app-select>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12">
                        <app-select
                          [readonly]="readonly"
                          [items]="reportTypes"
                          [bindLabel]="'key'"
                          [bindValue]="'value'"
                          [label]="'pages.item-group.report-type' | translate"
                          formControlName="reportType"
                        ></app-select>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12">
                      <div><mat-checkbox (change)="setValueCheckbox('hasStandardPrice', $event)" ><b>Show Standard Price Column</b></mat-checkbox></div>
                      <div><mat-checkbox (change)="setValueCheckbox('hasBuyPrice', $event)" ><b>Show Buy Price Column</b></mat-checkbox></div>
                      <div><mat-checkbox (change)="setValueCheckbox('hasSellPrice', $event)"><b>Show Current Stock Column</b></mat-checkbox></div>
                      <div><mat-checkbox (change)="setValueCheckbox('hasRemindStockWhen', $event)" ><b>Show Remind Stock Column</b></mat-checkbox></div>
                      <div><mat-checkbox (change)="setValueCheckbox('hasSupplier', $event)" ><b>Show Supplier Column</b></mat-checkbox></div>
                      <div><mat-checkbox (change)="setValueCheckbox('hasCurrentStock', $event)"><b>Show Current Stock Column</b></mat-checkbox></div>
                    </div>
                  </div>
                  <div class="row my-4">
                    <div class="col-12 py-5">
                      <button class="btn btn-primary">{{'common.download' | translate}}</button>
                    </div>

                  </div>
                </form>
              </mat-tab>
            </mat-tab-group>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
