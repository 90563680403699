import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageQueryInterface, PageInterface } from 'src/app/interfaces/page.interface';

@Injectable({providedIn: 'root'})
export abstract class BasePageService<T> {

  abstract baseUrl: string;

  protected constructor(public http: HttpClient) {
  }
  async findAll(p: PageQueryInterface): Promise<PageInterface>{
    return await this.http.get<PageInterface>(this.baseUrl + `?page=${p.pageNo}&limit=${p.pageSize}&search-term=${p.searchTerm}`,
        {}).toPromise();
  }

  async findAllNoPaging(): Promise<T[]>{
    return await this.http.get<T[]>(this.baseUrl + `/all`, {}).toPromise();
  }

  async findOne(id: number): Promise<T>{
    return await this.http.get<T>(this.baseUrl + `/one/${id}`, {}).toPromise();
  }

  async save(d: T): Promise<T>{
    return await this.http.post<T>(this.baseUrl, d , {}).toPromise();
  }

  async update(d: T): Promise<T>{
    return await this.http.put<T>(this.baseUrl, d , {}).toPromise();
  }

  async delete(id: number): Promise<any>{
    return await this.http.delete<any>(this.baseUrl + `/one/${id}`, {}).toPromise();
  }
}
