<div class="white-background p-5">
  <mat-dialog-content style="width: 600px;">
    <div class="row mt-3">
      <div class="col-12">
        <app-upload-single
          [readonly]="readonly"
          [fileUrl]="fileUrl"
          [accept]="'application/vnd.ms-excel'"
          [label]="'pages.area.image' | translate"
          [labelButton]="'pages.area.image-button' | translate">
          (fileChange)="getFileChange($event)"
        </app-upload-single>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions  class="justify-content-end pe-4">
    <button mat-raised-button class="py-1" color="primary">{{'common.upload' | translate}}</button>
    <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
  </mat-dialog-actions>
</div>
