export const MENU_ITEMS = [
  {
    id: 'dashboard',
    group: 'DASHBOARD',
    label: 'menu.dashboard.index',
    icon: 'article',
    color: '#80deea',
    hasChildMenu: false,
    externalURL: false,
    url: 'dashboard',
    childMenu: [],
  },
  {
    id: 'master-data',
    group: 'MASTER-DATA',
    label: 'menu.master.index',
    icon: 'article',
    color: '#80deea',
    hasChildMenu: true,
    externalURL: false,
    childMenu: [
      {
        id: 'master-storage',
        label: 'menu.master.storage',
        icon: 'request_quote',
        color: '#80deea',
        url: 'master/storage',
      },
      {
        id: 'master-area',
        label: 'menu.master.area',
        icon: 'account_balance',
        color: '#80deea',
        url: 'master/area',
      },
      {
        id: 'master-margin',
        label: 'menu.master.margin',
        icon: 'tag',
        color: '#80deea',
        url: 'master/margin',
      },
      {
        id: 'master-product',
        label: 'menu.master.product',
        icon: 'tag',
        color: '#80deea',
        url: 'master/product',
      },
      {
        id: 'master-parameter',
        label: 'menu.master.parameter',
        icon: 'tag',
        color: '#80deea',
        url: 'master/parameter',
      },
    ],
  },
  {
    id: 'item-group',
    group: 'ITEM-GROUP',
    label: 'menu.item-group.index',
    icon: 'article',
    color: '#80deea',
    hasChildMenu: true,
    externalURL: false,
    childMenu: [
      {
        id: 'item-group',
        label: 'menu.item-group.index',
        icon: 'upcoming',
        color: '#80deea',
        url: 'item-group/item-group',
      },
      {
        id: 'item-group-report',
        label: 'menu.item-group.report',
        icon: 'upcoming',
        color: '#80deea',
        url: 'item-group/report',
      },
    ],
  },
  {
    id: 'suppliers',
    group: 'SUPPLIER',
    label: 'menu.supplier.index',
    icon: 'article',
    color: '#80deea',
    hasChildMenu: true,
    externalURL: false,
    childMenu: [
      {
        id: 'supplier',
        label: 'menu.supplier.index',
        icon: 'upcoming',
        color: '#80deea',
        url: 'supplier/supplier',
      },
      {
        id: 'supplier-order',
        label: 'menu.supplier.supplier-order',
        icon: 'upcoming',
        color: '#80deea',
        url: 'supplier/order',
      },
      {
        id: 'supplier-order-receiving',
        label: 'menu.supplier.order-receiving',
        icon: 'upcoming',
        color: '#80deea',
        url: 'supplier/receiving-order',
      },
    ],
  },
  {
    id: 'shipping',
    group: 'SHIPPING',
    label: 'menu.shipping.index',
    icon: 'article',
    color: '#80deea',
    hasChildMenu: true,
    externalURL: false,
    childMenu: [
      {
        id: 'shipping-hq',
        label: 'menu.shipping.distribute',
        icon: 'upcoming',
        color: '#80deea',
        url: 'supply/head-quarter',
      },
      {
        id: 'shipping-receiving',
        label: 'menu.shipping.receiving',
        icon: 'upcoming',
        color: '#80deea',
        url: 'supply/branches',
      },
    ],
  },
  {
    id: 'customers',
    group: 'CUSTOMER',
    label: 'menu.customer.index',
    icon: 'article',
    color: '#80deea',
    hasChildMenu: true,
    externalURL: false,
    childMenu: [
      {
        id: 'customer',
        label: 'menu.customer.index',
        icon: 'upcoming',
        color: '#80deea',
        url: 'customer/customer',
      },
      {
        id: 'customer-order',
        label: 'menu.customer.order',
        icon: 'upcoming',
        color: '#80deea',
        url: 'customer/order',
      },
      {
        id: 'customer-order-receiving',
        label: 'menu.customer.order-receiving',
        icon: 'upcoming',
        color: '#80deea',
        url: 'customer/order-receiving',
      },
    ],
  },
  {
    id: 'audit',
    group: 'AUDIT',
    label: 'menu.audit.index',
    icon: 'article',
    color: '#80deea',
    hasChildMenu: true,
    externalURL: false,
    childMenu: [
      {
        id: 'storage-audit',
        label: 'menu.audit.storage-audit',
        icon: 'upcoming',
        color: '#80deea',
        url: 'audit/storage',
      },
    ],
  },
  {
    id: 'web-config',
    group: 'WEB-CONFIG',
    label: 'menu.web.index',
    icon: 'article',
    color: '#80deea',
    hasChildMenu: true,
    externalURL: false,
    childMenu: [
      {
        id: 'web-banner',
        label: 'menu.web.banner',
        icon: 'upcoming',
        color: '#80deea',
        url: 'web-config/banner',
      },
      {
        id: 'web-parameter',
        label: 'menu.web.parameter',
        icon: 'upcoming',
        color: '#80deea',
        url: 'web-config/parameter',
      },
      {
        id: 'web-product',
        label: 'menu.web.product',
        icon: 'upcoming',
        color: '#80deea',
        url: 'web-config/product',
      },
    ],
  },
];
