import {
  ERROR_BANK_ACCOUNT_NOT_FOUND,
  ERROR_BANK_ACCOUNT_NOT_VERIFIED,
  ERROR_COULD_NOT_CREATE_TOKEN,
  ERROR_EMAIL_NOT_FOUND,
  ERROR_INVALID_CREDENTIAL,
  ERROR_INVALID_EMAIL_ADDRESS, ERROR_INVALID_INPUT,
  ERROR_INVALID_VERIFY_TOKEN, ERROR_OLD_NEW_SIMILAR, ERROR_PASSWORD_NOT_MATCH,
  ERROR_VALIDATION,
  ERROR_WALLET_NOT_FOUND,
  ERROR_WITHDRAW_AMOUNT_GREATER_THAN_BALANCE,
  ERROR_WITHDRAW_AMOUNT_LESS_THAN_HUNDRED_THOUSAND
} from '../constants/error.constant';
import { saveAs } from 'file-saver';

export class UiHelper {
  static isLargeScreen(): boolean {
    return false;
  }
  static isLargeScreenReal(): boolean {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return (width > 720);
  }
  static downloadFile(file: any, fileName: string): void {
    const name = fileName.split('.');
    let fileType = 'image/jpeg';
    switch (name[1]) {
      case 'png': fileType = 'image/png'; break;
      case 'jpg': fileType = 'image/jpeg'; break;
      case 'jpeg': fileType = 'image/jpeg'; break;
      case 'pdf': fileType = 'application/pdf'; break;
    }

    const blob = new Blob([file.body], {type: fileType});
    saveAs(blob, fileName);
  }
  static checkHeight(): string {
    return window.innerHeight + ' ' + window.innerWidth;
  }
  static absoluteUrl(url): string {
    return `/${url}`;
  }
  static currencyFormat(numbers: number, locale: string): string {
    return new Intl.NumberFormat(locale, {}).format(Math.round(numbers));
  }
  static toInteger(numbers: string): number {
    return parseInt(numbers, 10);
  }
  static moneyFormatToNumber(money: any): number {
    if (!money) { return 0; }
    if (typeof money === 'number') { return money; }
    return parseFloat(money.split(',').join(''));
  }

  static errorMessageTransform(key: string): string {
    switch (key) {
      case 'invalid_credential': {
        return ERROR_INVALID_CREDENTIAL;
      }
      case 'email_not_found': {
        return ERROR_EMAIL_NOT_FOUND;
      }
      case 'could-not-create-token': {
        return ERROR_COULD_NOT_CREATE_TOKEN;
      }
      case 'invalid_verify_token': {
        return ERROR_INVALID_VERIFY_TOKEN;
      }
      case 'invalid_email_address': {
        return ERROR_INVALID_EMAIL_ADDRESS;
      }
      case 'error_validation': {
        return ERROR_VALIDATION;
      }
      case 'invalid_amount_gt': {
        return ERROR_WITHDRAW_AMOUNT_GREATER_THAN_BALANCE;
      }
      case 'unverified_bank_profile': {
        return ERROR_BANK_ACCOUNT_NOT_VERIFIED;
      }
      case 'wallet_not_found': {
        return ERROR_WALLET_NOT_FOUND;
      }
      case 'invalid_amount_100k': {
        return ERROR_WITHDRAW_AMOUNT_LESS_THAN_HUNDRED_THOUSAND;
      }
      case 'invalid_bank_profile': {
        return ERROR_BANK_ACCOUNT_NOT_FOUND;
      }
      case 'old_new_similar': {
        return ERROR_OLD_NEW_SIMILAR;
      }
      case 'invalid_input': {
        return ERROR_INVALID_INPUT;
      }
      case 'password_not_match': {
        return ERROR_PASSWORD_NOT_MATCH;
      }
    }
  }
}
