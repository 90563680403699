<div class="white-background p-5">
  <form [formGroup]="form" (submit)="save()">
    <h4 class="mat-dialog-title px-4" *ngIf="mode == 'create'">{{'pages.margin.margin-editor' | translate}}</h4>
    <h2 class="mat-dialog-title px-4" *ngIf="mode == 'edit' || mode == 'view'">{{'pages.margin.margin-editor' | translate}} #{{margin.id}}</h2>
    <mat-dialog-content style="width: 600px;">

      <div class="row">
        <div class="col-12">
          <app-text-field
            [readonly]="readonly"
            [label]="'pages.margin.margin-name' | translate"
            formControlName="marginName"></app-text-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
         <app-select
           [readonly]="readonly"
           [items]="areas"
           [bindLabel]="'areaName'"
           [bindValue]="'id'"
           [label]="'pages.storage.area' | translate"
           formControlName="areaId"></app-select>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
         <app-select
           [readonly]="readonly"
           [items]="subAreas"
           [bindLabel]="'subAreaName'"
           [bindValue]="'id'"
           [label]="'pages.storage.sub-area' | translate"
           formControlName="subAreaId"></app-select>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <app-number-field
            [readonly]="readonly"
            [label]="'pages.margin.margin' | translate"
            formControlName="margin" ></app-number-field>
        </div>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions  class="justify-content-end pe-4">
      <button *ngIf="!viewMode" mat-raised-button class="py-1" color="primary">{{'common.save' | translate}}</button>
      <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
    </mat-dialog-actions>
  </form>
</div>
