import {catchError, Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorStatuses = [401, 0]; // 401 : unauthorized, 0: unknown error
        const err = errorStatuses.includes(error.status);
        console.log(err, error.status, errorStatuses);
        if (err) {
          localStorage.clear();
        }
        return throwError(() => error);
      })
    );
  }
}
