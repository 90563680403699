import { Component, OnInit, Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CRUD_MODE_CREATE, CRUD_MODE_EDIT, CRUD_MODE_VIEW } from 'src/app/constants/common.constant';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BasePageComponentDirective } from 'src/app/shared/base/base-page.component';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { environment } from 'src/environments/environment';
import { ProductModal } from '../modals/product.modal';
import { ProductModel } from '../models/product.model';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-page-product',
  templateUrl: './product.page.html',
})
export class ProductPageComponent extends BasePageComponentDirective<ProductModel> implements OnInit{
  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.query();

    this.breadcrumbService.changeMessage([
      {label: 'menu.master.index', active: false, route: ''},
      {label: 'pages.product.product', active: true, route: ''}
    ]);
  }

  query(): void {
    this.productService.findAll(this.pageQuery).then((res) => {
      this.page.emit(res);
    });
  }

  create(): void {
    const dialog = this.dialog.open(ProductModal, {
      data: {
        mode: CRUD_MODE_CREATE
      }
    });

    dialog.componentInstance.result.subscribe((res) => {
      if (res === 1) {
        this.query();
      }
    });
  }

  edit(d: ProductModel): void {
    this.productService.findOne(d.id).then(product => {
      product.imageName = (product.imageName) ? environment.storage + '/images/product/' + product.imageName : null;
      const dialog = this.dialog.open(ProductModal, {
        data: {
          product,
          mode: CRUD_MODE_EDIT
        },
      });

      dialog.componentInstance.result.subscribe((res) => {
        if (res === 1) {
          this.query();
        }
      });
    });

  }

  view(d: ProductModel): void {
    this.productService.findOne(d.id).then(product => {
      product.imageName = (product.imageName) ? environment.storage + '/images/product/' + product.imageName : null;
      this.dialog.open(ProductModal, {
        data: {
          product,
          mode: CRUD_MODE_VIEW
        }
      });
    });

  }

  delete(d: ProductModel): void {
    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.delete-message'),
        subtitle: this.translateService.instant('dialog.delete-title'),
        content: `#${d.id} - ${d.productName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-danger', color: 'primary', focus: true, label: this.translateService.instant('common.delete')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.productService.delete(d.id).then(() => {
          this.snackBar.open(this.translateService.instant('dialog.delete-success'), undefined, {
            verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
          });
          this.query();
        });
      }
    });
  }

  detail(d: ProductModel): void {
    this.router.navigate(['master/product/' + d.id]).then();
  }


}
