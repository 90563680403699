import { Component, OnInit, Injector, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseComponentDirective } from 'src/app/shared/base/base.component';
import { ItemModel } from '../models/item.model';
import { ItemService } from '../services/item.service';
import { CRUD_MODE_CREATE, CRUD_MODE_EDIT, CRUD_MODE_VIEW, UNIT_LIST } from 'src/app/constants/common.constant';
import { DialogScrollComponent } from 'src/app/shared/components/dialog/dialog-scroll.component';
import { AreaModel } from 'src/app/pages/master/area/models/area.model';
import { ProductModel } from 'src/app/pages/master/product/models/product.model';
import { ProductSubModel } from 'src/app/pages/master/product-sub/models/product-sub.model';
import { StorageModel } from 'src/app/pages/master/storage/models/storage.model';
import { MarginModel } from 'src/app/pages/master/margin/models/margin.model';
import { AreaService } from 'src/app/pages/master/area/services/area.service';
import { ProductService } from 'src/app/pages/master/product/services/product.service';
import { StorageService } from 'src/app/pages/master/storage/services/storage.service';
import { MarginService } from 'src/app/pages/master/margin/services/margin.service';
import { ProductSubService } from 'src/app/pages/master/product-sub/services/product-sub.service';
import { SupplierModel } from 'src/app/pages/supplier/supplier/models/supplier.model';
import { SupplierService } from 'src/app/pages/supplier/supplier/services/supplier.service';

@Component({
  selector: 'app-modal-item',
  templateUrl: './item.modal.html',
})
export class ItemModal extends BaseComponentDirective implements OnInit{
  public result = new EventEmitter();
  public form: FormGroup;
  public mode = '';
  public item: ItemModel;
  public viewMode = false;

  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;
  units = UNIT_LIST;

  areas: AreaModel[] = [];
  products: ProductModel;
  productSubs: ProductSubModel;
  storages: StorageModel[] = [];
  suppliers: SupplierModel[] = [];
  margins: MarginModel[] = [];

  newCode = '';

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ItemModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private itemService: ItemService,
    private dialog: MatDialog,
    private areaService: AreaService,
    private productService: ProductService,
    private productSubService: ProductSubService,
    private storageService: StorageService,
    private marginService: MarginService,
    private supplierService: SupplierService
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      itemName: [null, Validators.required],
      sku: [null, Validators.required],
      areaId: [null, Validators.required],
      productId: [null, Validators.required],
      subProductId: [null, Validators.required],
      storageId: [null],
      supplierId: [null, Validators.required],
      marginId: [null, Validators.required],
      marginPercentage: [null],
      unit: [null],
      standardPrice: [0, Validators.required],
      sellPrice: [0, Validators.required],
      buyPrice: [0, Validators.required],
      currentStock: [0, Validators.required],
      remindStockWhen: [0, Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.item = this.data.item as ItemModel;
    this.mode = this.data.mode;

    this.form.controls.sellPrice.disable({onlySelf: true});
    this.form.controls.sku.disable({onlySelf: true});
    this.form.controls.itemName.disable({onlySelf: true});
    this.form.controls.areaId.disable({onlySelf: true});
    this.form.controls.storageId.disable({onlySelf: true});
    this.form.controls.marginPercentage.disable({onlySelf: true});
    this.form.controls.productId.disable({onlySelf: true});
    this.form.controls.subProductId.disable({onlySelf: true});
    this.form.controls.unit.disable({onlySelf: true});
    this.form.controls.supplierId.disable({onlySelf: true});
    this.form.controls.buyPrice.disable({onlySelf: true});
    this.form.controls.standardPrice.disable({onlySelf: true});

    this.areas    = await this.areaService.findAllNoPaging() as AreaModel[];
    this.margins  = await this.marginService.findAllNoPaging() as MarginModel[];
    this.products = await this.productService.findOne(this.data.itemGroup.productId) as ProductModel;
    this.productSubs = await this.productSubService.findOne(this.data.itemGroup.subProductId) as ProductSubModel;
    this.suppliers = await this.supplierService.findAllNoPaging() as SupplierModel[];
    this.storages = await this.storageService.findAllNoPaging() as StorageModel[];
    this.form.controls.productId.setValue(this.products.productName);
    this.form.controls.subProductId.setValue(this.productSubs.subProductName);
    this.form.controls.unit.setValue(this.data.itemGroup.unit);
    this.form.controls.standardPrice.valueChanges.subscribe(async standardPrice => {
      const margin = this.margins.find(m => m.id === this.form.controls.marginId.value);
      this.form.controls.sellPrice.setValue(
          (((margin.margin) / 100) * this.ui.moneyFormatToNumber(standardPrice)) + this.ui.moneyFormatToNumber(standardPrice));
    });

    this.form.controls.marginId.valueChanges.subscribe(async m => {
      if (m) {
        const optMargin = this.margins.find(margin => margin.id == m);
        this.form.controls.marginPercentage.setValue(optMargin.margin);
        const optArea = this.areas.find(area => area.id == optMargin.areaId);
        if (optArea) {
          this.form.controls.areaId.setValue(optArea.id);
          this.form.controls.storageId.setValue(optArea.storageId);
        }
      }else {
        this.form.controls.marginPercentage.setValue(null);
        this.form.controls.areaId.setValue(null);
        this.form.controls.storageId.setValue(null);
      }
    });

    switch (this.mode) {
      case CRUD_MODE_EDIT: {
        console.log(this.item);
        this.form.controls.sku.setValue(this.item.sku);

        this.fillForm();
        break;
      }
      case CRUD_MODE_VIEW: {
        this.readonly = true;
        this.viewMode = true;
        this.fillForm();
        break;
      }
      default: {

        const items = await this.itemService.findAllNoPaging(this.data.itemGroupId);
        if (items && items.length > 0) {
          const itemLast = items[items.length - 1];

          const codeLast = itemLast.sku.split('-');
          const codeLastParsed = '' + parseInt(codeLast[1], 10);
          console.log(codeLast, codeLastParsed);
          let newCode  = '';
          switch (codeLastParsed.length) {
            case 1: newCode = '000' + (parseInt(codeLast[1], 10) + 1); break;
            case 2: newCode = '00' + (parseInt(codeLast[1], 10) + 1); break;
            case 3: newCode = '0' + (parseInt(codeLast[1], 10) + 1); break;
            case 4: newCode = '' + (parseInt(codeLast[1], 10) + 1); break;
          }

          this.newCode = (this.data.itemGroupCode + '-' + newCode);
          this.form.controls.sku.setValue(this.newCode);
          this.form.controls.itemName.setValue(this.data.itemGroupName);
          this.form.controls.sku.setValue(this.newCode);

        }else {
          this.newCode = (this.data.itemGroupCode + '-' + '0001');
          this.form.controls.sku.setValue(this.newCode);
          this.form.controls.itemName.setValue(this.data.itemGroupName);
        }
      }
    }
  }

  fillForm(): void {
    const mx = this.margins.find(m => m.id === parseInt(this.item.marginId, 10));
    this.form.setValue({
      sku: (this.newCode === '') ? this.item.sku : this.newCode,
      itemName: this.data.itemGroupName,
      areaId: parseInt(this.item.areaId, 10),
      productId: this.products.productName,
      subProductId: this.productSubs.subProductName,
      storageId: parseInt(this.item.storageId, 10),
      supplierId: parseInt(this.data.itemGroup.supplierId, 10),
      marginId: parseInt(this.item.marginId, 10),
      standardPrice: this.data.itemGroup.standardPrice,
      marginPercentage: mx.margin,
      unit: this.data.itemGroup.unit,
      sellPrice: this.item.sellPrice,
      buyPrice: this.data.itemGroup.buyPrice,
      currentStock: parseInt(this.item.currentStock, 10),
      remindStockWhen: parseInt(this.item.remindStockWhen, 10)
    });
  }

  save(): void {
    if (!this.form.valid) {
      this.markFormGroupAsTouched(this.form);
      return;
    }

    const payload = {
      id: (this.mode === CRUD_MODE_EDIT) ? this.item.id : null,
      itemName: this.form.controls.itemName.value,
      itemGroupId: this.data.itemGroupId,
      sku: this.form.controls.sku.value,
      areaId: this.form.controls.areaId.value,
      productId: this.products.id,
      subProductId: this.productSubs.id,
      storageId: this.form.controls.storageId.value,
      marginId: this.form.controls.marginId.value,
      standardPrice: this.ui.moneyFormatToNumber(this.form.controls.standardPrice.value),
      sellPrice: this.ui.moneyFormatToNumber(this.form.controls.sellPrice.value),
      buyPrice: this.ui.moneyFormatToNumber(this.form.controls.buyPrice.value),
      currentStock: this.ui.moneyFormatToNumber(this.form.controls.currentStock.value),
      remindStockWhen: this.ui.moneyFormatToNumber(this.form.controls.remindStockWhen.value)
    };

    const isCreateMode = this.mode === CRUD_MODE_CREATE;

    const dialog = this.dialog.open(DialogScrollComponent,  {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: (isCreateMode) ? `#${payload.itemName}` : `#${payload.id} - ${payload.itemName}`,
        buttons: [
          {value: 1, className: 'text-red btn btn-primary', color: 'primary', focus: true, label: this.translateService.instant('common.save')},
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        if (isCreateMode) {
          this.itemService.save(payload).then((_) => {
            this.doAfterSave(res.id);
          });
        } else {
          this.itemService.update(payload).then((_) => {
            this.doAfterSave(payload.id);
          });
        }
      }
    });
  }

  doAfterSave(id: number): void {
    if (this.fd) {
      this.uploadFile(id).then();
    }

    this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
      verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
    });
    this.result.emit(1);
    this.dialogRef.close();
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  async uploadFile(id: number): Promise<any> {
    return await this.itemService.uploadFile(this.fd, id);
  }
}
