<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card m- white-background text-black-50">

      <div class="mat-card-content px-3 mt-3 py-5">

        <mat-accordion class="example-headers-align" multi >
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'pages.audit.audit-info' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="form">
              <div class="row mt-3">
                <div class="col-4">
                  <app-text-field
                    [readonly]="true"
                    [label]="'pages.storage.storage-name' | translate"
                    formControlName="storageName"></app-text-field>
                </div>
                <div class="col-4">
                  <app-text-field
                    [readonly]="true"
                    [label]="'pages.storage.description' | translate"
                    formControlName="description"></app-text-field>
                </div>
                <div class="col-4">
                  <app-text-field
                    [readonly]="true"
                    [label]="'pages.area.area-name' | translate"
                    formControlName="areaName"></app-text-field>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-4">
                  <app-datepicker
                    [readonly]="isCreated"
                    formControlName="auditStart"
                    [label]="'pages.audit.audit-start' | translate"></app-datepicker>
                </div>
                <div class="col-4" *ngIf="isCreated">
                  <app-datepicker
                    formControlName="auditEnd"
                    [label]="'pages.audit.audit-end' | translate"></app-datepicker>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-4">
                  <app-area-field
                    formControlName="note"
                    [label]="'pages.audit.note' | translate"></app-area-field>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12 justify-content-end text-end">
                  <button
                    *ngIf="!isCreated"
                    (click)="save()"
                    mat-flat-button
                    color="primary"
                    class="text-white">{{'common.create' | translate}}</button>
                </div>
              </div>
            </form>

          </mat-expansion-panel>
        </mat-accordion>

      </div>
    </div>
  </div>
</div>
