import {Component, Injector, OnInit} from '@angular/core';
import {BaseComponentDirective} from '../../shared/base/base.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../services/login.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {
  DEFAULT_LANGUAGE,
  FORM_GROUP_VALID,
  JWT_TOKEN_KEY,
  STORAGE_LANG_KEY,
  STORAGE_PROFILE_KEY
} from '../../constants/common.constant';
import {ErrorInterface} from '../../interfaces/common.interface';
import {MAIN_LANDING_PAGE, NEW_LOGIN_PAGE} from '../../constants/route.constant';
import {
  ERROR_COULD_NOT_CREATE_TOKEN,
  ERROR_EMAIL_NOT_FOUND,
  ERROR_INVALID_CREDENTIAL,
  ERROR_UNKNOWN_OR_NETWORK
} from '../../constants/error.constant';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-page-new-login',
  templateUrl: './new-login.page.html',
})
export class NewLoginPageComponent extends BaseComponentDirective implements OnInit{
  public loginFG: FormGroup;
  public flagValidForm = FORM_GROUP_VALID;
  public profile: any = {};
  public loggedInError = '';
  public errorPayload: ErrorInterface[] = [];
  private isAuth = false;
  public togglePassword = false;
  public height = '';
  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private jwtHelper: JwtHelperService
  ) {
    super(injector);
    this.loginFG = this.formBuilder.group({
      usernameFC: ['', [Validators.required, Validators.maxLength(254)]],
      passwordFC: ['', [Validators.required]]
    });
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.isAuth = !this.jwtHelper.isTokenExpired();
    if (this.isAuth) {
      this.router.navigate([MAIN_LANDING_PAGE]).then(() => {});
    }else{
      localStorage.clear();
      localStorage.setItem(STORAGE_LANG_KEY, DEFAULT_LANGUAGE);
      this.router.navigate([NEW_LOGIN_PAGE]).then(() => {});
    }
  }
  signIn(): void {
    this.loggedInError = '';
    this.errorPayload = [];
    if (this.loginFG.status === FORM_GROUP_VALID) {
      this.loginService.verify(this.loginFG.controls.usernameFC.value, this.loginFG.controls.passwordFC.value)
        .then((res) => {
          console.log(res);
          localStorage.setItem(JWT_TOKEN_KEY, res.access_token);
          this.currentUser();
        }, async (err) => {
          if (err.error.error) {
            switch (this.ui.errorMessageTransform(err.error.error)) {
              case ERROR_EMAIL_NOT_FOUND: {
                this.errorPayload.push({
                  hasError: true,
                  message: ERROR_EMAIL_NOT_FOUND,
                  input: 'email'
                });
                this.loginFG.controls.usernameFC.hasError('any');
                break;
              }
              case ERROR_INVALID_CREDENTIAL: {
                this.errorPayload.push({
                  hasError: true,
                  message: ERROR_INVALID_CREDENTIAL,
                  input: 'password'
                });
                break;
              }
              case ERROR_COULD_NOT_CREATE_TOKEN: {
                const message = await this.label.getLabel(this.translateService, ERROR_COULD_NOT_CREATE_TOKEN);
                this.snackBar.open(message as string, '', {duration: 2000});
                break;
              }
              default: {
                const message = await this.label.getLabel(this.translateService, ERROR_UNKNOWN_OR_NETWORK);
                this.snackBar.open(message as string, '', {duration: 2000});
                break;
              }
            }
          }else {
            const message = await this.label.getLabel(this.translateService, ERROR_UNKNOWN_OR_NETWORK);
            this.snackBar.open(message as string, '', {duration: 2000});
          }
        });
    }
  }
  currentUser(): void {
    this.loginService.getProfile().then((res) => {
      this.profile = res;
      localStorage.setItem(STORAGE_PROFILE_KEY, JSON.stringify(this.profile));
      window.location.href = environment.feUrl + this.ui.absoluteUrl(MAIN_LANDING_PAGE);
    }, (err) => {
      console.log(err);
    });
  }
}
