<div class="white-background p-5">
    <form [formGroup]="form" (submit)="save()">
      <h4 class="mat-dialog-title px-4" *ngIf="mode == 'create'">{{'pages.customer.customer-editor' | translate}}</h4>
      <h2 class="mat-dialog-title px-4" *ngIf="mode == 'edit' || mode == 'view'">{{'pages.customer.customer-editor' | translate}} #{{customer.id}}</h2>
      <mat-dialog-content style="width: 600px;">

        <div class="row">
          <div class="col-12">
            <app-text-field
              [readonly]="readonly"
              [label]="'pages.customer.customer-name' | translate"
              formControlName="customerName"></app-text-field>
          </div>
        </div>

        <div class="row">
            <div class="col-12">
                <app-text-field
                  [readonly]="readonly"
                  [label]="'pages.customer.contact-person' | translate"
                  formControlName="contactPerson"></app-text-field>
            </div>

        </div>
        <div class="row">
          <div class="col-6">
            <app-text-field
              [readonly]="readonly"
              [label]="'pages.customer.phone-number' | translate"
              formControlName="phoneNumbers"></app-text-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <app-select
              [readonly]="readonly"
              [items]="areas"
              [bindLabel]="'areaName'"
              [bindValue]="'id'"
              [label]="'pages.storage.area' | translate"
              formControlName="areaId"></app-select>
          </div>
          <div class="col-6">
            <app-select
              [readonly]="readonly"
              [items]="subAreas"
              [bindLabel]="'subAreaName'"
              [bindValue]="'id'"
              [label]="'pages.storage.sub-area' | translate"
              formControlName="subAreaId"></app-select>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
              <app-area-field
                [cols]="10"
                [rows]="5"
                [readonly]="readonly"
                [label]="'pages.customer.address' | translate"
                formControlName="address"></app-area-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-upload-single
              [readonly]="readonly"
              [fileUrl]="fileUrl"
              [accept]="'image/*'"
              [label]="'pages.customer.image' | translate"
              [labelButton]="'pages.customer.image-button' | translate" (fileChange)="getFileChange($event)">

            </app-upload-single>
          </div>
        </div>


      </mat-dialog-content>
      <mat-dialog-actions  class="justify-content-end pe-4">
        <button *ngIf="!viewMode" mat-raised-button class="py-1" color="primary">{{'common.save' | translate}}</button>
        <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
      </mat-dialog-actions>
    </form>
  </div>
