import {Component, Injector, OnInit} from '@angular/core';
import {ProductModel} from '../../../master/product/models/product.model';
import {ProductSubModel} from '../../../master/product-sub/models/product-sub.model';
import {SupplierModel} from '../../../supplier/supplier/models/supplier.model';
import {environment} from '../../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {BreadcrumbService} from '../../../../services/breadcrumb.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {REPORT_TYPES} from '../../../../constants/common.constant';
import {StorageModel} from '../../../master/storage/models/storage.model';
import {StorageService} from '../../../master/storage/services/storage.service';
import {ItemGroupService} from '../services/item-group.service';
import {AreaModel} from '../../../master/area/models/area.model';
import {AreaService} from '../../../master/area/services/area.service';
import {ProductService} from '../../../master/product/services/product.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import { BaseComponentDirective } from '../../../../shared/base/base.component';

@Component({
  selector: 'app-report-item-group',
  templateUrl: './item-group.report.html',
})
export class ItemGroupReportComponent extends BaseComponentDirective implements OnInit {

  products: ProductModel[] = [];
  subProducts: ProductSubModel[] = [];
  suppliers: SupplierModel[] = [];
  itemImageBaseURL = environment.storage + '/images/item-group/';
  form: FormGroup;
  reportTypes = REPORT_TYPES;
  storages: StorageModel[] = [];
  areas: AreaModel[] = [];

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private areaService: AreaService,
    private itemGroupService: ItemGroupService,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private productService: ProductService,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.breadcrumbService.changeMessage([
      {label: 'menu.item-group.index', active: false, route: ''},
      {label: 'pages.item-group.report', active: true, route: ''}
    ]);

    this.form = this.formBuilder.group({
      areaId: [null, [Validators.required]],
      reportType: [null, [Validators.required]],
      productId: [null],
      hasBuyPrice: [false],
      hasRemindStockWhen: [false],
      hasSupplier: [false],
      hasCurrentStock: [false],
      hasStandardPrice: [false],
    });
    this.storages = await this.storageService.findAllNoPaging();
    this.areas = await this.areaService.findAllNoPaging();
    this.products = await this.productService.findAllNoPaging();
  }

  setValueCheckbox(cb: string, $event: MatCheckboxChange): void {
    this.form.controls[cb].setValue($event.checked);
  }

  save(): void {
    const storage = this.areas.find(a => a.id === parseInt(this.form.controls.areaId.value, 10));
    this.itemGroupService.reportByStorage(
      storage.id,
      this.form.controls.areaId.value,
      this.form.controls.reportType.value,
      this.form.controls.productId.value,
      {
        hasBuyPrice: (this.form.controls.hasBuyPrice.value) ? 1 : 0,
        hasRemindStockWhen: (this.form.controls.hasRemindStockWhen.value) ? 1 : 0,
        hasSupplier: (this.form.controls.hasSupplier.value) ? 1 : 0,
        hasCurrentStock: (this.form.controls.hasCurrentStock.value) ? 1 : 0,
        hasStandardPrice: (this.form.controls.hasStandardPrice.value) ? 1 : 0,
      }
    ).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      switch (this.form.controls.reportType.value) {
        case 'pdf': {
          a.download = 'report-item' + '.pdf';
          break;
        }
        default: {
          a.download = 'report-item' + '.xlsx';
          break;
        }

      }
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching image:', error);
    });
  }
}
