<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card white-background text-black-50 rounded-lg mb-4 pb-5">
      <div class="mat-card-title px-3 py-3">
        <h3 class="pl-2">{{ 'pages.parameter.parameter' | translate }}</h3>
      </div>
      <div class="mat-card-content px-3 rounded">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-12">
              <app-text-field
                [label]="'pages.parameter.parameter-name' | translate"
                [readonly]="true"
                formControlName="parameterName" />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <app-area-field
                [cols]="10"
                [label]="'pages.parameter.description' | translate"
                [readonly]="true"
                [rows]="2"
                formControlName="description" />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button (click)="openEditParameterDialog()" class="btn btn-warning float-end"
                      type="button">{{ 'pages.parameter.edit-parameter' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="mat-card white-background text-black-50 rounded-lg">
      <div class="mat-card-title px-3 py-3">
        <h3 class="pl-2">{{ 'pages.parameter.details' | translate }}</h3>
      </div>
      <x-table
        (onAddButtonClick)="openCreateParameterDetailDialog()"
        (onQueryChange)="changeQuery($event)"
        [buttons]="buttons"
        [columns]="columns"
        [data]="pageItems"
        [hasNumbering]="false"
        [query]="currentQuery"
        [rawData]="currentRawData"
      />
    </div>
  </div>
</div>
