<aside class="sidenav">
  <mat-toolbar class="toolbar-top">
    <div class="toolbar-top-container">

      <div class="logo">
        <button id="btn-toggle-icon" class="sidenav-toggle" mat-icon-button (click)="isExpanded = !isExpanded">
          <mat-icon id="toggle-icon" class="text-white">reorder</mat-icon>
        </button>

      </div>
      <div class="toggler" >
        <button class="menu-label" mat-button [matMenuTriggerFor]="userProfile">
          {{profile.name}}
        </button>
        <button class="menu-label" mat-button [matMenuTriggerFor]="menu">
          <span>{{currentLang}}</span>
        </button>
        <mat-menu #menu>
          <button *ngFor="let lang of languages" mat-menu-item (click)="changeLanguage(lang.langCode)">{{ lang.langValue }}</button>
        </mat-menu>
        <mat-menu #userProfile>
          <button mat-menu-item (click)="changePassword()">{{'common.change-password' | translate}}</button>
          <button mat-menu-item (click)="logout()">{{'common.logout' | translate}}</button>
        </mat-menu>
        <button class="clear-transparent" [routerLink]="[this.notificationUrl]"  mat-icon-button>
          <span class="icon-notification"></span>
        </button>

      </div>
    </div>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container" autosize>
    <mat-sidenav class="mat-sidenav" [position]="'start'" [mode]="'side'" #sidenav
                [opened]="isExpanded">
      <app-sidenav-item (clicked)="isExpanded = false" [isExpanded]="isExpanded"></app-sidenav-item>
    </mat-sidenav>
    <mat-sidenav-content class="grey-background">
      <mat-toolbar class="toolbar-bottom mat-elevation-z1 container-fluid white-background" style="margin-top: 50px; position: fixed; z-index: 999999;">
        <div class="container-fluid pt-5">
          <span class="mx-1">{{'common.home' | translate}}</span>
          <div style="color: #000; display: inline-table; padding: 10px 0 50px 0;" *ngFor="let b of breadcrumbs">
            <span class="mx-1">/</span>
            <span class="text-green mx-1" *ngIf="b.active" style="font-weight: bold;">{{b.label | translate}}</span>
            <span class="mx-1" *ngIf="!b.active && !b.route">{{b.label | translate}}</span>
            <a [routerLink]="b.route" *ngIf="!b.active && b.route" class="text-green mx-1">{{b.label | translate}}</a>
          </div>
        </div>
      </mat-toolbar>
      <div [ngClass]="(navData.hasBackButton)?'container-fluid main-body has-back-button':'container-fluid main-body'">
          <router-outlet></router-outlet>
        <div class="text-center mt-5 mb-5">Made with <span class="text-red">&hearts;</span> in Bali</div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</aside>
