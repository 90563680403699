import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BACKOFFICE, IMAGE_BASE_URL, PRODUCT_BASE_URL } from 'src/app/constants/api.constant';
import { environment } from 'src/environments/environment';
import { ProductModel } from 'src/app/pages/master/product/models/product.model';
import { BasePageService } from 'src/app/shared/base/base-page.service';

@Injectable({providedIn: 'root'})
export class ProductService extends BasePageService<ProductModel>{
  constructor(public http: HttpClient) {
    super(http);
  }

  get baseUrl(): string {
    return environment.url + BACKOFFICE + PRODUCT_BASE_URL;
  }


  async uploadFile(fd: FormData, productId: number): Promise<any> {
    return await this.http.post<any>(environment.url + BACKOFFICE + IMAGE_BASE_URL + `/${productId}/upload/product`,
      fd, {}).toPromise();
  }
}
